/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faBuilding,
  faCalendar,
  faCheck,
  faCoin,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';

import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';
import useBookings from '../../hooks/useBookings';
import {
  BookingRequestResponse,
  CreateBookingResponse,
} from '../../types/officeRND/BookingRequest';
import { Office } from '../../types/officeRND/offices';

interface BookingRequestSceneProps {
  bookingRequest: BookingRequestResponse;
  offices: Office[];
  onConfirmBooking: (booking: CreateBookingResponse) => void;
  onError: (message: string) => void;
  goBack?: () => void;
}

const BookingRequestScene: React.FC<BookingRequestSceneProps> = ({
  bookingRequest,
  offices,
  onConfirmBooking,
  onError,
  goBack,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { createBooking } = useBookings({ mock: false });
  const { profile } = useContext(UserContext);

  const req = bookingRequest;
  const title = req.summary;
  const price = req.fees?.[0]?.fee?.formattedPrice ?? req.currentPrice;

  const startDateTime = dayjs(req.start?.dateTime);
  const endDateTime = dayjs(req.end?.dateTime);

  const office = offices.find((of) => of._id === req.office);

  const handleConfirmBooking = async (input: BookingRequestResponse) => {
    try {
      setLoading(true);

      const confirmedBooking = await createBooking({
        description: input.description,
        summary: input.summary,
        start: input.start,
        end: input.end,
        team: profile?.team._id ?? '',
        member: profile?.member._id ?? '',
        resourceId: input.resourceId,
      });

      if (confirmedBooking.length) {
        const booking = confirmedBooking?.[0];
        // console.log(`booking`, JSON.stringify(booking));
        onConfirmBooking(booking);
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.message ??
        'Something went wrong with your booking';
      onError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-gray-100 p-8 text-left text-sm text-gray-700">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-base">{title}</h2>
          <div className="flex items-center">
            <p>Available</p>
            <FontAwesomeIcon icon={faCheck} className="ml-2 text-green-500" />
          </div>
        </div>
        <p>
          <FontAwesomeIcon icon={faBuilding} className="mr-2" />
          {office?.name}
        </p>
        <div className="mt-6 mb-6 flex items-center justify-between text-base text-gray-800 md:text-sm">
          <p className="flex flex-wrap items-center justify-center">
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            {startDateTime.format('DD/MM/YYYY')}
          </p>
          {/* <p className="flex flex-wrap items-center justify-center">
            <FontAwesomeIcon icon={faClock} className="mr-2" />
            {startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}
          </p> */}
          <p className="flex flex-wrap items-center justify-center">
            <FontAwesomeIcon icon={faCoin} className="mr-2" />
            {price ?? 'Free'}
          </p>
        </div>
        <p className="mt-4 text-gray-500">{req.description}</p>
      </div>
      <div className="mt-4 flex justify-center">
        <PrimaryButtonComponent
          loading={loading}
          onClick={() => handleConfirmBooking(bookingRequest)}
        >
          Make booking
        </PrimaryButtonComponent>
      </div>
      <div className="mt-2 flex justify-center">
        <button
          className="rounded p-4 py-2 px-4 text-gray-500"
          onClick={() => goBack && goBack()}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default BookingRequestScene;
