import { useState } from 'react';
import { Resource } from '../../types/officeRND/Resource';
import OfficeRndAPI from '../../utils/OfficeRndAPI';

const api = new OfficeRndAPI();

const useResources = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>();

  const getResources = async (filter?: Partial<Resource>) => {
    try {
      setLoading(true);
      const result = await api.getResources(filter);

      setResources(result);
    } catch (error: any) {
      //let message = error?.response?.data?.message ?? 'Not available sorry';
      // console.log(`message`, message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getResources,
    resources,
  };
};

export default useResources;
