import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

export type CardComponentProps = {
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties | undefined;
  isLocationCard?: boolean;
};

const Card = styled.div`
  min-height: 2rem;
`;

const CardComponent: React.FC<CardComponentProps> = (props) => {
  const isDisabled = props.disabled;
  const style = classnames({
    'shadow-lg': !isDisabled,
    'shadow-sm': isDisabled,
    'bg-white': true,
    'rounded-2xl': true,
    'p-8': !props.isLocationCard,
    'min-w-full': true,
    'h-100': !props.isLocationCard,
    'text-left': true,
    'my-4': true,
    'opacity-75': isDisabled,
    'min-w-min': true,
  });

  return (
    <Card style={props?.style} className={props?.className + ' ' + style}>
      {props.children}
    </Card>
  );
};

export default CardComponent;
