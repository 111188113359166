import React, { Suspense, useState } from 'react';
import ReactLoading from 'react-loading';
// import ReactLoading from 'react-loading';
// import { useParams } from 'react-router';
// import { UserContext } from '../../context/user';
// import useOffices from '../../hooks/useOffices';
// import { Office } from '../../types/officeRND/offices';
import { NewWorklocalSpacesScreen } from '../WorklocalSpacesScreen';
import { Areas } from '../../types/Areas';
// import ContentCardComponent from '../../components/ContentCardComponent';
// import pattern from './pattern.svg';

type AreaButtonProps = {
  isActive?: boolean;
  onClick?: () => void;
  area: string;
};

const AreaButton = (props: AreaButtonProps) => {
  const className = `h-full p-2 text-sm break-words bg-black focus:outline-none ${
    !props.isActive && 'bg-opacity-10'
  } ${props.isActive ? 'bg-white font-medium' : 'hover:bg-opacity-20'}`;
  // const borderRadius = '5px';
  // const lineHeight = '23px';
  // const fontFamily = 'Futura PT';
  return (
    <button
      onClick={props.onClick}
      className={className}
      // className="m-2 flex items-center rounded border-2 border-cowork-grey p-2 text-sm text-gray-800 opacity-50 hover:text-gray-900 hover:opacity-60 lg:h-10 lg:justify-center"
    >
      {props.area}
    </button>
    // <button
    //   style={{ borderRadius, lineHeight }}
    //   className={className}
    //   onClick={props.onClick}
    // >
    //   {props.children}
    // </button>
  );
};

type NewHomeScreenProps = {
  children?: React.ReactNode;
};

const NewHomeScreen = (props: NewHomeScreenProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  const [activeArea, setActiveArea] = useState<Areas | ''>('');

  console.log('activeArea', activeArea);

  const areas = Object.values(Areas);

  function handleClick(area: Areas) {
    if (activeArea === area) {
      setActiveArea('');
      // setSearchTerm('');
      // setLoadAfterClick(true);
    } else {
      setActiveArea(area);
      // setSearchTerm(area);
    }
  }

  // const handleActionLink = (url: string, external?: boolean) => {
  //   if (external) {
  //     window.open(url);
  //   } else {
  //     history.push(url);
  //   }
  // };

  // const goToOffice = () => {
  //   if (office?._id) {
  //     handleActionLink(`/office/${office?._id}`);
  //   } else {
  //     if (profile?.member.office) {
  //       handleActionLink(`/office`);
  //     } else {
  //       handleActionLink(`/venues`);
  //     }
  //   }
  // };

  // const description = `Cowork Local gives you the opportunity to reduce your commute, save money, find fresh inspiration and improve your work-life balance. Book a desk to work from a diverse range of unique spaces across the country. Community centres, libraries, activity centres and sports grounds are just some of the spaces on offer. Fully flexible, coworking offers a great alternative to working from home or commuting to the office.`;

  // const eventsLink = isTownSquare
  //   ? '/events'
  //   : 'https://www.eventbrite.co.uk/o/cowork-local-40814554873';

  return (
    <div className="mt-8">
      <div className="bg-inherit grid grid-cols-1 items-center gap-2.5 lg:grid-cols-6">
        {/* <p className="ml-2 text-2xl font-bold leading-7">
          Find a coworking space
        </p> */}
        {/* <ContentCardComponent
          className="relative overflow-hidden"
          title="Find a space"
          description="Working closer to home is good for our communities, environments and local economies. By choosing to use a Cowork Local space, you're contributing to a greener planet and healthier world"
        > */}
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          placeholder="The Track"
          className="h-full w-full border-b-2 border-primary-500 p-2 lg:col-span-2 lg:max-w-sm"
        />
        {/* </ContentCardComponent> */}
        {/* <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          placeholder="Find a coworking space"
          className="ml-2 bg-gray-200 text-2xl font-bold leading-7"
          // mt-8 w-full max-w-sm border-b-2 border-primary-500 p-2
        /> */}
        {areas.map((area, idx) => (
          <AreaButton
            key={idx}
            isActive={activeArea === area}
            onClick={() => handleClick(area)}
            area={area}
          />
        ))}
        {/* <div className="grid grid-cols-1 gap-2.5 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
        </div> */}
      </div>
      <Suspense
        fallback={
          // <NewHomeScreen max={9} />
          <div className="flex h-full w-full items-center justify-center">
            <ReactLoading color="black" type="bubbles" />
          </div>
        }
      >
        <NewWorklocalSpacesScreen
          // loading={loading}
          // filteredOffices={filteredOffices}
          searchTerm={searchTerm}
          activeArea={activeArea}
          areas={areas}
        />
      </Suspense>
    </div>
  );
};

export default NewHomeScreen;
