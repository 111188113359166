// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Marker } from '!react-map-gl';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pin = ({ lat, lng }: { lat: number; lng: number }) => {
  return (
    <Marker latitude={lat} longitude={lng} anchor="top">
      <div
      // type={listing.type}
      >
        <FontAwesomeIcon
          className="mb-4 mt-2 h-10 w-10 text-cowork-blue"
          icon={faLocationDot}
        />
      </div>
    </Marker>
  );
};

export default Pin;
