/* eslint-disable @typescript-eslint/no-unused-vars */
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import {
  //Controller,
  useForm,
} from 'react-hook-form';
//import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';

import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';
import useBookings, {
  CreateBookingType,
} from '../../hooks/useBookings/useBookings';
import useOffices from '../../hooks/useOffices';
import {
  BookingRequestResponse,
  CreateBookingResponse,
} from '../../types/officeRND/BookingRequest';
import officeInfo, { Days } from '../../utils/officeInformation';
import BookingConfirmationScene from './BookingConfirmationScene';
import BookingRequestScene from './BookingRequestScene';
import axios from 'axios';
import { isUsingCredits } from '../../utils/isUsingCredits';

interface BookingFormProps {
  type: CreateBookingType;
  officeId: string;
  setModalTitle: (title: string) => void;
  handleCloseModal: () => void;
  goBack?: () => void;
}

type OfficeInput = {
  label: string;
  value: string;
};
export type Inputs = {
  time: 'am' | 'pm' | 'allDay';
  office?: OfficeInput;
  title: string;
  description: string;
  date: string;
  size?: string;
};

// Cut off time of 9pm
let formMinDate = dayjs()
  .startOf('day')
  .add(dayjs().isBefore(dayjs().set('hour', 21)) ? 1 : 2, 'day')
  .toDate();

// If the date is the weekend, set the next date to Monday
if (dayjs(formMinDate).day() === 0 || dayjs(formMinDate).day() === 6) {
  formMinDate = dayjs(formMinDate).set('day', 1).toDate();
}

const BookingForm: React.FC<BookingFormProps> = (props) => {

  // React Hooks
  const [startDate, setStartDate] = useState(formMinDate);
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingRequest, setBookingRequest] =
    useState<BookingRequestResponse>();
  const [confirmedBooking, setConfirmedBooking] = useState<
    CreateBookingResponse | boolean
  >();
  const [confirmBookingMessage, setConfirmBookingMessage] = useState<string>();
  const [notAvailableMessage, setNotAvailableMessage] = useState<
    string | undefined
  >();

  // Context
  const { profile } = useContext(UserContext);

  // Module Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const { addToast } = useToasts();

  // Custom Hooks
  const {
    office: foundOffice,
    getOffice,
    offices,
    getOffices,
  } = useOffices({ mock: false });
  const { checkBooking } = useBookings({ mock: false });

  useEffect(() => {
    getOffices();
    getOffice(props.officeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const readableType =
    props.type === CreateBookingType.HOTDESK ? 'Hot desk' : 'Meeting Room';
  const onSubmitBookingRequest = async (data: Inputs) => {
    if (startDate) {
      try {
        if (props.type === CreateBookingType.MEETING_ROOM) {
          // If its a meeting room request then we just send an email enquiry

          setLoading(true);
          const office = data.office;
          let date = dayjs(startDate);
          const body = {
            venue: office?.label,
            numberOfPeople: data.size,
            date: date.format('YYYY-MM-DD'),
            memberName: profile?.member.name,
            memberEmail: profile?.member.email,
            memberNumber: profile?.member.phone,
          };
          console.log('body', body);

          await axios.post('/.netlify/functions/request-meeting-room', {
            ...body,
          });

          setLoading(false);
          setConfirmedBooking(true);
          setConfirmBookingMessage(
            `Your request has been sent. The Cowork Local team will check availability of ${office?.label} and get back to you shortly.`
          );
          return true;
        }

        setLoading(true);
        setNotAvailableMessage(undefined);
        //  Set the correct hours.
        //  Morning sessions are 9:00 - 14:00
        // 	Afternoon sessions are 14:00 - 17:00
        let date = dayjs(startDate).startOf('hour');

        let start, end;

        const officeMeta = officeInfo.find(
          (off) => off.slug === foundOffice?.slug
        );

        let morningHour = 9;
        let morningMin = 0;
        let afternoonHour = 15;
        let eveningHour = 16;
        let eveningMin = 0;

        // If we know the opening times, set the times accordingly
        // if (officeMeta) {
        //   morningHour = dayjs(officeMeta.opening_time).get('hour');
        //   morningMin = dayjs(officeMeta.opening_time).get('minute');
        //   eveningHour = dayjs(officeMeta.closing_time).get('hour');
        //   eveningMin = dayjs(officeMeta.closing_time).get('minute');
        // }

        start = date.startOf('d');

        end = start.add(1, 'd').startOf('d');
        // switch (data.time ?? 'allDay') {
        //   case 'am':
        //     start = date.hour(morningHour).minute(morningMin);
        //     end = date.hour(afternoonHour);
        //     break;
        //   case 'pm':
        //     start = date.hour(afternoonHour);
        //     end = date.hour(eveningHour).minute(eveningMin);
        //     break;
        //   case 'allDay':
        //     start = date.hour(morningHour).minute(morningMin);
        //     end = date.hour(eveningHour).minute(eveningMin);
        //     break;
        //   default:
        //     start = date.hour(morningHour).minute(morningMin);
        //     end = date.hour(eveningHour).minute(eveningMin);
        //     break;
        // }

        console.log('start', start);
        console.log('end', end);
        const office = data.office;
        // Need to handle Daylight savings
        // start = start.add(dayjs().utcOffset(), 'minutes');
        // end = end.add(dayjs().utcOffset(), 'minutes');

        let title = data.title;

        if (!title) {
          title = `${readableType} @ ${office?.label}`;
        }

        console.log(
          'boocking requests',
          JSON.stringify({
            resourceType: props.type,
            description: data.description,
            summary: title,
            start: start.toISOString(),
            end: end.toISOString(),
            count: data.size ?? 1,
            // If there is no team id, there needs to be a memberId
            team: profile?.team._id,
            member: profile?.member._id,
            office: office?.value ?? props.officeId,
          })
        );
        const bookingCheck = await checkBooking({
          resourceType: props.type,
          description: data.description,
          summary: title,
          start: start.toISOString(),
          end: end.toISOString(),
          count: data.size ?? 1,
          // If there is no team id, there needs to be a memberId
          team: profile?.team._id,
          member: profile?.member._id,
          office: office?.value ?? props.officeId,
        });
        console.log(
          '🚀 ~ file: BookingForm.tsx ~ line 210 ~ onSubmitBookingRequest ~ bookingCheck',
          bookingCheck
        );

        if (bookingCheck.length) {
          setBookingRequest(bookingCheck?.[0]);
        }
      } catch (error: any) {
        let message = error?.response?.data?.message ?? 'Not available sorry';

        if (message.includes('Not enough resources available for booking')) {
          const typeName =
            props.type === CreateBookingType.HOTDESK
              ? 'hot desks'
              : 'meeting rooms';

          message = `Sorry, it seems like we don't have any ${typeName} available for that date, try a different time or a different space.`;
        }
        setNotAvailableMessage(message);
      } finally {
        setLoading(false);
      }
    }
  };

  const officesMap = offices.map((office) => ({
    label: office.name,
    value: office._id,
  }));

  // Show the confirmation screen
  if (confirmedBooking) {
    props.setModalTitle(
      props.type === CreateBookingType.MEETING_ROOM
        ? 'Your enquiry has been sent'
        : 'Your booking is confirmed'
    );
    return (
      <BookingConfirmationScene
        message={confirmBookingMessage}
        handleCloseModal={props.handleCloseModal}
      />
    );
  }

  const usingCredits = isUsingCredits(bookingRequest);
  if (bookingRequest && !usingCredits) {
    props.setModalTitle('No day passes remaining');

    return (
      <div className="text-gray-900">
        <h2>Sorry, it seems you don't have enough day passes to book.</h2>
        <p className="text-md mt-4 text-gray-500">
          Please{' '}
          <Link
            to="/membership"
            style={{
              textDecoration: 'underline',
            }}
          >
            subscribe
          </Link>{' '}
          to get more day passes.
        </p>
      </div>
    );
  }

  // Show the summary information
  if (bookingRequest) {
    props.setModalTitle('Confirm your booking');
    return (
      <BookingRequestScene
        offices={offices}
        bookingRequest={bookingRequest}
        onConfirmBooking={setConfirmedBooking}
        onError={(msg) => addToast(msg, { appearance: 'error' })}
        goBack={() => setBookingRequest(undefined)}
      />
    );
  }
  props.setModalTitle('Book a space');

  const isWeekday = (date: string | Date) => {
    const day = dayjs(date).day();
    return day !== 0 && day !== 6;
  };

  return (
    <div className="mx-2 mt-8 lg:mx-4">
      <form
        className="w-full text-left"
        onSubmit={handleSubmit(onSubmitBookingRequest)}
      >
        <div className="mb-4 flex items-center justify-between">
          <div className="w-1/2">
            <p>Date</p>
            {/* 
            <Controller
              as={DatePickerComponent}
              name="date"
              dateFormat="dd/MM/yyyy"
              defaultValue={startDate}
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date);
              }}
              onSelect={(date: Date) => {
                setStartDate(date);
              }}
              control={control}
              minDate={formMinDate}
              excludeTimes={[]}
              filterDate={isWeekday}
            /> */}
          </div>
          {/* <div className="w-1/2">
            <p>Time</p>
            <Controller
              as={TimeSelector}
              name="time"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              onSelect={(v: 'am' | 'pm' | 'allDay') => setValue('time', v)}
            />
          </div> */}
        </div>

        <div className="mb-4">
          {/* <Controller
            as={Input}
            name="title"
            label={
              CreateBookingType.MEETING_ROOM
                ? 'Special requirements / details'
                : 'Title'
            }
            placeholder={
              CreateBookingType.MEETING_ROOM
                ? 'Need a specific room, or a specific time?'
                : `${readableType} @ ${
                    offices.find((office) => office._id === props.officeId)
                      ?.name
                  }`
            }
            control={control}
            defaultValue=""
            errorMessage={errors.title?.message}
          /> */}
        </div>
        {/* <div className="mb-4">
          <Controller
            as={Input}
            name="description"
            label="Description"
            placeholder="Get together to discuss the next big thing. "
            control={control}
            defaultValue=""
            rules={{ required: false }}
          />
        </div> */}

        {/* <div className="mb-4 flex items-center justify-between">
          {props.type === CreateBookingType.MEETING_ROOM && (
            <div className="mr-2 w-1/2">
              <Controller
                as={Input}
                name="size"
                label="Number of people"
                control={control}
                defaultValue="1"
                className="w-1/2"
                rules={{ required: false }}
              />
            </div>
          )}
          <div className="w-1/2">
            <p className="mb-2 block text-sm text-gray-800">Space</p>
            {officesMap.length && props.officeId && (
              <Controller
                options={officesMap}
                defaultValue={officesMap.find(
                  (office) => office.value === props.officeId
                )}
                as={Select}
                name="office"
                control={control}
                label="Space"
              />
            )}
          </div>
        </div> */}
        {notAvailableMessage && (
          <div className="mb-4 flex items-center rounded bg-yellow-100 p-4 text-yellow-900">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-4" />
            <p>{notAvailableMessage}</p>
          </div>
        )}
        <div className="mt-8 flex w-full justify-center">
          <PrimaryButtonComponent loading={loading} type="submit">
            {props.type === CreateBookingType.MEETING_ROOM
              ? 'Send request'
              : 'Check availability'}
          </PrimaryButtonComponent>
        </div>
        {props.type === CreateBookingType.MEETING_ROOM && (
          <p className="mt-5 text-center text-gray-500">
            This will send a request to our team who will follow up with you to
            book the meeting room.
          </p>
        )}
        <div className="flex w-full justify-center">
          <button
            type="button"
            onClick={() => props.goBack && props.goBack()}
            className="mt-2 mr-4 rounded p-4 py-2 px-4 text-gray-500"
          >
            back
          </button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(BookingForm);
