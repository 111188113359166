import {
  faCheckCircle,
  //faDesktop,
  //faDoorClosed,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import classNames from 'classnames';
//import _ from 'lodash';
import {
  //useContext, useEffect,
  useState,
} from 'react';
import ReactLoading from 'react-loading';
import ReactModal from 'react-modal';
//import { UserContext } from '../../context/user';
//import { CreateBookingType } from '../../hooks/useBookings/useBookings';
import useResources from '../../hooks/useResources';
//import { TailwindTheme } from '../../types';
//import { Rate2 } from '../../types/officeRND/Resource';
//import bottom from './bottom.svg';
import { Office } from '../../types/officeRND/offices';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

ReactModal.setAppElement('#root');

type ConfirmedBookingModalProps = {
  onRequestClose: () => void;
  isOpen: boolean;
  office?: Office;
};

const ConfirmedBookingModal = (props: ConfirmedBookingModalProps) => {
  const { loading } = useResources();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showForm, setShowForm] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalTitle, setModalTitle] = useState<string>(
    'Your booking has been confirmed!'
  );

  // get queryparams from url
  const queryParams = new URLSearchParams(window.location.search);
  const bookingOffice = queryParams.get('office');
  const bookDate = queryParams.get('date');
  const bookingDate = dayjs(bookDate).format('MMMM DD, YYYY');

  const handleClose = () => {
    setShowForm(false);
    history.push('/bookings'); // To remove the URL params
    props.onRequestClose();
  };

  return (
    <ReactModal
      className={` translate-y-1/5 absolute right-1/2 w-full max-w-lg translate-x-1/2 transform rounded-lg bg-white p-8 text-center shadow-lg md:translate-y-1/4`}
      isOpen={props.isOpen}
      onRequestClose={handleClose}
    >
      {loading && (
        <div className="flex min-h-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )}

      <div className="z-50">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-700"
        >
          <FontAwesomeIcon className="mt-2 mb-4" icon={faTimes} />
        </button>
        <div className="flex flex-col">
          <FontAwesomeIcon
            className="mt-2 mb-4 text-6xl text-green-400"
            icon={faCheckCircle}
          />
          <p className="mb-4">{modalTitle}</p>
          <h2 className="mb-4 text-lg font-bold text-gray-500">
            {bookingOffice}
          </h2>
          <p className="mb-4">{bookingDate}</p>
          <button
            className="rounded bg-cowork-blue px-4 py-2 font-bold text-white"
            type="submit"
            onClick={handleClose}
          >
            View my bookings
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmedBookingModal;
