import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Membership } from '../../types/officeRND/membership';
import OfficeRndAPI from '../../utils/OfficeRndAPI';

const api = new OfficeRndAPI();

const useMembership = () => {
  const [loading, setLoading] = useState(false);
  const [activeMembership, setActiveMembership] = useState<Membership>();
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [numberOfCredits, setNumberOfCredits] = useState<number>(0);
  const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false);
  useEffect(() => {
    getActiveMembership();
  }, []);

  const getActiveMembership = async () => {
    // console.log('get active');
    try {
      setLoading(true);
      const membership = await api.getActiveMembership();
      if (membership) {
        setActiveMembership(membership);

        const endDate = membership.endDate;
        const today = new Date();
        const daysLeft = dayjs(endDate).diff(today, 'day');
        setDaysLeft(daysLeft);

        const credits = (await api.getCreditBalance(new Date())) ?? 0;
        setNumberOfCredits(credits);

        if (membership.plan.name.toLowerCase().includes('trial')) {
          // 'free'
          // ? 'free' appears to never be true.
          setIsFreeTrial(true);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getNumberOfDayPasses = async (date: Date) => {
    const credits = (await api.getCreditBalance(date)) ?? 0;
    let passesLeft = Math.floor(credits / 12.5);
    return passesLeft;
  };

  return {
    getActiveMembership,
    loading,
    activeMembership,
    daysLeft,
    numberOfCredits,
    getNumberOfDayPasses,
    isFreeTrial,
  };
};

export default useMembership;
