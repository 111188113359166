import axios from 'axios';
import dayjs from 'dayjs';
import {
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
} from '../types/officeRND';
import { HttpClient } from './axios';

const USER_TOKEN_KEY = 'user_token';

export class OfficeRndAPIAuth extends HttpClient {
  protected token: null | string;

  // Auth URL - https://app.officernd.com/api/v1/auth

  // Base - https://app.officernd.com/api/v1/organizations/townsquare

  // User - https://app.officernd.com/api/v1/organizations/townsquare/user
  public constructor(url?: string) {
    super('/api/v1');
    this.token = null;
  }

  get user_token(): string | null {
    return window.localStorage.getItem(USER_TOKEN_KEY);
  }

  set user_token(token: string | null) {
    this.token = token;
  }

  private _hasTokenExpired = (token: string): boolean => {
    const jwt = JSON.parse(atob(token.split('.')[1]));
    const expries = dayjs(jwt.exp * 1000);

    return dayjs().isAfter(expries);
  };

  /**
   * Authenticate member by email & password. Returns the access token to login (impersonate) as a member. Please note the base URL for this endpoint is https://app.officernd.com/api/{version} instead of https://app.officernd.com/api/{version}/organizations/{orgSlug}.
   */
  public signIn = async (
    body: SignInRequest
  ): Promise<SignInResponse | undefined> => {
    // console.log("signIn", body);
    try {
      const data = await this.instance.post<SignInRequest, SignInResponse>(
        '/auth/signin',
        body
      );
      this.token = data?.token ?? null;
      window.localStorage.setItem(USER_TOKEN_KEY, data?.token);
      // console.log("USER_TOKEN_KEY", data?.token);
      return data;
    } catch (error) {
      window.localStorage.removeItem(USER_TOKEN_KEY);
      throw error;
    }
  };

  /**
   * The user Id is mostly used for getting the users profile.
   * To filter other resources such as bookings, see getMemberId()
   */
  public getUserId = () => {
    const token = window.localStorage.getItem(USER_TOKEN_KEY);
    if (token) {
      const hasExpried = this._hasTokenExpired(token);

      if (hasExpried) {
        this.signOut();
        return null;
      }

      const jwt = JSON.parse(atob(token.split('.')[1]));
      return jwt?.id;
    }

    return null;
  };

  /**
   * Creates a new user credentials. Those credentials can be used later to login into OfficeRnD members portal to be exchanged for a bearer token and make requests as the user in hand.
   */
  public signUp = async (params: SignUpRequest): Promise<SignUpResponse> => {
    try {
      const { phone, ...signUpParams } = params;
      // console.log('Signing up');
      const res = await this.instance.post<SignUpRequest, SignUpResponse>(
        '/organizations/townsquare/signup',
        signUpParams
      );

      // Login as the user to get a token back
      const signInRes = await this.signIn({
        username: params.email,
        password: params.password,
      });

      const token = signInRes?.token;
      if (!token) {
        throw new Error('No sign in res found');
      }

      // Create a new member and/or company in the organization in hand and link it to the current user.
      await this.instance.post(
        '/organizations/townsquare/subscribe',
        {
          name: params.name,
          team: params.company,
          phone: params.phone ?? '',
          properties: {},
          billing: {},
          address: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Give the user a free one off fee of 30 day passes
      const profile = await this.instance.get(
        '/organizations/townsquare/user/profile',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      try {
        await axios.post('/.netlify/functions/subscribe-free-trial', {
          team: profile.member.team,
          member: profile.member._id,
          // office,
        });
      } catch (error) {
        console.log(`error`, error);
      }

      return res;
    } catch (error) {
      window.localStorage.removeItem(USER_TOKEN_KEY);
      throw error;
    }
  };

  public signOut = () => {
    window.localStorage.removeItem(USER_TOKEN_KEY);
  };
}
