import React from 'react';
import { Link } from 'react-router-dom';

import AuthCardComponent from '../../components/AuthCardComponent';
import CardComponent from '../../components/CardComponent';
import RegisterForm from '../../forms/RegisterForm';

export type RegisterScreenProps = {};

const RegisterScreen: React.FC<RegisterScreenProps> = (props) => {
  const url = window.location.href;
  let platform = 'Cowork Local';

  if (url.includes('members.townsq.co.uk')) {
    platform = 'TownSq';
  }

  return (
    <div className="w-auto">
      <AuthCardComponent
        title="Create an account"
        description={`Looking to join the ${platform} community? You are in the right place!`}
      >
        <RegisterForm />
      </AuthCardComponent>
      <CardComponent className="flex items-center justify-between h-2 mt-8 mb-20 text-sm sm:mb-0">
        <p>Already have an account?</p>
        <Link to="/login">
          <span className="font-bold underline">Log in</span>
        </Link>
      </CardComponent>
    </div>
  );
};

export default RegisterScreen;
