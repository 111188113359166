import {
  faBars,
  faSignIn,
  faSignOut,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react';
//import { act } from 'react-dom/test-utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import coworkLocalLogo from '../../assets/cowork-local-logo.svg';
import townsqlogo from '../../assets/townsqaure-logo.svg';
import { UserContext } from '../../context/user';
import useMembership from '../../hooks/useMembership/useMembership';
import {
  PlanNames,
  returnMembershipName,
  returnPlanDetails,
} from '../../screens/MembershipScreen/membershipUtils';
import PrimaryButtonComponent from '../PrimaryButtonComponent';
import NewNavItems from './NewNavItems';

export type NavComponentProps = {};

const fontFamily = 'Helvetica';

const lineHeight = '18px';

// const ActiveLine = styled.div``;

// type NewNavItemProps = {
//   title: string;
//   to: string;
//   isActive?: boolean;
// };

// const NewNavItem = (props: NewNavItemProps) => {
//   const className = `h-10 w-full my-2 flex justify-center items-center bg-cowork-${
//     props.isActive ? 'blue' : 'grey'
//   } opacity-50 hover:opacity-60`;
//   return (
//     <div className={className} style={{ fontFamily, lineHeight }}>
//       {props.title === 'Articles' || props.to.includes('eventbrite') ? (
//         <a href={props.to} target="_blank" rel="noreferrer">
//           {props.title}
//         </a>
//       ) : (
//         <Link to={props.to}>{props.title}</Link>
//       )}
//     </div>
//   );
// };

const NavComponent: React.FC<NavComponentProps> = (props) => {
  const { profile, signOut, isTownSquare } = useContext(UserContext);

  const membership = useMembership();

  const {
    activeMembership,
    numberOfCredits,
    //daysLeft,
    //getNumberOfDayPasses,
    //isFreeTrial,
  } = membership;
  const { planTitle } = returnPlanDetails(activeMembership?.plan.name);
  const [plan, setPlan] = React.useState<PlanNames | undefined>();
  // const planName = !activeMembership
  //   ? null
  //   : activeMembership.plan.name
  //       .split(' ')
  //       .filter(
  //         (word) =>
  //           word.toLowerCase() !== 'cowork' &&
  //           word.toLowerCase() !== 'local' &&
  //           word.toLowerCase() !== 'cowork-local'
  //       )
  //       .join(' ');
  // const plan =
  //   planName?.includes('trial') && daysLeft < 1
  //     ? 'PAYG'
  //     : planName;
  let logo = coworkLocalLogo;
  if (isTownSquare) {
    logo = townsqlogo;
  }
  let location = useLocation();
  const name = profile?.member.name;
  const email = profile?.member.email;

  useEffect(() => {
    setPlan(planTitle);
  }, [activeMembership?.plan.name, planTitle]);

  React.useEffect(() => {
    // console.log(membership);
  }, [membership]);

  return (
    <Wrapper
      className="flex h-full w-full justify-between bg-white lg:min-h-screen lg:max-w-sm lg:flex-col"
      // p-2 md:p-4
    >
      <InnerNav
        planName={plan ?? ''}
        // plan={plan}
        logo={logo}
        name={name}
        email={email}
        numberOfCredits={numberOfCredits}
        location={location}
        signOut={signOut}
        isTownSquare={isTownSquare}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

type InnerNavProps = {
  planName: string;
  // plan: string | null;
  logo: string;
  name: string | undefined;
  email: string | undefined;
  numberOfCredits: number | null;
  location: any;
  signOut: () => void;
  isTownSquare: boolean;
};

const InnerNav = ({
  logo,
  planName,
  // plan,
  name,
  email,
  location,
  signOut,
  isTownSquare,
}: InnerNavProps) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const { profile } = useContext(UserContext);
  const history = useHistory();
  const memberId = profile?.member._id;

  const goToSignup = () => {
    history.push('/register');
  };

  return (
    <>
      <div className="relative flex h-full w-full items-center justify-between p-3 lg:flex-col lg:p-0">
        <div
          className="items-center justify-center text-center lg:flex lg:w-full"
          // LOGO ALWAYS
        >
          <a href="https://coworklocal.co.uk">
            <img alt="logo" src={logo} className="w-24 lg:mt-4 lg:mb-20" />
          </a>
        </div>
        <div
          className="items-center text-center lg:w-full lg:justify-center lg:pb-10"
          // NAME ALWAYS, EMAIL ON SMALL
        >
          <p
            className="font-sans text-xl font-bold lg:text-2xl"
            style={{ fontFamily, lineHeight: '28px' }}
          >
            {name}
          </p>
          <p
            className="hidden font-sans opacity-50 lg:block"
            style={{ fontFamily, lineHeight }}
          >
            {email}
          </p>
        </div>
        {planName && !!profile && (
          <div
            className="hidden items-center text-center lg:mb-10 lg:flex lg:w-full lg:justify-center"
            // PLANNAME ON SMALL
          >
            {/* <span
              className="rounded bg-cowork-blue font-bold text-white lg:p-2"
              style={{ fontFamily, lineHeight }}
            >
              {plan}
            </span> */}
            <Link to="/membership">
              <PrimaryButtonComponent
                // disabled={true}
                style={{ cursor: 'default' }}
              >
                {' '}
                {returnMembershipName(planName)}
              </PrimaryButtonComponent>
            </Link>
          </div>
        )}
        {/* <div
          className=" items-center text-center lg:block lg:w-full lg:justify-center lg:pb-10"
          // REMAINING ALWAYS
        >
          <p style={{ fontFamily, lineHeight }}>
            {numberOfPasses +
              ' day passes remaining for ' +
              dayjs().format('MMMM')}
          </p>
        </div> */}
        <div
          className="lg:hidden"
          // DROPDOWN ON SMALL
        >
          <button
            data-dropdown-toggle="dropdown"
            className="focus:outline-none inline-flex items-center rounded-lg bg-white text-center font-medium text-gray-900 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
            type="button"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          {/* <!-- Dropdown menu --> */}
          {dropdownOpen && (
            <NewNavItems
              location={location}
              isTownSquare={isTownSquare}
              setDropdownOpen={setDropdownOpen}
              isDropdown
              hasProfile={memberId !== undefined}
            />
          )}
        </div>
        <div
          className="hidden h-full w-full px-8 text-center lg:block"
          // NAVITEM BUTTONS ON LARGE
        >
          <NewNavItems
            location={location}
            isTownSquare={isTownSquare}
            hasProfile={memberId !== undefined}
          />
        </div>
      </div>
      <div
        className="hidden h-full text-center lg:block lg:w-full lg:px-4"
        // SIGN OUT ON LARGE
      >
        <div className="m-2 flex items-center  border-2 border-cowork-grey opacity-50 hover:opacity-60 lg:h-10 lg:justify-center">
          {/* <button onClick={signOut} style={{ fontFamily, lineHeight }}>
            Sign out
          </button> */}
          {!!profile ? (
            <button
              onClick={signOut}
              className="w-full rounded p-2 text-sm text-gray-800 hover:text-gray-900 md:border-2"
            >
              <FontAwesomeIcon icon={faSignOut} className="mr-2" />
              <span className="hidden sm:inline">Sign out</span>
            </button>
          ) : (
            <button
              onClick={goToSignup}
              className="w-full rounded p-2 text-sm text-gray-800 hover:text-gray-900 md:border-2"
            >
              <FontAwesomeIcon icon={faSignIn} className="mr-2" />
              <span className="hidden sm:inline">Sign up</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default NavComponent;
