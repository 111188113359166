import classNames from 'classnames';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { UserContext } from '../../context/user';
import { TailwindTheme } from '../../types';
import AuthLayoutComponent from '../AuthLayoutComponent';
import NavComponent from '../NavComponent';
// import useMembership from '../../hooks/useMembership/useMembership';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type MainLayoutComponentProps = {};
const MainLayoutComponent: React.FC<MainLayoutComponentProps> = ({
  children,
}) => {
  const { profile, loading, isTownSquare } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  // const { daysLeft, isFreeTrial, activeMembership, numberOfCredits } =
  //   useMembership();
  // const isBasic = activeMembership?.plan.name.toLowerCase().includes('basic');
  // const isUnlimited = activeMembership?.plan.name
  //   .toLowerCase()
  //   .includes('unlimited');
  // const passesLeft = activeMembership?.plan.passes;
  // const [showBanner, setShowBanner] = React.useState(true);
  // const daysLeft = 0;
  // const isFreeTrial = true;

  if (loading) {
    return null;
  }

  const loginPath = location.pathname.includes('login');
  const registerPath = location.pathname.includes('register');

  const url = window.location.href;

  let title = 'Cowork local members portal';
  // let favicon = 'favicon';
  if (url.includes('members.townsq.co.uk')) {
    title = 'TownSq Members Portal';
    // favicon = 'townsq-favicon';
  }

  if (!profile && !loading && !location.pathname.includes('/venues')) {
    if (
      !location.pathname.includes('login') &&
      !location.pathname.includes('check-in') &&
      !location.pathname.includes('register')
    ) {
      history.push('/venues');
    }
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content={
              title +
              ' allows you to take full advantage of your membership. Book spaces, see up coming events and manage your membership.'
            }
          />
          {/* <link rel="icon" href={`%PUBLIC_URL%/${favicon}.ico`} /> */}
        </Helmet>
        <AuthLayoutComponent>{children}</AuthLayoutComponent>
      </>
    );
  }

  const hasTownSquareInURL = url.includes('members.townsq.co.uk');

  const theme = classNames({
    [TailwindTheme.TOWNSQ]: isTownSquare || hasTownSquareInURL,
    [TailwindTheme['COWORK-LOCAL']]: !isTownSquare,
  });

  if (loginPath || registerPath) {
    return <Redirect to="/dashboard" />;
  }

  // For the check-in page, we want to show the auth layout, even if the user is logged in.
  if (location.pathname.includes('check-in')) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content={
              title +
              ' allows you to take full advantage of your membership. Book spaces, see up coming events and manage your membership.'
            }
          />
        </Helmet>
        <AuthLayoutComponent>{children}</AuthLayoutComponent>
      </>
    );
  }

  // const membershipMessage =
  //   isBasic && passesLeft
  //     ? `You have ${passesLeft} day pass${
  //         passesLeft !== 1 && 'es'
  //       } left in your Basic Membership.`
  //     : isBasic
  //     ? `You have ${numberOfCredits} credit${
  //         numberOfCredits !== 1 && 's'
  //       } left in your Basic Membership.`
  //     : daysLeft > 0 && isFreeTrial
  //     ? `You have ${daysLeft} day${
  //         daysLeft !== 1 && 's'
  //       } left in your Free Trial.`
  //     : `Your Free Trial has expired.`;
  // TODO: Include CTA to upgrade to a plan
  // ? @awaiting cta details

  //   <main class="flex flex-col h-screen">
  //   <div class="flex flex-1 overflow-hidden">
  //     <div class="flex bg-gray-100 w-32 p-4">Sidebar</div>
  //     <div class="flex flex-1 flex-col">
  //       <div class="flex bg-gray-300 h-16 p-4">Header</div>
  //       <div class="flex flex-1 bg-blue-300 overflow-y-auto paragraph px-4">
  //...
  //       </div>
  //     </div>
  //   </div>
  //   <div class="flex">Footer</div>
  // </main>

  return (
    <Wrapper
      className={`${theme} h-full min-h-screen bg-gray-200 lg:flex`}
      // <-- nav and content only displayed flex at lg screen + up
    >
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={
            'Town Square members portal allows you to take full advantage of your membership. Book spaces, see up coming events and manage your membership.'
          }
        />
      </Helmet>
      {/* FIXME: the width of the nav was set at lg:w-1/5 */}
      <div className="h-full w-full lg:w-1/3 xl:w-1/4">
        <NavComponent />
      </div>
      <div
        // FIXME: removed "lg:pr-40 xl:pr-80" was pushing everything to left - also causing responsive issues @francois
        className="w-full overflow-y-auto p-4 pt-8 md:p-16 md:pt-10 lg:max-h-screen" // lg:pr-80
        // - Use permanent banner for the whole trial (rather than last 10 days)
        // - Show day passes remaining
      >
        {/* {!isUnlimited && showBanner && (
          <Banner
            membershipMessage={membershipMessage}
            trialEnded={daysLeft < 1}
            setShow={setShowBanner}
          />
        )} */}
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default MainLayoutComponent;

// const Banner = ({
//   membershipMessage,
//   trialEnded,
//   setShow,
// }: {
//   membershipMessage: string;
//   trialEnded: boolean;
//   setShow: (show: boolean) => void;
// }) => {
//   return (
//     // daysLeft < 10 &&
//     <div
//       className={`mb-4 h-14 rounded-lg ${
//         trialEnded ? 'bg-green-500' : 'bg-red-500'
//       } p-2 text-center ${
//         trialEnded ? 'text-green-50' : 'text-red-50'
//       } text-red-50`} // was mb-4
//     >
//       <p>
//         {membershipMessage}
//         {trialEnded && (
//           <span className="inline-flex items-center float-right p-1 mr-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full">
//             <FontAwesomeIcon
//               className={'float-right'}
//               icon={faTimesCircle}
//               onClick={() => setShow(false)}
//             />
//           </span>
//         )}
//       </p>
//     </div>
//   );
// };
