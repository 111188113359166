import React from 'react';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  errorMessage?: string;
}

export const Input: React.FC<InputProps> = ({
  errorMessage,
  label,
  ...props
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={props.name ?? ''}
          className="block text-sm text-gray-800"
        >
          {label}
        </label>
      )}
      <input
        {...props}
        className="bordered focus:outline-none mt-2 w-full rounded border-2 border-gray-400 p-2"
      />

      {errorMessage && (
        <div className="mr-1 mt-1 flex justify-end">
          <p className="text-red-500">{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default Input;
