import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    const cache = setupCache({
      maxAge: 30 * 60 * 60 * 1000,
    });

    this.instance = axios.create({
      baseURL,
      adapter: cache.adapter,
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}
