import React from 'react';

export type TabsComponentProps = {};

const TabsComponent: React.FC<TabsComponentProps> = (props) => {
  return (
    <ul className="flex md:flex-row flex-col w-full md:w-auto mb-4">
      {props.children}
    </ul>
  );
};

export default TabsComponent;
