import {
  faCalendarStar,
  faNewspaper,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import ReactLoading from 'react-loading';

import CardComponent from '../CardComponent';

export enum ContentCardComponentType {
  EVENT = 'event',
  ARTICLE = 'atricle',
}

export type ContentCardComponentProps = {
  onClick?: () => void;
  title?: string;
  subTitle?: string;
  description?: string;
  img?: string;
  type?: ContentCardComponentType;
  link?: string;
  className?: string;
  floatImage?: boolean;
  loading?: boolean;
};

const ContentCardComponent: React.FC<ContentCardComponentProps> = (props) => {
  const imagePostion = classnames({
    'md:absolute': props.floatImage,
    'right-0': props.floatImage,
    '-top-16': props.floatImage,
    'float-right': !props.floatImage,
  });

  if (props.loading) {
    return (
      <CardComponent className="flex items-center justify-center">
        <ReactLoading color="black" type={'bubbles'} width={48} height={48} />
      </CardComponent>
    );
  }

  return (
    <CardComponent
      style={{ minHeight: '16rem' }}
      className={`${props.className}`}
    >
      {props.type && (
        <div className="mb-4 flex items-center text-gray-600">
          {props.type === ContentCardComponentType.EVENT && (
            <>
              <FontAwesomeIcon className="mr-4" icon={faCalendarStar} />
              <p className="text-xs">EVENT</p>
            </>
          )}
          {props.type === ContentCardComponentType.ARTICLE && (
            <>
              <FontAwesomeIcon className="mr-4" icon={faNewspaper} />
              <p className="text-xs">ARTICLE</p>
            </>
          )}
        </div>
      )}
      <div className="relative flex justify-center md:justify-end lg:mt-0">
        {props.img && (
          <img
            className={` mb-4 h-24 w-24 rounded-full object-cover lg:h-48 lg:w-48 ${imagePostion}`}
            alt="building"
            src={props.img}
          />
        )}
      </div>
      {props.title && (
        <h2 className="mb-4 max-w-md text-2xl font-bold text-gray-700">
          {props.link ? (
            <a
              className="transition-all hover:underline"
              target="_blank"
              rel="noreferrer"
              href={props.link}
            >
              {props.title}
            </a>
          ) : (
            props.title
          )}
        </h2>
      )}
      {props.subTitle && (
        <p className="mb-4 max-w-xl text-sm text-gray-500">{props.subTitle}</p>
      )}
      {props.description && (
        <p className="max-w-xl text-sm text-gray-500">{props.description}</p>
      )}
      {props.children}
    </CardComponent>
  );
};

export default ContentCardComponent;
