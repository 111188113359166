import {
  faBrowser,
  faCalendarAlt,
  faClipboardCheck,
  // faSignOut,
  faUserCircle,
  IconDefinition,
  faNewspaper,
  // faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export enum NavItemTitles {
  LOCATIONS = 'Locations',
  MY_BOOKINGS = 'My Bookings',
  MEMBERSHIP = 'Membership',
  EVENTS = 'Events',
  ARTICLES = 'Articles',
}

type NewNavItemProps = {
  title: NavItemTitles;
  to: string;
  isActive?: boolean;
  icon?: IconDefinition | undefined;
  // badge?: number;
};

type NewNavItemsProps = {
  location: any;
  isTownSquare?: boolean;
  isDropdown?: boolean;
  setDropdownOpen?: (value: boolean) => void;
  hasProfile?: boolean;
};

const ActiveLine = styled.div``;

// const fontFamily = 'Helvetica';

// const lineHeight = '18px';

const Contents = (props: Omit<NewNavItemProps, 'to'>) => {
  return (
    <li
      className={`flex justify-between text-gray-500 hover:text-gray-600 lg:mb-2${
        props.isActive && 'text-gray-800'
      }`}
    >
      <div className="flex w-full items-center justify-start text-sm lg:text-lg">
        {props.icon && (
          <div className="mr-2 w-1/5 lg:mr-4">
            <FontAwesomeIcon className="w-6" icon={props.icon} />
          </div>
        )}

        <span className="hidden w-4/5 text-left sm:flex">{props.title}</span>
        {/* {props.badge && (
      <span className="items-center hidden p-2 ml-2 text-xs text-green-500 bg-green-100 rounded-full md:block">
        {props.badge}
      </span>
    )} */}
      </div>

      {props.isActive && (
        <ActiveLine className="invisible -mr-2 border-2 border-solid border-primary-500 text-gray-500 md:-mr-8 lg:visible" />
      )}
    </li>
  );
};

const NewNavItem = (props: NewNavItemProps) => {
  // const className = `h-10 w-full my-2 flex justify-center items-center bg-cowork-${
  //   props.isActive ? 'blue' : 'grey'
  // } opacity-50 hover:opacity-60`;

  // TODO: EVENT button is leading nowhere - trying to figure out what is going on - added "props.title === 'Events'" below
  return props.title === NavItemTitles.ARTICLES ||
    props.title === NavItemTitles.EVENTS ||
    props.to.includes('eventbrite') ? (
    <a href={props.to} target="_blank" rel="noreferrer" className="w-full">
      <Contents
        title={props.title}
        icon={props.icon}
        isActive={props.isActive}
      />
    </a>
  ) : (
    <Link to={props.to} className="w-full">
      <Contents
        title={props.title}
        icon={props.icon}
        isActive={props.isActive}
      />
    </Link>
    // <div className={className} style={{ fontFamily, lineHeight }}>
    //   {props.title === 'Articles' || props.to.includes('eventbrite') ? (
    //     <a href={props.to} target="_blank" rel="noreferrer">
    //       {props.title}
    //     </a>
    //   ) : (
    //     <Link to={props.to}>{props.title}</Link>
    //   )}
    // </div>
  );
};

const NewNavItems = ({
  location,
  isTownSquare,
  isDropdown,
  setDropdownOpen,
  hasProfile,
}: NewNavItemsProps) => {
  const items = [
    {
      icon: faBrowser,
      title: NavItemTitles.LOCATIONS,
      to: '/venues',
      isActive: ['/venues', '/dashboard'].includes(location.pathname),
    },
    {
      icon: faClipboardCheck,
      title: NavItemTitles.MY_BOOKINGS,
      to: '/bookings',
      isActive: location.pathname === '/bookings',
      isHidden: !hasProfile,
    },
    {
      icon: faUserCircle,
      title: NavItemTitles.MEMBERSHIP,
      to: '/membership',
      isActive: location.pathname === '/membership',
      isHidden: !hasProfile,
    },
    {
      icon: faCalendarAlt,
      title: NavItemTitles.EVENTS,
      to: 'https://www.coworklocal.co.uk/business-support',
    },
    {
      icon: faNewspaper,
      title: NavItemTitles.ARTICLES,
      to: 'https://www.coworklocal.co.uk/news',
    },
    // {
    //   icon: faUsers,
    //   title: 'Support',
    //   to: '/need a link to support',
    // },
  ];
  return isDropdown ? (
    <div
      id="dropdown"
      className="absolute right-0 top-14 z-10 w-44 divide-y divide-gray-100 rounded bg-white shadow-md"
      // TODO: style list items and fix z-index @sarah
    >
      <ul
        className="text-gray-700 "
        aria-labelledby="dropdownMenuIconHorizontalButton"
      >
        {items.map((item, idx) => (
          <li key={idx} className={`${item.isHidden && 'hidden'}`}>
            {item.title === NavItemTitles.ARTICLES ||
            item.title === NavItemTitles.EVENTS ? (
              <a
                href={item.to}
                onClick={() =>
                  setDropdownOpen ? setDropdownOpen(false) : null
                }
                target="_blank"
                rel="noreferrer"
                className="test block px-3 py-2 hover:bg-gray-100"
              >
                {item.title}
              </a>
            ) : (
              <Link
                to={item.to}
                onClick={() =>
                  setDropdownOpen ? setDropdownOpen(false) : null
                }
                key={idx}
                className={`${
                  item.isHidden ? 'hidden' : 'block'
                } py-2 px-3 hover:bg-gray-100`}
              >
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <>
      {items.map((item, idx) => (
        <div
          className={`${
            item.isHidden ? 'hidden' : 'flex lg:block'
          } flex-1 justify-between px-4 text-lg font-bold md:justify-around`}
          key={idx}
        >
          <NewNavItem
            icon={item.icon}
            title={item.title}
            to={item.to}
            isActive={item.isActive ?? undefined}
          />
        </div>
      ))}
      {/* <NewNavItem
        title="Locations"
        to="/venues"
        isActive={['/venues', '/dashboard'].includes(location.pathname)}
      />
      <NewNavItem
        title="My Bookings"
        to="/bookings"
        isActive={location.pathname === '/bookings'}
      />
      <NewNavItem
        title="Membership"
        to="/membership"
        isActive={location.pathname === '/membership'}
      />
      <NewNavItem
        title="Events"
        to={
          isTownSquare
            ? '/events'
            : 'https://www.eventbrite.co.uk/o/cowork-local-40814554873'
        }
      />
      <NewNavItem title="Articles" to="https://www.coworklocal.co.uk/news" />
      <NewNavItem
        title="Support"
        to="/"
        // TODO: What's the link to support? 
      /> */}
    </>
  );
};

export default NewNavItems;
