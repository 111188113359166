import { OtherPriceCard, PricingCard } from './PricingCard';
import { PlanNames, pricingArray, returnPlanDetails } from './membershipUtils';
import useMembership from '../../hooks/useMembership';
import { useEffect } from 'react';

type PlansProps = {
  onUpgrade: (id: PlanNames) => void;
  cancelling?: boolean;
  cancellingAt?: Date;
  features?: string[];
} & (PlansWithAmenitiesProps | PlansWithoutAmenitiesProps);

type PlansWithAmenitiesProps = {
  includesAmenities: true;
  setIsModalOpen: (isOpen: boolean) => void;
};

type PlansWithoutAmenitiesProps = {
  includesAmenities?: false;
};

const Plans = (props: PlansProps) => {
  const { activeMembership } = useMembership();

  const { planTitle, planDescription } = returnPlanDetails(
    activeMembership?.plan.name
  );

  // console.log(
  //   '😎 props at Plans',
  //   props,
  //   'planTitle',
  //   planTitle,
  //   'planDescription',
  //   planDescription
  // );

  useEffect(() => {}, [activeMembership]);

  // return
  // !activeMembership ? (
  //   <>LOADING</>
  // ) :

  return (
    // <div className="grid grid-cols-3 grid-rows-3 gap-8 ">
    <>
      {pricingArray.map((price, idx) => {
        const isCurrentPlan =
          planTitle?.toLowerCase() === price.title.toLowerCase();
        return props.includesAmenities && isCurrentPlan ? (
          <PricingCard
            key={price.id}
            index={idx}
            membership={price}
            setIsModalOpen={props.setIsModalOpen}
            features={props.features}
            planTitle={planTitle}
            planDescription={planDescription}
            cancelling={props.cancelling}
            cancellingAt={props.cancellingAt}
          />
        ) : (
          <OtherPriceCard
            key={price.id}
            index={idx}
            membership={price}
            onUpgrade={props.onUpgrade}
            planTitle={price.id}
            planDescription={price.description}
          />
        );
      })}
      {/* {pricing.map((p) => {
        console.log(p.title);
        const thisPlan = returnPlanDetails(p.title);
        console.log(thisPlan.planTitle?.toLowerCase() === titleOfCurrentPlan);
        // TODO: Fix this!
        return props.includesAmenities &&
          thisPlan.planTitle?.toLowerCase() === titleOfCurrentPlan ? (
          <NewAmenitiesCardComponent
            key={p.id}
            onClick={() => props.setIsModalOpen(true)}
            buttonTitle={
              titleOfCurrentPlan === PlanNames.DAYPASS
                ? 'Currently Active'
                : props.cancelling
                ? 'Cancelling...'
                : 'Cancel Subscription'
            }
            features={props.features}
            title={titleOfCurrentPlan}
            pricing={props.pricing}
            addOn={
              //TODO: Fix this!
              props.activeMembership?.plan?.description ?? descripOfCurrentPlan
            }
          />
        ) : (
          <CardComponent
            key={p.id}
            className="flex flex-col items-center justify-between shadow-none transition-all"
          >
            <div className="w-full">
              <h2 className="mb-4 text-center text-lg font-bold text-gray-800">
                {planTitle === PlanNames.BASIC
                  ? 'Basic Access'
                  : planTitle === PlanNames.UNLIMITED
                  ? 'Full Access'
                  : 'Pay As You Go'}
              </h2>
              <p className="text-md mb-4 text-center text-gray-500">
                {planDescription}
              </p>
              <p className="text-md mb-4 text-center font-bold text-gray-500">
                {formatCurrency(p.price, 0)} + VAT / month
              </p>

              <div
                className={`${
                  planTitle &&
                  ![PlanNames.BASIC, PlanNames.UNLIMITED].includes(planTitle) &&
                  'hidden'
                }`}
              >
                <button
                  disabled={planTitle === PlanNames.DAYPASS}
                  style={
                    planTitle === PlanNames.DAYPASS ? { cursor: 'default' } : {}
                  }
                  onClick={() => props.onUpgrade(p.id)}
                  className="hover:text-bg-primary-900 mt-2 w-full rounded bg-primary-200 p-2 font-bold text-primary-800 transition-all hover:bg-primary-300"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </CardComponent>
        );
      })} */}
    </>
    // </div>
  );
};

export default Plans;
