import * as React from 'react';
import styled from 'styled-components';
import { Popup } from 'react-map-gl';
import { Link } from 'react-router-dom';
import { Listing } from './MapMultiComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Div = styled.div``;

const Image = styled.img``;

const StyledPopup = styled(Popup)`
  position: relative;
  font-family: 'Futura PT', sans-serif;
  font-size: 0.9rem;
  & .mapboxgl-popup-content {
    border-radius: 12px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
`;

const Close = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  height: 15px;
  width: 15px;
`;

const MyPopup = ({
  popupInfo,
  setPopupInfo,
}: {
  popupInfo: Listing;
  setPopupInfo: React.Dispatch<React.SetStateAction<Listing | null>>;
}) => {
  return (
    <StyledPopup
      longitude={Number(popupInfo.lng)}
      latitude={Number(popupInfo.lat)}
      // onClose={() => setPopupInfo(null)}
      closeOnClick
      closeButton={false}
    >
      <Div className="popup-info pb-3 pr-3">
        <strong>
          {popupInfo.name}, {popupInfo.city}
        </strong>
        <br />
        <StyledLink to={`venues/${popupInfo._id}`}>Go to office</StyledLink>
      </Div>
      <StyledLink to={`venues/${popupInfo._id}`}>
        <Image
          width="100%"
          src={popupInfo.image}
          alt={popupInfo.name}
          className="popup-image"
        />
      </StyledLink>
      <Close>
        <button
          data-dropdown-toggle="dropdown"
          className="focus:outline-none inline-flex h-full w-full cursor-pointer items-center rounded-lg bg-white p-1 text-center font-medium text-gray-900 hover:bg-gray-300"
          type="button"
          onClick={() => setPopupInfo(null)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Close>
    </StyledPopup>
  );
};

export default MyPopup;
