import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import NewBookingForm from './NewBookingForm';
import { Office } from '../../types/officeRND/offices';
import {
  BookingRequestInput,
  BookingRequestResponse,
  //CreateBookingResponse,
} from '../../types/officeRND/BookingRequest';
import useBookings from '../../hooks/useBookings';
// import officeInfo from '../../utils/officeInformation';
import { CreateBookingType } from '../../hooks/useBookings/useBookings';
import { UserContext } from '../../context/user';
import { isUsingCredits } from '../../utils/isUsingCredits';
import type { Body } from '../../../netlify/functions/create-checkout-url';
import axios from 'axios';
import useMembership from '../../hooks/useMembership';
import { useToasts } from 'react-toast-notifications';
import useResources from '../../hooks/useResources';
import {
  //Redirect,
  useHistory,
} from 'react-router';

import utc from 'dayjs/plugin/utc';
import compareLocalDateToUTCDate from '../../utils/compareLocalDateToUTCDate';
dayjs.extend(utc);

type NewBookingFormContainerProps = {
  office?: Office;
};

export type Method = 'DAYPASS' | 'PAYMENT';

type CheckAvailabilityProps = {
  date: Date;
  period: 'am' | 'pm' | 'allDay';
  type: CreateBookingType;
};

export type Availability = {
  date: Date;
  bookingRequest?: BookingRequestResponse;
  hasAvailability: boolean;
  canUseDayPasses: boolean;
  canUsePayment: boolean;
  dayPassesRemaining: number;
};

export type CheckAvailabilityFunc = (
  props: CheckAvailabilityProps
) => Promise<void>;
export type CreateBookingFunc = (props: Method) => Promise<void>;

const NewBookingFormContainer: React.FC<NewBookingFormContainerProps> = ({
  office,
}) => {
  const { getResources, resources } = useResources();
  const { checkBooking, createBooking, loading } = useBookings({ mock: true });
  const { getNumberOfDayPasses } = useMembership();
  const { profile } = useContext(UserContext);
  const [availability, setAvailability] = useState<Availability | undefined>();
  const { addToast } = useToasts();

  useEffect(() => {
    if (!office?._id) return;
    getResources({
      office: office?._id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office?._id]);

  const history = useHistory();

  const checkAvailability: CheckAvailabilityFunc = async (props) => {
    try {
      // IN CASE OF BST/UTC TIMEZONE DIFFERENCE
      // compareLocalDateToUTCDate(dayjs(props.date).startOf('day'));
      let date = dayjs(props.date).startOf('day');
      let start, end;

      if (props.type === CreateBookingType.MEETING_ROOM) {
        try {
          const body = {
            venue: office?.name,
            numberOfPeople: 'unknown',
            date: date.format('YYYY-MM-DD'),
            memberName: profile?.member.name,
            memberEmail: profile?.member.email,
            memberNumber: profile?.member.phone,
          };
          console.log('body', body);

          // await axios.post('/.netlify/functions/request-meeting-room', {
          //   ...body,
          // });
          addToast(
            `Your request has been sent. The Cowork Local team will check meeting room availability of ${office?.name} and get back to you shortly.`,
            { appearance: 'success' }
          );
          return;
        } catch (error: any) {
          throw error;
        }
      }

      // const officeMeta = officeInfo.find((off) => off.slug === office?.slug);

      // let morningHour = 9;
      // let morningMin = 0;
      // let afternoonHour = 15;
      // let eveningHour = 16;
      // let eveningMin = 0;

      // // If we know the opening times, set the times accordingly
      // if (officeMeta) {
      //   morningHour = dayjs(officeMeta.opening_time).get('hour');
      //   morningMin = dayjs(officeMeta.opening_time).get('minute');
      //   eveningHour = dayjs(officeMeta.closing_time).get('hour');
      //   eveningMin = dayjs(officeMeta.closing_time).get('minute');
      // }
      // console.log(officeMeta, morningHour, morningMin, eveningHour, eveningMin);

      // start = date.startOf('d');
      // end = start.add(1, 'd').startOf('d');
      // switch (props.period ?? 'allDay') {
      //   case 'am':
      //     start = date.hour(morningHour).minute(morningMin);
      //     end = date.hour(afternoonHour);
      //     break;
      //   case 'pm':
      //     start = date.hour(afternoonHour);
      //     end = date.hour(eveningHour).minute(eveningMin);
      //     break;
      //   case 'allDay':
      //     start = date.hour(morningHour).minute(morningMin);
      //     end = date.hour(eveningHour).minute(eveningMin);
      //     break;
      //   default:
      //     start = date.hour(morningHour).minute(morningMin);
      //     end = date.hour(eveningHour).minute(eveningMin);
      //     break;
      // }

      start = date;
      end = start.clone().add(1, 'day');

      console.log('start and end at booking request', start, end);

      const readableType =
        props.type === CreateBookingType.HOTDESK ? 'Hot desk' : 'Meeting Room';
      const title = `${readableType} @ ${office?.name}`;

      const request: BookingRequestInput = {
        resourceType: props.type,
        description: title,
        summary: title,
        start: start.toISOString(),
        end: end.toISOString(),
        count: 1, // Number of people maybe
        // If there is no team id, there needs to be a memberId
        team: profile?.team._id,
        member: profile?.member._id,
        office: office?._id ?? '',
        timezone: 'Europe/London',
      };

      const bookingRequest = await checkBooking(request);
      if (!bookingRequest) throw new Error('No booking request');
      const useCredits = isUsingCredits(bookingRequest[0]);
      // console.log(bookingRequest);

      const dayPassesRemaining = await getNumberOfDayPasses(
        dayjs(date).toDate()
      );

      setAvailability({
        date: props.date,
        bookingRequest: bookingRequest[0],
        hasAvailability: bookingRequest?.[0].fees.length > 0,
        canUseDayPasses: useCredits && dayPassesRemaining > 0,
        canUsePayment: true,
        dayPassesRemaining,
      });
    } catch (error: any) {
      let message = error?.response?.data?.message ?? 'Not available sorry';
      console.error(message);

      const dayPassesRemaining = await getNumberOfDayPasses(
        dayjs(props.date).startOf('day').toDate()
      );

      setAvailability({
        date: props.date,
        hasAvailability: false,
        canUseDayPasses: false,
        canUsePayment: false,
        dayPassesRemaining,
      });

      throw message;
    } finally {
      // setLoading(false);
    }
  };

  const confirmBooking: CreateBookingFunc = async (method) => {
    const bookingRequest = availability?.bookingRequest;

    if (!bookingRequest?.start || !bookingRequest?.end) {
      throw new Error('No date passed');
    }
    if (!bookingRequest?.resourceId) {
      throw new Error('No resource passed');
    }

    try {
      if (method === 'DAYPASS') {
        const confirmedBooking = await createBooking({
          description: bookingRequest?.description ?? '',
          summary: bookingRequest?.summary ?? '',
          start: bookingRequest?.start,
          end: bookingRequest?.end,
          team: profile?.team._id ?? '',
          member: profile?.member._id ?? '',
          resourceId: bookingRequest?.resourceId ?? '',
          tentative: false,
        });
        console.log('creating hotdesk booking using daypass', confirmedBooking);
        // console.log(`confirmedBooking`, confirmedBooking);
        history.push(
          `/bookings?new=true&office=${
            office?.name
          }&date=${availability?.date.toISOString()}`
        );
      }

      if (method === 'PAYMENT') {
        const input: Body = {
          email: profile?.member.email ?? profile?.team.email,
          teamId: profile?.team._id,
          memberId: profile?.member._id,
          planName: 'dayPass',
          bookingRequest: bookingRequest,
        };
        console.log('creating hotdesk booking using payment', input);
        const { data } = await axios.post(
          '/.netlify/functions/create-checkout-url',
          input
        );
        if (data.url) {
          window.open(data.url, '_self');
        }
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.message ??
        'Something went wrong with your booking';
      throw message;
    } finally {
    }
  };

  return (
    <NewBookingForm
      office={office}
      checkAvailability={checkAvailability}
      availability={availability}
      confirmBooking={confirmBooking}
      resetForm={() => setAvailability(undefined)}
      loading={loading}
      resources={resources ?? []}
    />
  );
};

export default NewBookingFormContainer;
