/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import Input from '../../components/InputComponent/InputComponent';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';

export type Inputs = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { login } = useContext(UserContext);

  const onSubmit = () => {
    handleSubmit(
      async (data: Inputs) => {
        if (data.password && data.email) {
          try {
            setLoading(true);
            await login(data.email, data.password);
            history.push('/dashboard');
          } catch (error: any) {
            console.log(error);
            addToast(error.message, { appearance: 'error' });
          } finally {
            setLoading(false);
          }
        }
      },
      (errors) => {
        console.log('Form Error!', errors);
        setLoading(false);
      }
    )();
  };

  return (
    <div className="my-2">
      <form className="w-full text-left" onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4">
          <Controller
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                autoComplete="email"
                placeholder="name@company.co.uk"
                label="Email Address"
                type="text"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="email"
            control={control}
            defaultValue=""
            // rules={{ required: true, pattern: /^\S+@\S+$/i }}
          />
        </div>

        <div className="mb-4">
          <Controller
            {...register('password', { required: true })}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                autoComplete="current-password"
                type="password"
                label="Password"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="password"
            control={control}
            defaultValue=""
            // rules={{ required: true }}
          />
        </div>
        <div className="flex w-full justify-end">
          <PrimaryButtonComponent
            loading={loading}
            className="mt-2 flex w-full justify-center text-center"
            type="submit"
            onClick={onSubmit}
          >
            Log In
          </PrimaryButtonComponent>
        </div>
      </form>
    </div>
  );
};
export default LoginForm;
