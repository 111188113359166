import BookingsScreen from './BookingsScreen';

type BookingsScreenContainerProps = {};

const BookingsScreenContainer: React.FC<BookingsScreenContainerProps> = (
  props
) => {
  return <BookingsScreen />;
};

export default BookingsScreenContainer;
