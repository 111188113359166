// Global types (types not confined to one component) go here

export type MyType = {
  // first: string;
  // second: number;
};

export enum TailwindTheme {
  'COWORK-LOCAL' = 'theme-coworklocal',
  'TOWNSQ' = 'theme-townsquare',
}
