import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import {
  Map,
  MapProvider,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  ViewState,
} from 'react-map-gl';
import Pin from './Pin';
import Popup from './Popup';
import MapFlyHolder from './MapFlyHolder';
import { Areas } from '../../types/Areas';

const MapWrapper = styled.div`
  height: 400px;
`;

const token = process.env.REACT_APP_MAPBOX_TOKEN;

const starterViewState = {
  latitude: 51.62576981254209,
  longitude: -3.3953359503842857,
  zoom: 8,
};

export type Listing = {
  _id: string;
  name: string;
  city: string | undefined;
  lat: string | undefined;
  lng: string | undefined;
  image: string | undefined;
};

type InitialViewState =
  | (Partial<ViewState> & {
      bounds?: mapboxgl.LngLatBoundsLike | undefined;
      fitBoundsOptions?: mapboxgl.FitBoundsOptions | undefined;
    })
  | undefined;

export type MapMultiComponentProps = {
  activeArea?: Areas | '';
  listings: Listing[];
};

const MapMultiComponent: React.FC<MapMultiComponentProps> = ({
  listings,
  activeArea,
}) => {
  if (!listings) {
    console.error('No Mapbox found. Please check latitude and longitude.');
    return (
      <ReactLoading color="black" type={'bubbles'} width={48} height={48} />
    );
  }

  return (
    <MapProvider>
      <MyMap
        listings={listings}
        state={starterViewState}
        activeArea={activeArea}
      />
    </MapProvider>
  );
};

export default MapMultiComponent;

const MyMap = ({
  listings,
  state,
  activeArea,
}: {
  listings: Listing[];
  state: InitialViewState;
  activeArea?: Areas | '';
}) => {
  const [popupInfo, setPopupInfo] = React.useState<Listing | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialViewState, setInitialViewState] = React.useState(state);

  // const context = React.useContext(MapContext);

  const geolocateControlRef = React.useCallback((ref) => {
    if (ref) {
      // Activate as soon as the control is loaded
      ref.trigger();
    }
  }, []);

  React.useEffect(() => {
    setPopupInfo(null);
  }, [activeArea]);

  return (
    <MapWrapper>
      <Map
        mapboxAccessToken={token}
        initialViewState={initialViewState}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        <MapFlyHolder activeArea={activeArea} listings={listings} />
        <GeolocateControl position="top-left" ref={geolocateControlRef} />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        {listings.map((listing: Listing, idx: number) => (
          <Pin key={idx} listing={listing} setAction={setPopupInfo} />
        ))}
        {popupInfo && (
          <Popup popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
        )}
      </Map>
    </MapWrapper>
  );
};
