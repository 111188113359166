import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
//import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';

import Input from '../../components/InputComponent/InputComponent';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';

export type Inputs = {
  name: string;
  company: string;
  email: string;
  password: string;
  phone: string;
};

const RegisterForm = () => {
  const url = new URL(window.location.href);
  const name = url.searchParams.get('name');
  const companyName = url.searchParams.get('companyName');
  const email = url.searchParams.get('email');
  const phone = url.searchParams.get('phone');

  const office = url.searchParams.get('office');
  const type = url.searchParams.get('type');
  const date = url.searchParams.get('date');

  const {
    handleSubmit,
    control,
    register,
    //formState: { errors },
  } = useForm<Inputs>();

  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const { signup, login } = useContext(UserContext);
  const history = useHistory();
  const onSubmit = async (data: Inputs) => {
    // console.log(data);
    if (data.password && data.email) {
      try {
        setLoading(true);
        const account = await signup(data);
        if (account) {
          addToast(
            'Account created! You now have 5 day passes to use as a thank you for signing up.',
            {
              appearance: 'success',
            }
          );
          const user = await login(data.email, data.password);
          if (office) {
            history.push(`/venues/${office}?type=${type}&date=${date}`);
          } else if (user?.member._id) {
            history.push('/dashboard');
          }
        }
      } catch (error: any) {
        addToast(error.message, { appearance: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form className="w-full text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className={`mb-4 ${name ? 'hidden' : ''}`}>
        <Controller
          {...register('name', { required: true })}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="Your name"
              type="text"
              errorMessage={fieldState.error?.message}
            />
          )}
          name="name"
          control={control}
          defaultValue={name ?? ''}
          rules={{ required: true }}
        />
      </div>

      <div className={`mb-4 ${companyName ? 'hidden' : ''}`}>
        <Controller
          {...register('company', { required: true })}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="text"
              label="Company Name"
              errorMessage={fieldState.error?.message}
            />
          )}
          name="company"
          control={control}
          defaultValue={companyName ?? ''}
          rules={{ required: true }}
        />
      </div>

      <div className="mb-4">
        <Controller
          {...register('phone', { required: false })}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="text"
              label="Phone"
              errorMessage={fieldState.error?.message}
            />
          )}
          name="phone"
          control={control}
          defaultValue={phone ?? ''}
          rules={{ required: false }}
        />
      </div>

      <div className="mb-4">
        <Controller
          {...register('email', { required: true })}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="email"
              disabled={!!email}
              label="Email"
              errorMessage={fieldState.error?.message}
            />
          )}
          name="email"
          control={control}
          defaultValue={email ?? ''}
          rules={{ required: true, pattern: /^\S+@\S+$/i }}
        />
      </div>

      <div className="mb-4">
        <Controller
          {...register('password', { required: true })}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="password"
              label="Password"
              errorMessage={fieldState.error?.message}
            />
          )}
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
        />
      </div>

      <div className="flex w-full justify-end">
        <PrimaryButtonComponent
          loading={loading}
          className="mt-2 flex w-full justify-center text-center"
          type="submit"
        >
          Sign up
        </PrimaryButtonComponent>
      </div>
      <div className="mt-4 flex justify-between text-center">
        <a
          className="text-sm text-primary-500 underline"
          href="https://cms.thetownsquare.co.uk/wp-content/uploads//2018/05/Privacy-policy-010518-1.pdf"
        >
          Privacy Policy
        </a>
        <a
          className="text-sm text-primary-500 underline"
          href="https://www.officernd.com/privacy/"
        >
          Office RND Privacy Policy
        </a>
      </div>
    </form>
  );
};
export default RegisterForm;
