import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import CardComponent from '../CardComponent';
// import PrimaryButtonComponent from '../PrimaryButtonComponent';

export type AmenitiesCardComponentProps = {
  features?: string[];
  title?: string;
  addOn?: string;
  description?: string;
  buttonTitle?: string;
  onClick?: () => void;
  capacity?: number;
  meetingRoomName?: string;
};

type FeatureProps = { name: string };

const Feature = (props: FeatureProps) => {
  return (
    <li className="mx-2 mb-4 text-gray-700 ">
      <FontAwesomeIcon className="mr-2 text-primary-500" icon={faCheck} />
      {props.name}
    </li>
  );
};

const AmenitiesCardComponent: React.FC<AmenitiesCardComponentProps> = (
  props
) => {
  return (
    <>
      <div className="mb-4 flex items-center justify-between text-lg font-bold">
        <h2>{props.title ?? ''}</h2>

        {props.addOn && (
          <h2>
            <span className="text-xs">{props.addOn}</span>
          </h2>
        )}
      </div>
      <div className="mt-1 flex flex-wrap justify-start">
        <ul>
          {props.features &&
            props.features.map((feature) => {
              return <Feature key={feature} name={feature} />;
            })}
        </ul>
      </div>
      <p className="mt-4 text-xs text-gray-500">{props.description ?? ''}</p>
      {props.onClick && (
        <div className="mt-8 flex justify-between">
          <p className="text-lg">Max capacity: {props.capacity}</p>
          <button
            className="h-10 border border-black p-2 font-bold"
            onClick={props.onClick}
          >
            {props.buttonTitle}
          </button>
        </div>
      )}
    </>
  );
};

export default AmenitiesCardComponent;
