import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import CardComponent from '../../components/CardComponent';
import ContentCardComponent from '../../components/ContentCardComponent';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import useAmenities from '../../hooks/useAmenities';
import useMembership from '../../hooks/useMembership';
import pattern from './pattern.svg';
import ScheduleACallForm from './ScheduleACallForm';
import axios from 'axios';
import { UserContext } from '../../context/user';
import { useToasts } from 'react-toast-notifications';
import { TailwindTheme } from '../../types';
import { Body } from '../../../netlify/functions/create-checkout-url';
import { PlanNames } from './membershipUtils';
import Plans from './Plans';
import dayjs from 'dayjs';

export type MembershipScreenProps = {
  enquireAboutTeams: () => void;
};

// type FeatureProps = { name: string };
// const Feature = (props: FeatureProps) => {
//   return (
//     <div className="mx-2 mb-4 text-gray-700">
//       <FontAwesomeIcon className="mr-2 text-primary-500" icon={faCheck} />

//       {props.name}
//     </div>
//   );
// };

const MembershipScreen: React.FC<MembershipScreenProps> = (props) => {
  const { activeMembership, loading, daysLeft } = useMembership();
  const { getAmenities, amenities } = useAmenities();
  const [showForm, setShowForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { profile } = useContext(UserContext);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [cancellingAt, setCancellingAt] = useState<Date | undefined>();
  const { addToast } = useToasts();

  useEffect(() => {
    const amenities = activeMembership?.plan?.amenities ?? [];

    if (amenities.length > 0) {
      getAmenities(amenities);
    }
    // console.log('activeMembership', activeMembership);
    if (activeMembership?.endDate) {
      setCancellingAt(dayjs(activeMembership?.endDate).toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMembership]);

  const handleChangeSubscription = async () => {
    const memberId = activeMembership?.member;

    setCancelling(true);

    try {
      await axios.post('/.netlify/functions/cancel-subscription', {
        memberId: memberId,
      });

      addToast(`Subscription cancelled, no further payments will be taken`, {
        appearance: 'success',
      });
      setCancellingAt(dayjs().add(daysLeft, 'day').toDate());
    } catch (error) {
      addToast(
        'We could not cancel your subscription, please get in touch with us.',
        {
          appearance: 'error',
        }
      );
    } finally {
      if (isModalOpen) {
        setIsModalOpen(false);
      }
      setCancelling(false);
    }
  };

  const upgradeAccount = async (planName: PlanNames) => {
    const userTeamId = profile?.team._id;
    try {
      if (!planName) {
        throw new Error('Plan ID is required');
      }
      const input: Body = {
        email: profile?.member.email ?? profile?.team.email,
        teamId: userTeamId,
        memberId: profile?.member._id,
        planName,
      };
      const { data } = await axios.post(
        '/.netlify/functions/create-checkout-url',
        input
      );
      if (data.url) {
        window.open(data.url, '_self');
      }
    } catch (error) {
      console.log('error', JSON.stringify(error));
    }
  };

  const features = amenities.map((am) => am.title);

  if (loading) {
    return (
      <div className="flex min-h-full items-center justify-center">
        <ReactLoading color="black" type="bubbles" />
      </div>
    );
  }

  if (!activeMembership) {
    return (
      <Wrapper>
        <ContentCardComponent
          className="relative overflow-hidden"
          title="Join Today!"
          description="Cowork Local membership gives you flexible access to spaces as much or as often as you need. Our membership options give you a range of options to suit the way you work."
        >
          <img
            className="invisible absolute -bottom-8 -right-32 max-w-sm md:visible lg:-right-24"
            alt="pattern"
            src={pattern}
          />
          {showForm && (
            <div className="max-w-sm">
              <ScheduleACallForm onCancel={() => setShowForm(false)} />
            </div>
          )}
          {/* {!showForm && (
            <PrimaryButtonComponent
              onClick={() => setShowForm(true)}
              className="mt-8"
            >
              Become a member
            </PrimaryButtonComponent>
          )} */}
        </ContentCardComponent>
        <CardComponent className="md:max-w-sm">
          <h2 className="mb-4 text-lg font-bold">Get in touch</h2>
          <p className="mb-2">
            <FontAwesomeIcon
              className="mr-2 text-primary-500"
              icon={faEnvelope}
            />
            <a
              className="text-primary-500 underline hover:text-primary-600"
              href="mailto:coworklocal@townsq.co.uk"
            >
              coworklocal@townsq.co.uk
            </a>
          </p>
          <p>
            <FontAwesomeIcon className="mr-2 text-primary-500" icon={faPhone} />
            02921 111 252
          </p>
        </CardComponent>
        <div>
          <h2 className="my-4 mt-10 text-2xl font-bold text-gray-700">
            Upgrade your account
          </h2>
        </div>
        <Plans onUpgrade={upgradeAccount} />
      </Wrapper>
    );
  }

  // const { planTitle, planDescription } = returnPlanTitle(
  //   activeMembership?.name
  // );

  return (
    <Wrapper>
      <div className="flex flex-wrap items-center justify-between">
        <h2 className="max-w-sm text-2xl font-bold text-gray-700">
          Membership
        </h2>
      </div>
      {/* <ContentCardComponent
        className="relative overflow-hidden"
        title={`Your membership `}
        description="Manage your membership below"
      >
        <img
          className="invisible absolute -bottom-8 -right-32 max-w-sm md:visible lg:-right-24"
          alt="pattern"
          src={pattern}
        />
      </ContentCardComponent> */}
      <div className="mt-8 grid grid-cols-1 gap-4 md:gap-6 lg:grid-cols-3">
        <Plans
          includesAmenities
          onUpgrade={upgradeAccount}
          setIsModalOpen={setIsModalOpen}
          cancelling={cancelling}
          cancellingAt={cancellingAt}
          features={features}
        />
        <CardComponent className="items-left col-span-1 flex flex-col justify-between shadow-none transition-all lg:col-span-3">
          <h2 className="mb-4 text-lg font-bold text-gray-800">
            Team membership
          </h2>
          <p className="text-md mb-4 text-gray-500">
            Our team memberships are ideal for teams of any size who want to
            utilise our network of coworking spaces. Looking for a membership
            for members of your team? Register your interest and a community
            manager will be in touch to help you get started.
          </p>
          <button
            onClick={() => props.enquireAboutTeams()}
            className="hover:text-bg-primary-900 mt-2 w-full rounded bg-primary-500 p-2 font-bold text-white transition-all hover:bg-primary-300 lg:w-1/3"
          >
            Enquire now
          </button>
        </CardComponent>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        className={`${TailwindTheme['COWORK-LOCAL']} translate-y-1/5 absolute right-1/2 w-full max-w-lg translate-x-1/2 transform rounded bg-white p-8 text-center shadow-lg md:translate-y-1/4`}
      >
        <div className="">
          <h2 className="text-2xl font-bold text-gray-700">
            Are you sure you want to cancel your subscription?
          </h2>
          <p className="mt-4 text-gray-600">
            Your membership will stay active for the next {daysLeft} days, but
            we will not take any further payments.
          </p>
          <div className="flex flex-col-reverse justify-between">
            <PrimaryButtonComponent
              loading={cancelling}
              onClick={() => {
                handleChangeSubscription();
              }}
            >
              Yes
            </PrimaryButtonComponent>
            <button
              className="my-4 rounded bg-gray-200 p-2 text-gray-700 transition-all hover:bg-gray-300"
              onClick={() => setIsModalOpen(false)}
            >
              No
            </button>
          </div>
        </div>
      </ReactModal>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default MembershipScreen;
