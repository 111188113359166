import { useMap } from 'react-map-gl';
import styled from 'styled-components';
import { Areas } from '../../types/Areas';
import { Listing } from './MapMultiComponent';

const Spacer = styled.span``;

function MapFlyHolder({
  activeArea,
  listings,
}: {
  activeArea?: Areas | '';
  listings: Listing[];
}) {
  const { current: map } = useMap();

  console.log(listings.length, { map, activeArea });

  const avgLat = listings.reduce(
    (acc, listing) => acc + Number(listing.lat),
    0
  );

  const avgLng = listings.reduce(
    (acc, listing) => acc + Number(listing.lng),
    0
  );

  const array = Object.values(Areas);

  const zoom =
    activeArea === Areas.DENBIGHSHIRE
      ? 8
      : activeArea === Areas.VALE_OF_GLAM
      ? 10
      : activeArea === Areas.SWANSEA
      ? 11
      : activeArea === Areas.OTHER_REG || activeArea === ''
      ? 6
      : 10;

  if (activeArea && array.includes(activeArea)) {
    map?.flyTo({
      center: [avgLng / listings.length, avgLat / listings.length],
      zoom,
    });
  }

  return <Spacer />;
}

export default MapFlyHolder;
