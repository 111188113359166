import React, { useEffect, useContext } from 'react';
import LocationCards from './LocationCard';
import { Office } from '../../types/officeRND/offices';
import useOffices from '../../hooks/useOffices';
import { useParams } from 'react-router';
import { UserContext } from '../../context/user';
import Fuse from 'fuse.js';
import Mapbox from '../../components/NewMapMultiComponent';
import { Areas } from '../../types/Areas';

type Params = {
  officeId?: string;
};

type NewWorklocalSpacesScreenProps = {
  searchTerm: string | undefined;
  activeArea: Areas | '';
  areas: string[];
};

const NewWorklocalSpacesScreen: React.FC<NewWorklocalSpacesScreenProps> = ({
  searchTerm,
  activeArea,
  areas,
}) => {
  // const history = useHistory();

  const params = useParams<Params>();

  const { profile } = useContext(UserContext);

  const {
    getAndSetOffices,
    offices,
    //loading
  } = useOffices({
    mock: false,
  });

  let filteredOffices: Office[] = offices;

  if (activeArea) {
    filteredOffices = offices.filter((o) => {
      if (activeArea === 'Other regions') {
        return o.state === undefined || !areas.includes(o.state);
      } else {
        return o.state === activeArea;
      }
    });
  }

  if (searchTerm) {
    let options: Fuse.IFuseOptions<Office> = {
      minMatchCharLength: 3,
      keys: ['name', 'city', 'zip', 'description'],
    };
    const fuse = new Fuse(filteredOffices, options);
    const results = fuse.search(searchTerm);
    filteredOffices = results.map((res) => res.item);
  }

  const {
    //office,
    getOffice,
  } = useOffices({ mock: false });

  useEffect(() => {
    let officeId = params?.officeId ?? profile?.member?.office;

    if (officeId) {
      getOffice(officeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.officeId, profile?.member?.office]);

  useEffect(() => {
    getAndSetOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listings = filteredOffices.map((office) => ({
    _id: office._id,
    name: office.name,
    city: office.city,
    image: office.image,
    lat: office.physicalAddress?.latitude,
    lng: office.physicalAddress?.longitude,
  }));

  return (
    <div className="mt-4">
      {/* {props.loading && (
        <div className="flex h-full w-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )} */}

      {!searchTerm && (
        <Mapbox listings={listings} activeArea={activeArea} />
      )}

      <div
        className="grid grid-cols-1 gap-x-4 gap-y-0 sm:grid-cols-2 lg:grid-cols-6"
        //flex flex-row flex-wrap"
        // grid gap-3 sm:grid-cols-3
      >
        <LocationCards filteredOffices={filteredOffices} />
      </div>
    </div>
  );
};

export default NewWorklocalSpacesScreen;

// const Button = styled.button`
//   background-color: #25b092;
//   font-family: 'Futura PT', sans-serif;
//   border-radius: 5px;
// `;
