import { Booking } from '../../types/officeRND/Booking';

export const mockBookings: Booking[] = [
  {
    createdAt: '2019-06-21T13:02:45.333Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:54.137Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2019-06-21T09:00:00.000Z',
    },
    end: {
      dateTime: '2019-06-21T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2019-06-21T09:00:00.000Z',
    seriesEnd: '2019-06-21T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2019-06-21T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5d0cd575d4f6580010b65d5b',
          quantity: 4,
          price: 20,
          name: 'Ankerwycke, £80.00, 4 x £20.00 / hour - Jun 21, 2019',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5d02283fc8737c00107a2467',
    member: null,
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'X5V144K',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5d0cd575d4f6580010b65d70',
  },
  {
    createdAt: '2019-06-21T13:48:39.972Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2019-08-02T11:02:26.607Z',
    modifiedBy: '5d43461d4032200094acdd98',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2019-06-23T23:00:00.000Z',
    },
    end: {
      dateTime: '2019-06-24T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    seriesStart: '2019-06-23T23:00:00.000Z',
    seriesEnd: '2019-06-24T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-21T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d0cdca63a7a2100d1a19b74',
      price: 5,
      deposit: 0,
      type: 'carpark',
      office: '5c62cd1855d7470010d90776',
      room: '5d0cdb3cd5e99d007a8c4fa6',
      name: '1',
      number: 58,
      targetPlan: '5a6932f7f94d74160049d33a',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-21T13:33:26.054Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2020-08-10T13:19:08.951Z',
      modifiedBy: '5d43461d4032200094acdd98',
      parents: [],
      rate: '5d0cdf8a3a7a2100d1a36ada',
      target: '5d5d48324f3867001028465e',
    },
    team: '5cfff39634e36509d69beb46',
    member: null,
    visitors: [],
    members: [],
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: 'DD50M4K',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5d0ce03736dfc30011d6fd06',
  },
  {
    createdAt: '2019-08-05T15:17:46.424Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:54.110Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2019-08-06T08:00:00.000Z',
    },
    end: {
      dateTime: '2019-08-06T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: "Sara's meeting",
    seriesStart: '2019-08-06T08:00:00.000Z',
    seriesEnd: '2019-08-06T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2019-08-06T00:00:00.000Z',
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d441beae6b60a00a5866cd7',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: 'X2Q574M',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5d48489ac9cec601ff487215',
  },
  {
    createdAt: '2019-08-05T15:18:55.390Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:56.627Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2019-08-06T08:00:00.000Z',
    },
    end: {
      dateTime: '2019-08-06T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    seriesStart: '2019-08-06T08:00:00.000Z',
    seriesEnd: '2019-08-06T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2019-08-06T00:00:00.000Z',
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d441beae6b60a00a5866cd7',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: '987CN4M',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5d4848df3a3d82018f2f2646',
  },
  {
    createdAt: '2020-02-19T12:11:21.678Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.628Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-21T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-21T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting with new supplier',
    seriesStart: '2020-02-21T11:00:00.000Z',
    seriesEnd: '2020-02-21T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e4d2529954765029d7bccee',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    free: true,
    accounted: true,
    reference: 'SKX5Q4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e4d25e902c2e402cc22d9d2',
  },
  {
    createdAt: '2020-02-19T12:20:17.618Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.130Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-28T08:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-02-28T13:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'meeting with breakfast and lunch x11',
    seriesStart: '2020-02-28T08:30:00.000Z',
    seriesEnd: '2020-02-28T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-28T00:00:00.000Z',
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e4d274fa55d19008a182fe5',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'NG2P84T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e4d2801a55d19008a187e78',
  },
  {
    createdAt: '2020-02-25T09:49:07.063Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.629Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-24T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-24T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting room hire',
    seriesStart: '2020-02-24T10:00:00.000Z',
    seriesEnd: '2020-02-24T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e5b6cf951a2dd0010346e07',
          },
          _id: '5e54ed9231451e00dc9062f5',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Feb 24, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '1B9RD4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e54ed9331451e00dc9062f8',
  },
  {
    createdAt: '2020-02-25T12:03:45.952Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.669Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-25T08:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-02-25T11:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Robert Jolley Meeting booking PO: CDC018702',
    seriesStart: '2020-02-25T08:30:00.000Z',
    seriesEnd: '2020-02-25T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e5f8f907e0b96024db5eebe',
          },
          _id: '5e550d7d31451e00dca13a46',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Feb 25, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e550c1a4821d801033f8aaa',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '31C7X4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e550d214821d80103402b64',
  },
  {
    createdAt: '2020-02-25T12:04:59.377Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.630Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-26T09:30:00.000Z',
    },
    end: {
      dateTime: '2020-02-26T13:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Robert Jolley meeting booking  PO: CDC018703',
    seriesStart: '2020-02-26T09:30:00.000Z',
    seriesEnd: '2020-02-26T13:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e5f8f2083c297010db91516',
          },
          _id: '5e550d6bfae5be010382ff62',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £45.00, 1 x £45.00 / hour - Feb 26, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e550c1a4821d801033f8aaa',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'BJCT04T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e550d6bfae5be010382ff65',
  },
  {
    createdAt: '2020-02-25T12:06:49.989Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.626Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-26T13:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-02-26T17:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MEETING PO: CDC08786',
    seriesStart: '2020-02-26T13:30:00.000Z',
    seriesEnd: '2020-02-26T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e5f8fe57e0b96024db647ed',
          },
          _id: '5e57d60de6a44d0121f1dd2a',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £60.00, 4 x £15.00 / hour - Feb 26, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e550c1a4821d801033f8aaa',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'WBBMC4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e550dd931451e00dca14aca',
  },
  {
    createdAt: '2020-02-27T15:12:42.112Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.112Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-04T09:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-04T17:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary:
      'Procurement Day + Lunch for x10 (£100) Use of Rowan suite and Ankerwycke',
    seriesStart: '2020-03-04T09:00:00.000Z',
    seriesEnd: '2020-03-04T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
            _id: null,
          },
          _id: '5e592cecac9a6403281279f3',
          quantity: 1,
          price: 295,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 4, 2020',
          discountedPrice: 295,
        },
        credits: [],
        valueCredits: [],
        customPrice: 295,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e550c1a4821d801033f8aaa',
    member: '5e550c1aa5c8f600dbe3ac13',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '7Z5P94V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e57dc6a6799640164206c04',
  },
  {
    createdAt: '2020-02-28T11:09:05.268Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.634Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-04T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-03-04T14:00:00.000Z',
    },
    serviceSlots: {
      before: 15,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting room - Reflection Time/ Rosie Rowe',
    seriesStart: '2020-03-04T11:00:00.000Z',
    seriesEnd: '2020-03-04T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
            _id: null,
          },
          _id: '5e58f4d107381e0290ee7a29',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Mar 4, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e550c1a4821d801033f8aaa',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'WC1D04V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e58f4d107381e0290ee7a2e',
  },
  {
    createdAt: '2020-02-28T11:11:43.004Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.119Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-27T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-27T13:00:00.000Z',
    },
    serviceSlots: {
      before: 20,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting Room + Breakfast + Lunch catering',
    seriesStart: '2020-02-27T10:00:00.000Z',
    seriesEnd: '2020-02-27T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-27T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e60e71b9d2062004f73b007',
          },
          _id: '5e58f56ef85e1f032eb1cd3d',
          quantity: 1,
          price: 200,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Feb 27, 2020',
          discountedPrice: 200,
        },
        credits: [],
        valueCredits: [],
        customPrice: 200,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e4d274fa55d19008a182fe5',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '190774T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e58f56ff85e1f032eb1cd40',
  },
  {
    createdAt: '2020-03-03T11:33:41.440Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.641Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-05T14:00:00.000Z',
    },
    end: {
      dateTime: '2020-03-05T17:00:00.000Z',
    },
    serviceSlots: {
      before: 10,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Katy Fletcher/ Ravensburger Meeting',
    seriesStart: '2020-03-05T14:00:00.000Z',
    seriesEnd: '2020-03-05T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-05T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e6123dc3ae5220010e4d34c',
          },
          _id: '5e5e4095b687c4004e3e0397',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Mar 5, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e5e40446b84b30083e4276a',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'BHJKX4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e5e4095b687c4004e3e039d',
  },
  {
    createdAt: '2020-03-05T15:49:42.309Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.642Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-06T10:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-06T11:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MBA and partners',
    seriesStart: '2020-03-06T10:00:00.000Z',
    seriesEnd: '2020-03-06T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-06T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e819998f54cd70010db8bb3',
          },
          _id: '5e611fb05262860010f76abe',
          quantity: 1,
          price: 12,
          name: 'Ashbrittle, £15.00, 1 x £15.00 / hour - Mar 6, 2020',
          discountedPrice: 12,
        },
        credits: [],
        valueCredits: [],
        customPrice: 12,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5d441cf14f0f2c001022208f',
    member: '5d484480b77f6901bc0fdb82',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '524894V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e611f965262860010f76409',
  },
  {
    createdAt: '2020-03-09T14:30:43.404Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.113Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-12T14:00:00.000Z',
    },
    end: {
      dateTime: '2020-03-12T17:00:00.000Z',
    },
    serviceSlots: {
      before: 30,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Robert Jolley ECO',
    seriesStart: '2020-03-12T14:00:00.000Z',
    seriesEnd: '2020-03-12T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-12T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e771c42549e950010356d1b',
          },
          _id: '5e6653128b12a604a0d1db22',
          quantity: 3,
          price: 20,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Mar 12, 2020',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e550c1a4821d801033f8aaa',
    member: '5e550c1aa5c8f600dbe3ac13',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'LC3MB4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e6653138b12a604a0d1db2a',
  },
  {
    createdAt: '2020-03-10T11:26:23.857Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.641Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-12T08:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-12T12:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Robert Jolley and Dean Fischer Meeting x2',
    seriesStart: '2020-03-12T08:30:00.000Z',
    seriesEnd: '2020-03-12T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-12T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e771c42549e950010356d1b',
          },
          _id: '5e67d7b25e9062060a53142c',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £45.00, 1 x £45.00 / hour - Mar 12, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e550c1a4821d801033f8aaa',
    member: '5e550c1aa5c8f600dbe3ac13',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'X4B284V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e67795fb99be005d4b9e336',
  },
  {
    createdAt: '2020-03-11T09:55:01.504Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.631Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-13T10:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-13T12:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'A2D Meeting plus pastries for 6 (£3.00 per person)',
    seriesStart: '2020-03-13T10:30:00.000Z',
    seriesEnd: '2020-03-13T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-13T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e819998f54cd70010db8bb3',
          },
          _id: '5e6a1d29fd5d7401add0282b',
          quantity: 1,
          price: 39,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Mar 13, 2020',
          discountedPrice: 39,
        },
        credits: [],
        valueCredits: [],
        customPrice: 39,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5d441cf14f0f2c001022208f',
    member: '5d484480b77f6901bc0fdb82',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'WCC9D4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e68b5757f26dc0010418ef2',
  },
  {
    createdAt: '2020-03-12T11:23:36.652Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.140Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-13T10:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-13T12:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting Room',
    seriesStart: '2020-03-13T10:00:00.000Z',
    seriesEnd: '2020-03-13T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-13T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e843c7cd643b90010d274a3',
          },
          _id: '5e6a1bcbd39c6600d9f6c44a',
          quantity: 1,
          price: 32,
          name: 'Ankerwycke, £40.00, 2 x £20.00 / hour - Mar 13, 2020',
          discountedPrice: 32,
        },
        credits: [],
        valueCredits: [],
        customPrice: 32,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'HSRPP4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e6a1bb8d39c6600d9f6b036',
  },
  {
    createdAt: '2020-06-23T12:02:43.051Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.652Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-06-30T07:30:00.000Z',
    },
    end: {
      dateTime: '2020-06-30T15:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting Room',
    seriesStart: '2020-06-30T07:30:00.000Z',
    seriesEnd: '2020-06-30T15:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-06-30T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5efc3556d8ab8a001101d017',
          },
          _id: '5ef1ef63588105001216ce2f',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jun 30, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: ['5e2858a3e2a1a00267e9f2e6', '5e285a097d7c5d02160b853c'],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '889XZ4Z',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5ef1ef63588105001216ce32',
  },
  {
    createdAt: '2020-07-01T13:37:23.246Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.656Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-05T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-06T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Fido Weekly Meeting',
    seriesStart: '2020-07-05T23:00:00.000Z',
    seriesEnd: '2020-07-06T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-06T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5efc91935187fc0011950e61',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 6, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '2LFJG50',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5efc91935187fc0011950e64',
  },
  {
    createdAt: '2020-07-01T13:38:28.779Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.671Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-12T23:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-07-13T23:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Fido Weekly Meeting',
    seriesStart: '2020-07-12T23:00:00.000Z',
    seriesEnd: '2020-07-13T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-13T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5f0ec21b7b04f90011bde209',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 13, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '1X1Q950',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5efc91d402927900115b7bcf',
  },
  {
    createdAt: '2020-07-01T13:51:34.018Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.142Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-15T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-15T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Lemongrass Team Lunch',
    seriesStart: '2020-07-15T11:00:00.000Z',
    seriesEnd: '2020-07-15T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-15T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f2270be9d3a680011cfc950',
          },
          _id: '5efc94e5f24b790012c57bd5',
          quantity: 3,
          price: 20,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Jul 15, 2020',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'JK5S050',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5efc94e6f24b790012c57bdf',
  },
  {
    createdAt: '2020-07-09T11:49:56.899Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.668Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-08T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-09T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-07-08T23:00:00.000Z',
    seriesEnd: '2020-07-09T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-09T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5f0704646f7bab0011084b73',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 9, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e2858a3e2a1a00267e9f2e6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'RQKH250',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f0704646f7bab0011084b76',
  },
  {
    createdAt: '2020-07-15T08:45:50.528Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.631Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-13T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-14T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting room',
    seriesStart: '2020-07-13T23:00:00.000Z',
    seriesEnd: '2020-07-14T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-14T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5f0ec23e7b04f90011be0402',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 14, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'CC6BH50',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f0ec23e7b04f90011be0405',
  },
  {
    createdAt: '2020-07-15T09:16:42.121Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.635Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-31T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-01T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting room',
    seriesStart: '2020-08-31T23:00:00.000Z',
    seriesEnd: '2020-09-01T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-01T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b170',
          },
          _id: '5f0ec97a86f2500031e56797',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 1, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '74GX951',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f0ec97a86f2500031e5679a',
  },
  {
    createdAt: '2020-07-29T09:55:30.003Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.636Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-26T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-27T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-07-26T23:00:00.000Z',
    seriesEnd: '2020-07-27T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-27T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5f214791b0ba0700bb9d17b6',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 27, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '6M0VC50',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f214792b0ba0700bb9d17be',
  },
  {
    createdAt: '2020-07-29T09:55:46.187Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:56.643Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-07-27T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-07-28T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-07-27T23:00:00.000Z',
    seriesEnd: '2020-07-28T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-07-28T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f22a1e96ef5c50011341eea',
          },
          _id: '5f2147a293473700c94d6ca7',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jul 28, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'S40JX50',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f2147a293473700c94d6caa',
  },
  {
    createdAt: '2020-07-29T14:57:26.920Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.638Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-02T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-03T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-02T23:00:00.000Z',
    seriesEnd: '2020-08-03T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-03T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218e569fb7d90098462f1f',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 3, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '8P2BK51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218e569fb7d90098462f22',
  },
  {
    createdAt: '2020-07-29T14:57:50.836Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.632Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-03T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-04T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-03T23:00:00.000Z',
    seriesEnd: '2020-08-04T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218e6e60f2100096c6001f',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 4, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'WX8HL51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218e6e60f2100096c60022',
  },
  {
    createdAt: '2020-07-29T14:58:12.192Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.658Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-09T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-10T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-09T23:00:00.000Z',
    seriesEnd: '2020-08-10T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-10T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218e849fb7d900984647c0',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 10, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '9JDSP51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218e849fb7d900984647c3',
  },
  {
    createdAt: '2020-07-29T14:59:08.257Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.675Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-10T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-11T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-10T23:00:00.000Z',
    seriesEnd: '2020-08-11T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-11T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218ebc9fb7d90098466ce1',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 11, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'M1CD951',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218ebc9fb7d90098466ce4',
  },
  {
    createdAt: '2020-07-29T14:59:28.023Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.680Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-16T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-17T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-16T23:00:00.000Z',
    seriesEnd: '2020-08-17T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-17T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218ed09fb7d9009846745f',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 17, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '6CPV551',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218ed09fb7d90098467462',
  },
  {
    createdAt: '2020-07-29T14:59:45.071Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.671Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-17T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-18T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-17T23:00:00.000Z',
    seriesEnd: '2020-08-18T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-18T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218ee160f2100096c637dd',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 18, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'L00CB51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218ee160f2100096c637e0',
  },
  {
    createdAt: '2020-07-29T15:00:08.117Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.647Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-23T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-24T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-23T23:00:00.000Z',
    seriesEnd: '2020-08-24T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218ef89fb7d90098468037',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 24, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'WVNWP51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218ef89fb7d9009846803a',
  },
  {
    createdAt: '2020-07-29T15:00:32.879Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.658Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-24T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-25T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-08-24T23:00:00.000Z',
    seriesEnd: '2020-08-25T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477a03da32a80011f3efa3',
          },
          _id: '5f218f109fb7d900984687ac',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 25, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'NWTX151',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f218f109fb7d900984687af',
  },
  {
    createdAt: '2020-07-30T16:16:11.886Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.640Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-06T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-06T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Brand Solutions',
    seriesStart: '2020-08-06T09:00:00.000Z',
    seriesEnd: '2020-08-06T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-06T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f4b4f3a15e9b900115a2c76',
          },
          _id: '5f22f24bac58a4003f4ef952',
          quantity: 1,
          price: 24,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Aug 6, 2020',
          discountedPrice: 24,
        },
        credits: [],
        valueCredits: [],
        customPrice: 24,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5ee0f5665e130e0010d35aad',
    member: '5f22f06d5dcacc006cf11cef',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '0CVLP51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f22f24bac58a4003f4ef955',
  },
  {
    createdAt: '2020-08-03T16:02:56.983Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.134Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-06T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-07T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Lemongrass Team Contact Day',
    seriesStart: '2020-09-06T23:00:00.000Z',
    seriesEnd: '2020-09-07T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f72dc5f2f544f00114f830e',
          },
          _id: '5f283530cbf1200011a8267f',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Sep 7, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'LHPQ352',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f283530cbf1200011a82682',
  },
  {
    createdAt: '2020-08-11T08:35:56.992Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.145Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-13T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-13T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Tim Hall Meeting',
    seriesStart: '2020-08-13T08:00:00.000Z',
    seriesEnd: '2020-08-13T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2020-08-13T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f4366344a0d40001130c453',
          },
          _id: '5f32586c700b0900115bd118',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £80.00, 4 x £20.00 / hour - Aug 13, 2020',
          discountedPrice: 60,
        },
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'NJ4B051',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f32586c700b0900115bd11b',
  },
  {
    createdAt: '2020-08-19T15:08:05.447Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.171Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-20T07:30:00.000Z',
    },
    end: {
      dateTime: '2020-08-20T10:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Ravensburger Meeting',
    seriesStart: '2020-08-20T07:30:00.000Z',
    seriesEnd: '2020-08-20T10:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2020-08-20T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f4366344a0d40001130c453',
          },
          _id: '5f3d4055bedc710012f99189',
          quantity: 3,
          price: 20,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Aug 20, 2020',
          discountedPrice: 20,
        },
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '4ZJF151',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f3d4055bedc710012f9918c',
  },
  {
    createdAt: '2020-08-20T09:37:48.435Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.645Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-25T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-26T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Interviews',
    seriesStart: '2020-08-25T23:00:00.000Z',
    seriesEnd: '2020-08-26T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477b63a6d11300176b72a0',
          },
          _id: '5f3e446c02e1560119a5cde3',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Aug 26, 2020',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f3e434102e1560119a59398',
    member: '5f3e43ec02e1560119a5b194',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'P8GNC51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f3e446c02e1560119a5cdec',
  },
  {
    createdAt: '2020-08-24T08:14:04.838Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.150Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-24T12:30:00.000Z',
    },
    end: {
      dateTime: '2020-08-24T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monday Meeting',
    seriesStart: '2020-08-24T12:30:00.000Z',
    seriesEnd: '2020-08-24T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477b1169405a00116f4f81',
          },
          _id: '5f4376cc1f4ee400120586e5',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Aug 24, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'B1CW251',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f4376cc1f4ee400120586e8',
  },
  {
    createdAt: '2020-08-24T08:16:51.450Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.173Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-08-27T12:00:00.000Z',
    },
    end: {
      dateTime: '2020-08-27T16:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Thursday Meeting',
    seriesStart: '2020-08-27T12:00:00.000Z',
    seriesEnd: '2020-08-27T16:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-08-27T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f477b1169405a00116f4f81',
          },
          _id: '5f4377731f4ee400120605d5',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Aug 27, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'SF4GM51',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f4377731f4ee400120605d8',
  },
  {
    createdAt: '2020-08-25T16:13:54.668Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.148Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-02T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-03T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Lemongrass Meeting',
    seriesStart: '2020-09-02T23:00:00.000Z',
    seriesEnd: '2020-09-03T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-03T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f72dc5f2f544f00114f830e',
          },
          _id: '5f4538c2d0787a0011d277fa',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Sep 3, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'DL8X452',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f4538c2d0787a0011d277fd',
  },
  {
    createdAt: '2020-08-27T15:33:36.091Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.640Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-03T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-03T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-09-03T09:00:00.000Z',
    seriesEnd: '2020-09-03T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-03T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f5f5e3b4fc7010011eeb3d2',
          },
          _id: '5f47d25003de080011d667d7',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Sep 3, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'MVQ4252',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f47d25003de080011d667e0',
  },
  {
    createdAt: '2020-08-27T17:21:42.958Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.633Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-06T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-07T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monday Meeting',
    seriesStart: '2020-09-06T23:00:00.000Z',
    seriesEnd: '2020-09-07T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b170',
          },
          _id: '5f47eba69f448b001178bd3b',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 7, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'NG51P52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f47eba69f448b001178bd3e',
  },
  {
    createdAt: '2020-08-27T17:22:55.739Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.663Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-07T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-08T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Tuesday Meeting',
    seriesStart: '2020-09-07T23:00:00.000Z',
    seriesEnd: '2020-09-08T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-08T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b170',
          },
          _id: '5f47ebef0e22b000117b9b7a',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 8, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'BSVNT52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f47ebef0e22b000117b9b7d',
  },
  {
    createdAt: '2020-09-03T11:46:52.500Z',
    createdBy: '5d48391fc9cec601ff46ba46',
    modifiedAt: '2021-01-09T07:38:56.654Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-30T09:30:00.000Z',
    },
    end: {
      dateTime: '2020-09-30T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-09-30T09:30:00.000Z',
    seriesEnd: '2020-09-30T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-30T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b171',
          },
          _id: '5f50d7ac9336ae0011e9ae23',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Sep 30, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '004W052',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f50d7ac9336ae0011e9ae26',
  },
  {
    createdAt: '2020-09-08T08:28:30.941Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.132Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-08T14:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-08T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Berni Meeting 3 - 5pm',
    seriesStart: '2020-09-08T14:00:00.000Z',
    seriesEnd: '2020-09-08T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-08T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f72dc5f2f544f00114f830e',
          },
          _id: '5f5740ae24037e021095ef85',
          quantity: 1,
          price: 36,
          name: 'Ankerwycke, £40.00, 2 x £20.00 / hour - Sep 8, 2020',
          discountedPrice: 36,
        },
        credits: [],
        valueCredits: [],
        customPrice: 36,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '68K8Z52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f5740ae24037e021095ef88',
  },
  {
    createdAt: '2020-09-11T15:52:18.838Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.114Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-14T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-14T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MGES meeting',
    seriesStart: '2020-09-14T09:00:00.000Z',
    seriesEnd: '2020-09-14T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-14T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f75abc830af300011132045',
          },
          _id: '5f5b9d322163b8020f21f1b3',
          quantity: 1,
          price: 48,
          name: 'Ankerwycke, £80.00, 4 x £20.00 / hour - Sep 14, 2020',
          discountedPrice: 48,
        },
        credits: [],
        valueCredits: [],
        customPrice: 48,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e57d8e6e6a44d0121f3b035',
    member: '5e57d98367996401641e87b1',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'Z8N1Q52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f5b9d322163b8020f21f1b6',
  },
  {
    createdAt: '2020-09-14T08:15:56.948Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.673Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-14T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-15T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Fido Meeting',
    seriesStart: '2020-09-14T23:00:00.000Z',
    seriesEnd: '2020-09-15T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-15T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b170',
          },
          _id: '5f5f26bc4975f401adae9ba1',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 15, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'S68DD52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f5f26bc4975f401adae9bb0',
  },
  {
    createdAt: '2020-09-15T10:11:19.490Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.133Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-15T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-16T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Lemongrass meeting',
    seriesStart: '2020-09-15T23:00:00.000Z',
    seriesEnd: '2020-09-16T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-16T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f72dc5f2f544f00114f830e',
          },
          _id: '5f60934758665500129eac6f',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Sep 16, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'LK5JG52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f60934758665500129eac72',
  },
  {
    createdAt: '2020-09-17T10:37:16.920Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.666Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-07T09:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-10-07T13:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-10-07T09:00:00.000Z',
    seriesEnd: '2020-10-07T13:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f8025728799bc0011a7040e',
          },
          _id: '5f633ca45d9d520011f91779',
          quantity: 1,
          price: 45,
          name:
            'Ashbrittle, £60.00, 1 x £45.00 / hour, 1 x £15.00 / hour - Oct 7, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'KD5W153',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f633c5c5d9d520011f8fd5d',
  },
  {
    createdAt: '2020-09-17T10:38:09.199Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.144Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-07T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-07T13:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-10-07T09:00:00.000Z',
    seriesEnd: '2020-10-07T13:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f8025728799bc0011a7040e',
          },
          _id: '5f633c915d9d520011f914be',
          quantity: 1,
          price: 45,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Oct 7, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '8WJ0W53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f633c915d9d520011f914c1',
  },
  {
    createdAt: '2020-09-21T14:56:02.222Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.665Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-15T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-15T10:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation Session',
    seriesStart: '2020-10-15T08:00:00.000Z',
    seriesEnd: '2020-10-15T10:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-15T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9034f81a6f9a00113977cc',
          },
          _id: '5f68bf0204189b0011bfd64b',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Oct 15, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'D6CCX53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f68bf0204189b0011bfd64e',
  },
  {
    createdAt: '2020-09-22T12:48:46.167Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.663Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-22T13:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-22T15:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Interview',
    seriesStart: '2020-09-22T13:00:00.000Z',
    seriesEnd: '2020-09-22T15:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-22T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f757ff754a132001101b170',
          },
          _id: '5f69f2ae3bf867015a2ec406',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Sep 22, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'ZW24Q52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f69f2ae3bf867015a2ec409',
  },
  {
    createdAt: '2020-09-22T16:30:20.577Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.165Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-22T09:30:00.000Z',
    },
    end: {
      dateTime: '2020-09-22T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting Room',
    seriesStart: '2020-09-22T09:30:00.000Z',
    seriesEnd: '2020-09-22T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-22T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f7311873c0c200011d51c8d',
          },
          _id: '5f6a269c41d05a01376f9529',
          quantity: 1,
          price: 48,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Sep 22, 2020',
          discountedPrice: 48,
        },
        credits: [],
        valueCredits: [],
        customPrice: 48,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f6a0bae65da3200ef186954',
    member: '5f6a0c2e6b3a38011fc87062',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '1521Q52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6a269c41d05a01376f9532',
  },
  {
    createdAt: '2020-09-22T16:35:06.752Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.638Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-22T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-23T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting plus lunch',
    seriesStart: '2020-09-22T23:00:00.000Z',
    seriesEnd: '2020-09-23T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-23T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f72dc5f2f544f00114f8311',
          },
          _id: '5f6a27ba3bf867015a3d6b9b',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 23, 2020',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f3e434102e1560119a59398',
    member: '5f3e43ec02e1560119a5b194',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '596TQ52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6a27ba3bf867015a3d6b9e',
  },
  {
    createdAt: '2020-09-23T08:09:12.996Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.647Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-25T12:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-25T16:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Catch up',
    seriesStart: '2020-09-25T12:00:00.000Z',
    seriesEnd: '2020-09-25T16:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f75abc830af300011132047',
          },
          _id: '5f6b02a83bf867015a5f18ff',
          quantity: 1,
          price: 45,
          name:
            'Ashbrittle, £60.00, 1 x £45.00 / hour, 1 x £15.00 / hour - Sep 25, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f6b01c33bf867015a5ea781',
    member: '5f6b02533bf867015a5eed7c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '3JQ3K52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6b02a83bf867015a5f1991',
  },
  {
    createdAt: '2020-09-23T08:14:02.645Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.652Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-14T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-14T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Half Day Meeting',
    seriesStart: '2020-10-14T08:00:00.000Z',
    seriesEnd: '2020-10-14T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-14T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9034f81a6f9a00113977cb',
          },
          _id: '5f6b03ca65da3200ef4762e1',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £45.00, 1 x £45.00 / hour - Oct 14, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f3e434102e1560119a59398',
    member: '5f3e43ec02e1560119a5b194',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '2DWD753',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6b03ca65da3200ef4762e4',
  },
  {
    createdAt: '2020-10-01T09:20:24.187Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.655Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-27T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-28T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-09-27T23:00:00.000Z',
    seriesEnd: '2020-09-28T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-28T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f75ac215ca6a5001145cc57',
          },
          _id: '5f759f58b579230210636051',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 28, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '5HLVB52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f759f58b579230210636054',
  },
  {
    createdAt: '2020-10-01T09:20:56.664Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.668Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-20T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-21T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-09-20T23:00:00.000Z',
    seriesEnd: '2020-09-21T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-21T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f75ac215ca6a5001145cc57',
          },
          _id: '5f759f78b579230210636daf',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 21, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'KNL7P52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f759f78b579230210636db2',
  },
  {
    createdAt: '2020-10-01T09:22:53.354Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.676Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-13T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-14T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-09-13T23:00:00.000Z',
    seriesEnd: '2020-09-14T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-09-14T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f75ac215ca6a5001145cc57',
          },
          _id: '5f759fedef0e000011b2a2fb',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Sep 14, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'LP77Z52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f759fedef0e000011b2a2fe',
  },
  {
    createdAt: '2020-10-01T09:25:22.569Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.679Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-04T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-05T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-10-04T23:00:00.000Z',
    seriesEnd: '2020-10-05T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-05T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9e6cf8d5d45200113268a6',
          },
          _id: '5f75a082ef0e000011b2ea99',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 5, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'PBR7J53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f75a082ef0e000011b2ea9c',
  },
  {
    createdAt: '2020-10-01T09:26:23.344Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.673Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-11T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-12T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-10-11T23:00:00.000Z',
    seriesEnd: '2020-10-12T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-12T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9e6cf8d5d45200113268a6',
          },
          _id: '5f75a0bfef0e000011b30b77',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 12, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'QSGSP53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f75a0bfef0e000011b30b7a',
  },
  {
    createdAt: '2020-10-01T09:26:45.174Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.649Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-18T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-19T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-10-18T23:00:00.000Z',
    seriesEnd: '2020-10-19T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-19T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9e6cf8d5d45200113268a6',
          },
          _id: '5f75a0d5ef0e000011b30eb2',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 19, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'T0CWH53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f75a0d5ef0e000011b30eb5',
  },
  {
    createdAt: '2020-10-01T09:27:05.505Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.668Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-26T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-27T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-10-26T00:00:00.000Z',
    seriesEnd: '2020-10-27T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9e6cf8d5d45200113268a6',
          },
          _id: '5f75a0e9ef0e000011b3166b',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 26, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'GD78H53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f75a0e9ef0e000011b3166e',
  },
  {
    createdAt: '2020-10-07T09:49:16.642Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.142Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-14T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-14T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Team Meeting x4',
    seriesStart: '2020-10-14T08:00:00.000Z',
    seriesEnd: '2020-10-14T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-14T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9034f81a6f9a00113977c9',
          },
          _id: '5f7d8f1c85ac54001186dbb8',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Oct 14, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e550c1a4821d801033f8aaa',
    member: '5f7d8eb227f9b400116b13ab',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'G69D653',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f7d8f1c85ac54001186dbbb',
  },
  {
    createdAt: '2020-10-07T13:32:24.299Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.160Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-04T10:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-11-04T13:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Double Mediation',
    seriesStart: '2020-11-04T10:00:00.000Z',
    seriesEnd: '2020-11-04T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fa93a74be5999762c2356c7',
          },
          _id: '5f9fed2d0605b1017ca46922',
          quantity: 1,
          price: 45,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Nov 4, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '89NNX54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f7dc36885ac54001197050a',
  },
  {
    createdAt: '2020-10-07T13:32:48.317Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.649Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-04T10:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-11-04T13:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Double Mediation',
    seriesStart: '2020-11-04T10:00:00.000Z',
    seriesEnd: '2020-11-04T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fa93a74be5999762c2356c7',
          },
          _id: '5f9fed3b2286c401b5dde462',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Nov 4, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'GPFLV54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f7dc38027f9b400117b13e3',
  },
  {
    createdAt: '2020-10-08T15:12:14.698Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.152Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-18T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-19T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Staff Induction',
    seriesStart: '2020-10-18T23:00:00.000Z',
    seriesEnd: '2020-10-19T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-19T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9fe6b4c66a9500127b2e62',
          },
          _id: '5f7f2c4e583a3d00119b9e00',
          quantity: 1,
          price: 130,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Oct 19, 2020',
          discountedPrice: 130,
        },
        credits: [],
        valueCredits: [],
        customPrice: 130,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'VC75F53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f7f2c4e583a3d00119b9e09',
  },
  {
    createdAt: '2020-10-13T08:05:04.082Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.644Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-13T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-13T16:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Training session in Sequoia',
    seriesStart: '2020-10-13T09:00:00.000Z',
    seriesEnd: '2020-10-13T16:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-13T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9d1aa45bc46200114220a9',
          },
          _id: '5f855fb047119a0011c0a87f',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 13, 2020',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5dc5632f41e03f036a70a841',
    member: '5dc5636ed9b2fd02ae89f2e9',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '4G3CX53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f855fb047119a0011c0a882',
  },
  {
    createdAt: '2020-10-20T11:19:06.708Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.658Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-19T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-20T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'FT Meeting',
    seriesStart: '2020-10-19T23:00:00.000Z',
    seriesEnd: '2020-10-20T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-20T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5f9e6cf8d5d45200113268a6',
          },
          _id: '5f8ec7aac72b4d0011549faa',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Oct 20, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'KRP1F53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f8ec7aac72b4d0011549fad',
  },
  {
    createdAt: '2020-10-27T14:24:29.229Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.162Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-28T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-28T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Steve and Tony Meeting',
    seriesStart: '2020-10-28T11:00:00.000Z',
    seriesEnd: '2020-10-28T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-10-28T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5faa4a8aba50f43b01835798',
          },
          _id: '5f982d9d1067e30011c33776',
          quantity: 1,
          price: 36,
          name: 'Ankerwycke, £40.00, 2 x £20.00 / hour - Oct 28, 2020',
          discountedPrice: 36,
        },
        credits: [],
        valueCredits: [],
        customPrice: 36,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f6a0bae65da3200ef186954',
    member: '5f6a0c2e6b3a38011fc87062',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'WCJHJ53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f982d9d1067e30011c3377b',
  },
  {
    createdAt: '2020-11-02T13:33:47.605Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.166Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-26T00:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-11-27T00:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Meeting',
    seriesStart: '2020-11-26T00:00:00.000Z',
    seriesEnd: '2020-11-27T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fc5000fea2a14078f8a0c78',
          },
          _id: '5fa9550a8dd1955f2d007fde',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Nov 26, 2020',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'WRTSF54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa00abb4f4b5a0011708587',
  },
  {
    createdAt: '2020-11-02T13:55:52.532Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.159Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-04-25T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-04-26T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-04-25T23:00:00.000Z',
    seriesEnd: '2021-04-26T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-04-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa00fe84f4b5a001172dc76',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Apr 26, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '3PZMK59',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa00fe84f4b5a001172dc7b',
  },
  {
    createdAt: '2020-11-02T13:56:34.197Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.168Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-05-23T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-05-24T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-05-23T23:00:00.000Z',
    seriesEnd: '2021-05-24T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-05-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa010124f4b5a001172e062',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - May 24, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '527CD5B',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa010124f4b5a001172e067',
  },
  {
    createdAt: '2020-11-02T13:57:15.725Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.139Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-06-27T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-06-28T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-06-27T23:00:00.000Z',
    seriesEnd: '2021-06-28T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-06-28T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa0103b4f4b5a001172edb6',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Jun 28, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'VL1P05C',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa0103b4f4b5a001172edbb',
  },
  {
    createdAt: '2020-11-02T13:57:50.555Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.173Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-07-25T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-07-26T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-07-25T23:00:00.000Z',
    seriesEnd: '2021-07-26T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-07-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa0105e5eaa1400115c2172',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Jul 26, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'RD1B45D',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa0105e5eaa1400115c2177',
  },
  {
    createdAt: '2020-11-02T13:58:24.848Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.148Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-08-22T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-08-23T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-08-22T23:00:00.000Z',
    seriesEnd: '2021-08-23T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-08-23T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa010804f4b5a00117307ec',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Aug 23, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'CVWX75F',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa010804f4b5a00117307f1',
  },
  {
    createdAt: '2020-11-02T13:58:53.233Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.155Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-09-26T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-09-27T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-09-26T23:00:00.000Z',
    seriesEnd: '2021-09-27T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-09-27T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa0109d4f4b5a0011730ac9',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Sep 27, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'CTTKX5G',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa0109d4f4b5a0011730ad3',
  },
  {
    createdAt: '2020-11-02T13:59:30.120Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.169Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-10-24T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-10-25T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2021-10-24T23:00:00.000Z',
    seriesEnd: '2021-10-25T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-10-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa010c24f4b5a0011730f70',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Oct 25, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '71H9K5H',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa010c24f4b5a0011730f75',
  },
  {
    createdAt: '2020-11-02T14:00:36.528Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.154Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-11-29T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-11-30T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-11-29T00:00:00.000Z',
    seriesEnd: '2021-11-30T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-11-29T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '5fa011044f4b5a00117339fd',
          quantity: 1,
          price: 90,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Nov 29, 2021',
          discountedPrice: 90,
        },
        credits: [],
        valueCredits: [],
        customPrice: 90,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'SN1T55J',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa011044f4b5a0011733a02',
  },
  {
    createdAt: '2020-11-05T11:13:14.176Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.174Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-25T13:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-25T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-11-25T13:00:00.000Z',
    seriesEnd: '2020-11-25T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fc5000fea2a1472c58a0c76',
          },
          _id: '5fa3de4a476deb3a39f8da9c',
          quantity: 1,
          price: 45,
          name: 'Ankerwycke, £60.00, 3 x £20.00 / hour - Nov 25, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'K93CS54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa3de4a476debbf79f8daa9',
  },
  {
    createdAt: '2020-11-05T11:20:27.370Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.650Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-25T13:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-25T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-11-25T13:00:00.000Z',
    seriesEnd: '2020-11-25T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fc5000fea2a1472c58a0c76',
          },
          _id: '5fa3dffb4098666e7352f0f0',
          quantity: 3,
          price: 15,
          name: 'Ashbrittle, £45.00, 3 x £15.00 / hour - Nov 25, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'KZ2WQ54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa3dffb409866657d52f0f5',
  },
  {
    createdAt: '2020-11-06T16:06:35.439Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.671Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-17T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-17T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: '0915 - 1115 Meeting',
    seriesStart: '2020-11-17T09:00:00.000Z',
    seriesEnd: '2020-11-17T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-17T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fb7da931a75b05c099810ed',
          },
          _id: '5fa5748bb4382b192f88c106',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Nov 17, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '7C8PS54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa5748bb4382b09bd88c10b',
  },
  {
    createdAt: '2020-11-09T13:32:48.206Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.136Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-16T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-16T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: "121's",
    seriesStart: '2020-11-16T09:00:00.000Z',
    seriesEnd: '2020-11-16T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-16T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fb3cbe424f712bce3d34f12',
          },
          _id: '5fa94500d983db1f63237fd1',
          quantity: 1,
          price: 60,
          name:
            'Ankerwycke, £80.00, 1 x £60.00 / half day, 1 x £20.00 / hour - Nov 16, 2020',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'NH9D754',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa94500d983db1d67237fd6',
  },
  {
    createdAt: '2020-11-19T12:55:14.956Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.658Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-16T12:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-16T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Threadgold',
    seriesStart: '2020-12-16T12:00:00.000Z',
    seriesEnd: '2020-12-16T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-16T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a3565fa3dee',
          },
          _id: '5fb66b32421f99179e474b8e',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Dec 16, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'JBS6B55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fb66b32421f991c70474b93',
  },
  {
    createdAt: '2020-11-20T08:23:01.595Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.675Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-24T09:30:00.000Z',
    },
    end: {
      dateTime: '2020-11-24T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Alexander & Co Meeting',
    seriesStart: '2020-11-24T09:30:00.000Z',
    seriesEnd: '2020-11-24T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-11-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fc35674959a3dd8a6fd5eb4',
          },
          _id: '5fb77ce590bbd92fc99f2a10',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Nov 24, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f3e434102e1560119a59398',
    member: '5f3e43ec02e1560119a5b194',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'W4KL554',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fb77ce590bbd96a339f2a16',
  },
  {
    createdAt: '2020-11-23T10:31:23.366Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2020-11-23T10:31:23.366Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-05T08:30:00.000Z',
    },
    end: {
      dateTime: '2020-12-05T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Photoshoot',
    seriesStart: '2020-12-05T08:30:00.000Z',
    seriesEnd: '2020-12-05T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-05T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fd9c025ea31314647adf671',
          },
          _id: '5fbb8f7a99f3adf91fed877d',
          quantity: 1,
          price: 62.5,
          name: 'Sequoia, £100.00, 1 x £100.00 / day - Dec 5, 2020',
          discountedPrice: 62.5,
        },
        credits: [],
        valueCredits: [],
        customPrice: 62.5,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-10-14T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5f86c78b08339d001110ff71',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Sequoia',
      number: 88,
      size: 10,
      rate: '5f86c6b408339d001110a280',
      description:
        'Additional meeting room\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#e51c1c',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-10-14T09:40:27.644Z',
      modifiedAt: '2020-10-14T09:41:14.192Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
    },
    team: '5fb51ffe027a685431d3f55f',
    member: '5fb5201c027a6837fed3f7db',
    visitors: [],
    members: [],
    plan: '5f86c6b408339d001110a280',
    accountedUntil: null,
    accounted: true,
    reference: '1P3ZV55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fbb8f7b99f3adab04ed89b6',
  },
  {
    createdAt: '2020-11-26T10:11:51.901Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.678Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-15T10:30:00.000Z',
    },
    end: {
      dateTime: '2020-12-15T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-12-15T10:30:00.000Z',
    seriesEnd: '2020-12-15T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-15T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a3565fa3dee',
          },
          _id: '5fbf7f67f2d1384e3ca4d813',
          quantity: 2,
          price: 15,
          name: 'Ashbrittle, £30.00, 2 x £15.00 / hour - Dec 15, 2020',
          discountedPrice: 15,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '2VW2V55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fbf7f67f2d1385e46a4d821',
  },
  {
    createdAt: '2020-12-01T10:19:36.669Z',
    createdBy: '5fbba444caf0e7c791543007',
    modifiedAt: '2020-12-01T10:19:36.669Z',
    modifiedBy: '5fbba444caf0e7c791543007',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-15T10:30:00.000Z',
    },
    end: {
      dateTime: '2020-12-15T12:30:00.000Z',
    },
    serviceSlots: {
      before: null,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Arman sitting Exam',
    seriesStart: '2020-12-15T10:30:00.000Z',
    seriesEnd: '2020-12-15T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-11-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5e5d245d2a554401bf42237f',
      price: 99,
      deposit: 0,
      parents: [],
      type: 'hotdesk',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd301e5c8100104fdcef',
      name: 'Hotdesk 1',
      number: 64,
      parent: null,
      rate: '5e4d2ea0954765029d7f2009',
      color: '#ff0861',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-03-02T15:21:01.343Z',
      modifiedAt: '2021-03-01T15:08:55.864Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedBy: '5d43461d4032200094acdd98',
      target: '5d5a9f8edf86d60010483446',
      targetPlan: '5f90436d389b6500617e5e71',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5fbba441bd4a6c2d88511b5b',
    visitors: [],
    members: [],
    plan: '5e4d2ea0954765029d7f2009',
    accountedUntil: null,
    tentative: true,
    accounted: true,
    reference: 'VHW7455',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fc618b8d29e6f7c5eb3feb0',
  },
  {
    createdAt: '2020-12-07T11:07:25.834Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.145Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-07T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-08T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mirjam and Mark',
    seriesStart: '2020-12-07T00:00:00.000Z',
    seriesEnd: '2020-12-08T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a77d0fa3ded',
          },
          _id: '5fce0cedb6b8d7841d77438a',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Dec 7, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'SKF5J55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fce0cedb6b8d7678977438f',
  },
  {
    createdAt: '2020-12-07T11:08:04.938Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.143Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-08T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-09T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-12-08T00:00:00.000Z',
    seriesEnd: '2020-12-09T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-08T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a77d0fa3ded',
          },
          _id: '5fce0d14b6b8d71209774ec9',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Dec 8, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'XTNHG55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fce0d14b6b8d74eee774ece',
  },
  {
    createdAt: '2020-12-07T11:08:40.901Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.135Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-09T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-10T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-12-09T00:00:00.000Z',
    seriesEnd: '2020-12-10T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-09T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a77d0fa3ded',
          },
          _id: '5fce0d38b85d324461762757',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Dec 9, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'VCM5Q55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fce0d38b85d32e78a76275c',
  },
  {
    createdAt: '2020-12-07T11:08:59.001Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.163Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-10T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-11T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-12-10T00:00:00.000Z',
    seriesEnd: '2020-12-11T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-10T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a77d0fa3ded',
          },
          _id: '5fce0d4ab85d323e6e763e45',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Dec 10, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'MKGCR55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fce0d4bb85d323de4763e4a',
  },
  {
    createdAt: '2020-12-07T11:09:27.016Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.165Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-11T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-12T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-12-11T00:00:00.000Z',
    seriesEnd: '2020-12-12T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-11T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a77d0fa3ded',
          },
          _id: '5fce0d66b6b8d78553779a1b',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Dec 11, 2020',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'NVBT955',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fce0d67b6b8d78541779a20',
  },
  {
    createdAt: '2020-12-11T10:49:03.626Z',
    createdBy: '5fbba444caf0e7c791543007',
    modifiedAt: '2021-01-09T07:38:56.676Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-11T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-11T15:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Actually in Ankerwycke but couldn’t book',
    seriesStart: '2020-12-11T11:00:00.000Z',
    seriesEnd: '2020-12-11T15:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2020-12-11T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5feed79d817005d0b81bed86',
          },
          _id: '5fd34eb15c3ecc8025630f02',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £45.00, 1 x £45.00 / hour - Dec 11, 2020',
          discountedPrice: 45,
        },
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f0c75c7340ccd00117252fd',
    member: null,
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '1RDHN55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fd34e9f5c3eccb85263091c',
  },
  {
    createdAt: '2020-12-15T14:20:44.829Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.150Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-16T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-16T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Confidential meeting',
    seriesStart: '2020-12-16T10:00:00.000Z',
    seriesEnd: '2020-12-16T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-16T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5fda1f85bdd67a29bbfa3df1',
          },
          _id: '5fd8c63cf19d4b37dcb980de',
          quantity: 2,
          price: 20,
          name: 'Ankerwycke, £40.00, 2 x £20.00 / hour - Dec 16, 2020',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5fd796b80730c72edb335f59',
    member: '5fd796b89a0014439107ab6b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'HS0R455',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fd8c63cf19d4b79f7b980e3',
  },
  {
    createdAt: '2020-12-17T12:35:42.938Z',
    createdBy: '5f462b1c9279c700115a7ada',
    modifiedAt: '2020-12-17T13:21:26.675Z',
    modifiedBy: '5d43461d4032200094acdd98',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-19T09:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-19T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'NSPCC Workshop- Week 1',
    seriesStart: '2021-01-19T09:00:00.000Z',
    seriesEnd: '2021-01-19T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2021-01-19T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
            _id: null,
          },
          _id: '5fdb52dc1650dd3cdd83167f',
          quantity: 1,
          price: 80,
          name: 'Event Space, £100.00, 1 x £100.00 / half day - Jan 19, 2021',
          discountedPrice: 80,
        },
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: true,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-05-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5e60f65da8b47100a177bd26',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'event_space',
      office: '5e60f4380d715200cb209354',
      room: '5e60f575a8b47100a1775fa3',
      name: 'Event Space',
      number: 82,
      size: 44,
      color: '#fff200',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-03-05T12:53:49.810Z',
      modifiedAt: '2020-12-17T12:09:53.563Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
      rate: '5fdb4866503e258c766061dc',
    },
    team: '5f884c33e0be0b00148ab549',
    member: '5fdb4621f19d4b636a40ce5b',
    visitors: [],
    members: [],
    plan: '5fdb4866503e258c766061dc',
    accountedUntil: null,
    accounted: true,
    reference: '1RF3C56',
    office: {
      createdAt: '2020-03-05T12:44:40.388Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedAt: '2021-03-22T15:38:23.266Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'The Track',
      slug: 'the-track',
      code: 'BR01',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-track-1616427313403.jpeg',
      description:
        'The_Track is the creative and digital hub in Bognor Regis in West Sussex. Its stunning design, high ceilings and superb facilities create a landmark coworking space designed to inspire and innovate. With a range of desks, project rooms and a spacious events and exhibition space, as well as super-fast wifi connectivity, Bognor Regis has never seen anything like it before. Now, for the first time, local entrepreneurs, startups and small and growing businesses have a space to call their own, where they can create, collaborate and build an enterprise community.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      public: true,
      zip: 'PO21 1QF',
      city: 'Bognor Regis',
      address: '26 Station Road',
      _id: '5e60f4380d715200cb209354',
    },
    _id: '5fdb509eec355a49c8795815',
  },
  {
    createdAt: '2020-12-17T13:24:03.354Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2020-12-17T13:24:03.354Z',
    modifiedBy: '5d43461d4032200094acdd98',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-26T09:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-26T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2021-01-26T09:00:00.000Z',
    seriesEnd: '2021-01-26T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-01-26T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
            _id: null,
          },
          _id: '5fdb5bf3d1f83d11615da6f2',
          quantity: 1,
          price: 80,
          name: 'Event Space, £100.00, 1 x £100.00 / half day - Jan 26, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: true,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-05-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5e60f65da8b47100a177bd26',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'event_space',
      office: '5e60f4380d715200cb209354',
      room: '5e60f575a8b47100a1775fa3',
      name: 'Event Space',
      number: 82,
      size: 44,
      color: '#fff200',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-03-05T12:53:49.810Z',
      modifiedAt: '2020-12-17T12:09:53.563Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
      rate: '5fdb4866503e258c766061dc',
    },
    team: '5f884c33e0be0b00148ab549',
    member: '5fdb4621f19d4b636a40ce5b',
    visitors: [],
    members: [],
    plan: '5fdb4866503e258c766061dc',
    accountedUntil: null,
    accounted: true,
    reference: '258DV56',
    office: {
      createdAt: '2020-03-05T12:44:40.388Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedAt: '2021-03-22T15:38:23.266Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'The Track',
      slug: 'the-track',
      code: 'BR01',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-track-1616427313403.jpeg',
      description:
        'The_Track is the creative and digital hub in Bognor Regis in West Sussex. Its stunning design, high ceilings and superb facilities create a landmark coworking space designed to inspire and innovate. With a range of desks, project rooms and a spacious events and exhibition space, as well as super-fast wifi connectivity, Bognor Regis has never seen anything like it before. Now, for the first time, local entrepreneurs, startups and small and growing businesses have a space to call their own, where they can create, collaborate and build an enterprise community.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      public: true,
      zip: 'PO21 1QF',
      city: 'Bognor Regis',
      address: '26 Station Road',
      _id: '5e60f4380d715200cb209354',
    },
    _id: '5fdb5bf3d1f83d8a7f5da6f7',
  },
  {
    createdAt: '2021-01-03T16:54:58.644Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-03T16:54:58.644Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-11T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-11T15:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Peter and Michael Meeting In Ankerwycke',
    seriesStart: '2020-12-11T11:00:00.000Z',
    seriesEnd: '2020-12-11T15:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-12-11T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5ff327bc8a296d05960630cf',
          },
          _id: '5ff1f6e2b4c31f1b4133abdd',
          quantity: 1,
          price: 45,
          name: 'Sequoia, £80.00, 4 x £20.00 / hour - Dec 11, 2020',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-10-14T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5f86c78b08339d001110ff71',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Sequoia',
      number: 88,
      size: 10,
      rate: '5f86c6b408339d001110a280',
      description:
        'Additional meeting room\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#e51c1c',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-10-14T09:40:27.644Z',
      modifiedAt: '2020-10-14T09:41:14.192Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
    },
    team: '5f904453ada51d0011a57fcc',
    member: '5f904483c5a44a0011867795',
    visitors: [],
    members: [],
    plan: '5f86c6b408339d001110a280',
    accountedUntil: null,
    accounted: true,
    reference: 'KJ3V055',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5ff1f6e2b4c31f6aba33abe2',
  },
  {
    createdAt: '2021-01-06T14:03:03.916Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.637Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-07T13:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-07T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Team Member Induction',
    seriesStart: '2021-01-07T13:00:00.000Z',
    seriesEnd: '2021-01-07T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-01-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5ffec00f18db4f21f8553e3f',
          },
          _id: '5ff5c317cec68736df506a16',
          quantity: 1,
          price: 12,
          name: 'Ashbrittle, £15.00, 1 x £15.00 / hour - Jan 7, 2021',
          discountedPrice: 12,
        },
        credits: [],
        valueCredits: [],
        customPrice: 12,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5ff33286133aadda40bacf3b',
    member: '5ff332864795a621bef4a7da',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'X16MD56',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5ff5c317cec6879324506a25',
  },
  {
    createdAt: '2021-01-11T10:18:54.094Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-11T10:18:54.094Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-04T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-05T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day',
    seriesStart: '2021-01-04T00:00:00.000Z',
    seriesEnd: '2021-01-05T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-01-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6017b6a890e2b05f6669199f',
          },
          _id: '5ffc260e8c13dc6c64cf0db8',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Jan 4, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'BJWK156',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5ffc260e8c13dc043ccf0dbd',
  },
  {
    createdAt: '2021-02-05T10:08:30.540Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-05T10:08:30.540Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-09T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-10T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'New Starter Meeting: Training',
    seriesStart: '2021-02-09T00:00:00.000Z',
    seriesEnd: '2021-02-10T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-09T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '602d7d860fdf797168386642',
          },
          _id: '601d191e84698090eac88ab0',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Feb 9, 2021',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'V4M7J57',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '601d191e84698077f3c88ab5',
  },
  {
    createdAt: '2021-02-11T12:17:16.820Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-11T12:17:16.820Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-17T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-18T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-02-17T00:00:00.000Z',
    seriesEnd: '2021-02-18T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-17T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '602d7d860fdf797168386642',
          },
          _id: '6025204c2389da619f252bcc',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Feb 17, 2021',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '976V657',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6025204c2389da1c67252bd1',
  },
  {
    createdAt: '2021-02-18T13:00:18.720Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-18T13:00:18.720Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-24T09:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-24T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Half Day Meeting',
    seriesStart: '2021-02-24T09:00:00.000Z',
    seriesEnd: '2021-02-24T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6039fdd23ea86b7db54105a2',
          },
          _id: '602e64e2680fb3a3b75838e2',
          quantity: 1,
          price: 45,
          name: 'Ashbrittle, £45.00, 1 x £45.00 / hour - Feb 24, 2021',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f3e434102e1560119a59398',
    member: '5f3e43ec02e1560119a5b194',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'R1MTX57',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '602e64e2680fb381d15838e7',
  },
  {
    createdAt: '2021-02-18T13:01:25.227Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-18T13:01:25.227Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-24T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-25T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Meeting',
    seriesStart: '2021-02-24T00:00:00.000Z',
    seriesEnd: '2021-02-25T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6039fdd23ea86b32bb4105a4',
          },
          _id: '602e652574cd0b12252c26d9',
          quantity: 1,
          price: 75,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Feb 24, 2021',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
        customPrice: 75,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'X3H1457',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '602e652574cd0b81562c26de',
  },
  {
    createdAt: '2021-02-23T10:45:30.635Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-23T10:45:30.635Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-25T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-26T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'NHS Event 1000 - 1600',
    seriesStart: '2021-02-25T00:00:00.000Z',
    seriesEnd: '2021-02-26T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '603b4f0149d4efaf6eed60f2',
          },
          _id: '6034dccad54589baedd5c638',
          quantity: 1,
          price: 150,
          name: 'Sequoia, £100.00, 1 x £100.00 / day - Feb 25, 2021',
          discountedPrice: 150,
        },
        credits: [],
        valueCredits: [],
        customPrice: 150,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-10-14T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5f86c78b08339d001110ff71',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Sequoia',
      number: 88,
      size: 10,
      rate: '5f86c6b408339d001110a280',
      description:
        'Additional meeting room\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#e51c1c',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-10-14T09:40:27.644Z',
      modifiedAt: '2020-10-14T09:41:14.192Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
    },
    team: '5dc5632f41e03f036a70a841',
    member: '5f9aba126a95d80011482df7',
    visitors: [],
    members: [],
    plan: '5f86c6b408339d001110a280',
    accountedUntil: null,
    accounted: true,
    reference: 'NJTXC57',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6034dccad5458977f0d5c63d',
  },
  {
    createdAt: '2021-02-26T16:02:17.883Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-26T16:02:17.883Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-03T10:30:00.000Z',
    },
    end: {
      dateTime: '2021-03-03T15:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Confidential Meeting',
    seriesStart: '2021-03-03T10:30:00.000Z',
    seriesEnd: '2021-03-03T15:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-03T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6041418841d4f95fdec46cc2',
          },
          _id: '60391b89894fe77576d95309',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Mar 3, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '60391b18cd68c74b0c9eae21',
    member: '60391b19cd68c7c10c9eae2b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '4G3B458',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '60391b89894fe74cded9530e',
  },
  {
    createdAt: '2021-02-26T16:03:38.189Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-26T16:03:38.189Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-01T08:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-01T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Morning Meeting',
    seriesStart: '2021-03-01T08:00:00.000Z',
    seriesEnd: '2021-03-01T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-01T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '605af2ada0b227bf24e5782f',
          },
          _id: '60391bdacd68c7fb359eb6b3',
          quantity: 1,
          price: 60,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Mar 1, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'DM2W658',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '60391bdacd68c778ee9eb6b8',
  },
  {
    createdAt: '2021-03-02T08:42:06.469Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-02T08:42:06.469Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-03T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-04T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Meeting Ashbrittle',
    seriesStart: '2021-03-03T00:00:00.000Z',
    seriesEnd: '2021-03-04T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-03T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6041418841d4f98aa5c46cc1',
          },
          _id: '603dfa5e7bd6ea338164b7e7',
          quantity: 1,
          price: 75,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Mar 3, 2021',
          discountedPrice: 75,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'JN6NF58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '603dfa5e7bd6ea5a0e64b7f6',
  },
  {
    createdAt: '2021-03-02T13:13:48.336Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-02T13:13:48.336Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-04T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-05T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Thursday Day Pass',
    seriesStart: '2021-03-04T00:00:00.000Z',
    seriesEnd: '2021-03-05T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-04T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6041418841d4f9d853c46cc3',
          },
          _id: '603e3a0c5152071987800ff6',
          quantity: 1,
          price: 20,
          name: 'Day Pass Desk, £20.00, 1 x £20.00 / day - Mar 4, 2021',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-11-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5e5d245d2a554401bf42237f',
      price: 99,
      deposit: 0,
      parents: [],
      type: 'hotdesk',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd301e5c8100104fdcef',
      name: 'Hotdesk 1',
      number: 64,
      parent: null,
      rate: '5e4d2ea0954765029d7f2009',
      color: '#ff0861',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-03-02T15:21:01.343Z',
      modifiedAt: '2021-03-01T15:08:55.864Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedBy: '5d43461d4032200094acdd98',
      target: '5d5a9f8edf86d60010483446',
      targetPlan: '5f90436d389b6500617e5e71',
    },
    team: '603e3995c629b770c182101d',
    member: '603e3995c629b73f51821025',
    visitors: [],
    members: [],
    plan: '5e4d2ea0954765029d7f2009',
    accountedUntil: null,
    accounted: true,
    reference: '9DWW358',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '603e3a0c515207b9c9800ffb',
  },
  {
    createdAt: '2021-03-04T13:33:21.040Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-04T13:33:21.040Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-05T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-06T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-03-05T00:00:00.000Z',
    seriesEnd: '2021-03-06T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-05T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60532e8e00cfd788e79fa76b',
          },
          _id: '6040e1a06f784972cb4e02ce',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 5, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'P6G0Q58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6040e1a16f78491dc54e02dd',
  },
  {
    createdAt: '2021-03-04T16:56:10.575Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-08T16:47:54.616Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-08T14:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-08T18:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Afternoon meeting',
    seriesStart: '2021-03-08T14:00:00.000Z',
    seriesEnd: '2021-03-08T18:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-08T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '605af2ada0b227bf24e5782f',
          },
          _id: '6046553aa74775486ea63168',
          quantity: 1,
          price: 48,
          name: 'Ankerwycke, £60.00, 1 x £60.00 / half day - Mar 8, 2021',
          discountedPrice: 48,
        },
        credits: [],
        valueCredits: [],
        customPrice: 48,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '47VLP58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6041112a30da3fdd1fa00927',
  },
  {
    createdAt: '2021-03-08T09:54:13.397Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-08T16:44:35.154Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-10T00:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-11T00:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-03-10T00:00:00.000Z',
    seriesEnd: '2021-03-11T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-10T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60532e8e00cfd788e79fa76b',
          },
          _id: '6046547357ae191ceb4bb400',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 10, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '38R1M58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6045f445c90f3eed2746e2af',
  },
  {
    createdAt: '2021-03-08T09:54:58.651Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-08T09:54:58.651Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-12T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-13T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day',
    seriesStart: '2021-03-12T00:00:00.000Z',
    seriesEnd: '2021-03-13T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-12T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60532e8e00cfd788e79fa76b',
          },
          _id: '6045f472c90f3e9cfc46e738',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Mar 12, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '8S1VP58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6045f472c90f3e129746e742',
  },
  {
    createdAt: '2021-03-08T10:02:27.502Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-09T11:37:49.883Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-17T00:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-18T00:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-03-17T00:00:00.000Z',
    seriesEnd: '2021-03-18T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-17T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60532e8e00cfd788e79fa76b',
          },
          _id: '60475e0d8a6e471e3b2cc7f5',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 17, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'NCG8458',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6045f633a5621823f20ac71a',
  },
  {
    createdAt: '2021-03-08T10:02:49.441Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-08T10:02:49.441Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-19T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-20T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-03-19T00:00:00.000Z',
    seriesEnd: '2021-03-20T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-19T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '605875d900cfd7194fa248c3',
          },
          _id: '6045f649a5621835c50ac73b',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Mar 19, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: '1QGH258',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6045f649a562187b830ac740',
  },
  {
    createdAt: '2021-03-08T10:03:16.689Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-09T11:38:02.640Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-24T00:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-25T00:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day',
    seriesStart: '2021-03-24T00:00:00.000Z',
    seriesEnd: '2021-03-25T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '60475e1aec6bccc142578f2b',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 24, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '601d188884698070dbc86797',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: '260SP58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6045f664a56218ac9a0ac77c',
  },
  {
    createdAt: '2021-03-08T16:46:43.560Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-08T16:46:43.560Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-08T10:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-08T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Colin meeting for an hour',
    seriesStart: '2021-03-08T10:00:00.000Z',
    seriesEnd: '2021-03-08T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-08T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '605af2ada0b227bf24e5782f',
          },
          _id: '604654f357ae1939bb4bbb51',
          quantity: 1,
          price: 16,
          name: 'Ankerwycke, £20.00, 1 x £20.00 / hour - Mar 8, 2021',
          discountedPrice: 16,
        },
        credits: [],
        valueCredits: [],
        customPrice: 16,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'WZ8VW58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '604654f357ae19fc4f4bbb56',
  },
  {
    createdAt: '2021-03-17T12:46:42.289Z',
    createdBy: '60229baab6c51b47451a3939',
    modifiedAt: '2021-03-18T10:28:05.051Z',
    modifiedBy: '5d43461d4032200094acdd98',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-17T13:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-17T15:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting Room Hire',
    seriesStart: '2021-03-17T13:00:00.000Z',
    seriesEnd: '2021-03-17T15:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-17T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60532e03a974816112d1d6af',
          },
          _id: '60532b3579160269fe044eeb',
          quantity: 1,
          price: 45,
          name: 'Taw Suite 2, £60.00, 3 x £20.00 / hour - Mar 17, 2021',
          discountedPrice: 45,
        },
        credits: [],
        valueCredits: [],
        customPrice: 45,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2021-02-09T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '602d8007649ad180c472beec',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'meeting_room',
      office: '600834bd1d6d843e7165f594',
      room: '602beec7adf4c6824bc5fcfa',
      name: 'Taw Suite 2',
      target: '602bf11c6dce9b81e3102417',
      number: 93,
      size: 15,
      rate: '60229e8cbfc0053774eb6b5c',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2021-02-17T20:43:51.066Z',
      modifiedAt: '2021-03-18T21:22:30.011Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
    },
    team: '6051f95c10deb043bdcea553',
    member: '6051f9ad10deb09393cea73c',
    visitors: [],
    members: [],
    plan: '60229e8cbfc0053774eb6b5c',
    accountedUntil: null,
    free: false,
    accounted: true,
    reference: 'WFTK058',
    office: {
      createdAt: '2021-01-20T13:48:45.001Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedAt: '2021-03-22T15:39:17.784Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Node Cowork',
      slug: 'node-cowork',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/node-1616427278213.jpeg',
      description:
        'Node Cowork is designed to bring together hundreds of entrepreneurs from a variety of backgrounds and industries. A range of coworking options and offices of varying sizes will provide opportunities for start-ups who are growing and scaling quickly.  Programmes and events to support member businesses include Startup Club, TownSq Accelerator, 1:1 surgeries, one-off workshops, 1:1 support from our dedicated team, and larger-scale events in collaboration with our corporate partners.   The project is receiving up to £2,932,162 of funding from the England European Re-gional Development Fund as part of the European Structural and Investment Funds Growth Programme 2014-2020 which is being used towards the development of the building and business support programmes.',
      timezone: 'Europe/London',
      isOpen: true,
      targetRevenuePerPeriod: [],
      public: true,
      zip: 'EX31 3YB',
      city: 'Barnstaple',
      state: 'Devon',
      address: '1 Enterprise Way,',
      _id: '600834bd1d6d843e7165f594',
    },
    _id: '6051fa327373b3f437d6164f',
  },
  {
    createdAt: '2021-03-17T15:06:59.681Z',
    createdBy: '60520cb89520fb2ef5976ca2',
    modifiedAt: '2021-03-17T15:17:49.333Z',
    modifiedBy: '5fd1ec30f4df94e64a18a4c4',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-18T09:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-18T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    summary: 'Meeting Room booking info',
    seriesStart: '2021-03-18T09:00:00.000Z',
    seriesEnd: '2021-03-18T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        credits: [],
        valueCredits: [],
        date: '2021-03-18T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '60521b147bdeec2ea1a00fdb',
          },
          _id: '60521b137bdeecd98da00fcb',
          quantity: 1,
          price: 10,
          name: 'Day Pass Desk, £10.00, 1 x £10.00 / half day - Mar 18, 2021',
          discountedPrice: 10,
        },
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-03-02T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5e5d25ab4429980186ce5feb',
      price: 99,
      deposit: 0,
      parents: [],
      type: 'hotdesk',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd301e5c8100104fdcef',
      name: 'Hotdesk 6',
      number: 69,
      parent: null,
      rate: '5e4d2ea0954765029d7f2009',
      color: '#ff0861',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-03-02T15:26:35.927Z',
      modifiedAt: '2021-03-01T15:10:12.071Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedBy: '5d43461d4032200094acdd98',
      target: '5d5a9f96324ca500102e478c',
      targetPlan: '5f90436d389b6500617e5e71',
    },
    team: '60520cba386f2d58e783d77b',
    member: '60520cbb386f2d665283d783',
    visitors: [],
    members: [],
    plan: '5e4d2ea0954765029d7f2009',
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: '2Q7BF58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '60521b137bdeec5ce7a00fd0',
  },
  {
    createdAt: '2021-03-19T16:32:51.787Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-19T16:32:51.787Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-23T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-24T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All day meeting',
    seriesStart: '2021-03-23T00:00:00.000Z',
    seriesEnd: '2021-03-24T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-23T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '6054d23388368ef57fe83ad3',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Mar 23, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e2858a3e2a1a00267e9f2e6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'B07L358',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6054d23388368ec78be83ad8',
  },
  {
    createdAt: '2021-03-22T10:01:09.676Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-22T10:01:09.676Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-22T15:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-22T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: "Colin's Meeting for an hour",
    seriesStart: '2021-03-22T15:00:00.000Z',
    seriesEnd: '2021-03-22T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-22T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '605af2ada0b227bf24e5782f',
          },
          _id: '60586ae527813a1c6fba3b42',
          quantity: 1,
          price: 16,
          name: 'Ankerwycke, £20.00, 1 x £20.00 / hour - Mar 22, 2021',
          discountedPrice: 16,
        },
        credits: [],
        valueCredits: [],
        customPrice: 16,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'GB1FQ58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '60586ae527813a5f1dba3b47',
  },
  {
    createdAt: '2021-03-23T09:54:14.043Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-23T09:54:14.043Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-23T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-24T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Interviews',
    seriesStart: '2021-03-23T00:00:00.000Z',
    seriesEnd: '2021-03-24T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-23T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '6059bac525dd9898fed1d08b',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 23, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f72097275490b023ff07a4d',
    member: '5f7209f175490b023ff0f0a1',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'VS6H958',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6059bac625dd989b74d1d090',
  },
  {
    createdAt: '2021-03-23T09:57:55.614Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-24T14:25:12.211Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-25T00:00:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2021-03-26T00:00:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting',
    seriesStart: '2021-03-25T00:00:00.000Z',
    seriesEnd: '2021-03-26T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-25T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '605b4bc8b0efb6620f29795f',
          quantity: 1,
          price: 80,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Mar 25, 2021',
          discountedPrice: 80,
        },
        credits: [],
        valueCredits: [],
        customPrice: 80,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '601d18885bad437b7766e694',
    member: '6054b8e908b20d6c57de984e',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    accounted: true,
    reference: 'JMD6758',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6059bba3dcee7a791a2ca945',
  },
  {
    createdAt: '2021-03-23T09:59:50.010Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-23T09:59:50.010Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-24T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-25T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Interviews',
    seriesStart: '2021-03-24T00:00:00.000Z',
    seriesEnd: '2021-03-25T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-24T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '6059bc1568e6254856f67492',
          quantity: 1,
          price: 60,
          name: 'Ashbrittle, £75.00, 1 x £75.00 / day - Mar 24, 2021',
          discountedPrice: 60,
        },
        credits: [],
        valueCredits: [],
        customPrice: 60,
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f72097275490b023ff07a4d',
    member: '5f7209f175490b023ff0f0a1',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    accounted: true,
    reference: 'Q61M858',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6059bc1668e625abcdf67497',
  },
  {
    createdAt: '2021-03-25T09:44:51.144Z',
    createdBy: '6011948fce8e8c91d35cc116',
    modifiedAt: '2021-03-25T09:44:51.144Z',
    modifiedBy: '6011948fce8e8c91d35cc116',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-04-06T07:00:00.000Z',
    },
    end: {
      dateTime: '2021-04-06T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Optec - Interviews ',
    seriesStart: '2021-04-06T07:00:00.000Z',
    seriesEnd: '2021-04-06T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-04-06T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '605c5b932622c55c93c2171f',
          quantity: 1,
          price: 100,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Apr 6, 2021',
          discountedPrice: 100,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f72097275490b023ff07a4d',
    member: '5f7209f175490b023ff0f0a1',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    description: 'Interviewing New Business Executives',
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: 'FJQT859',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '605c5b932622c5b461c21724',
  },
  {
    createdAt: '2021-03-25T10:40:58.125Z',
    createdBy: '6011948fce8e8c91d35cc116',
    modifiedAt: '2021-03-25T10:40:58.125Z',
    modifiedBy: '6011948fce8e8c91d35cc116',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-04-07T07:00:00.000Z',
    },
    end: {
      dateTime: '2021-04-07T16:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Optec',
    seriesStart: '2021-04-07T07:00:00.000Z',
    seriesEnd: '2021-04-07T16:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-04-07T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '605c68bad274692fb85ae771',
          quantity: 1,
          price: 100,
          name: 'Ankerwycke, £100.00, 1 x £100.00 / day - Apr 7, 2021',
          discountedPrice: 100,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f72097275490b023ff07a4d',
    member: '5f7209f175490b023ff0f0a1',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    accounted: true,
    reference: 'ZMPHW59',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '605c68bad274691d605ae776',
  },
  {
    createdAt: '2019-08-02T11:38:37.591Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:54.171Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2019-08-02T13:30:00.000Z',
    },
    end: {
      dateTime: '2019-08-02T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'TSq',
    seriesStart: '2019-08-02T13:30:00.000Z',
    seriesEnd: '2019-08-02T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        valueCredits: [
          {
            credit: {
              intervalLength: 'month',
              _id: '5d4420bd4f0f2c0010227c9e',
              validFrom: '2019-08-01T00:00:00.000Z',
              validTo: '2019-08-31T00:00:00.000Z',
              creditAccount: '5c3cb6f9994fcf013e08f69a',
            },
            _id: '5d4420cd4f0f2c0010227d76',
            count: 14,
            journalId: '5d4420be4f0f2c0010227ca4',
          },
        ],
        extraFees: [],
        date: '2019-08-02T00:00:00.000Z',
        credits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d441beae6b60a00a5866cd7',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: '6KR6N4M',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5d4420bd4f0f2c0010227c9d',
  },
  {
    createdAt: '2020-02-09T14:47:00.372Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:54.150Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-10T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-10T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Sara Test',
    seriesStart: '2020-02-10T10:00:00.000Z',
    seriesEnd: '2020-02-10T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-10T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e58ca6c1293520010d9d1b6',
          },
          _id: '5e401b7a2c6be5081a1322ad',
          quantity: 1,
          price: 50,
          name:
            'Cancelled Ankerwycke, £100.00, 1 x £100.00 / day - Feb 10, 2020',
          discountedPrice: 50,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d441beae6b60a00a5866cd7',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: 'TWN2H4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e401b6452afff0895781357',
  },
  {
    createdAt: '2020-02-09T14:47:49.785Z',
    createdBy: '5c3cb703994fcf013e091273',
    modifiedAt: '2021-01-09T07:38:54.166Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-10T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-10T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Sara test 2',
    seriesStart: '2020-02-10T10:00:00.000Z',
    seriesEnd: '2020-02-10T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-02-10T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e58ca6c1293520010d9d1b6',
          },
          _id: '5e401ba052afff089578162a',
          quantity: 1,
          price: 50,
          name:
            'Cancelled Ankerwycke, £100.00, 1 x £100.00 / day - Feb 10, 2020',
          discountedPrice: 50,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d441beae6b60a00a5866cd7',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: 'DT0X44T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e401b9552afff08957815e8',
  },
  {
    createdAt: '2020-02-21T14:46:40.949Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.663Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-24T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-24T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Fidotech Meeting',
    seriesStart: '2020-02-24T10:00:00.000Z',
    seriesEnd: '2020-02-24T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d4424a44671ac008c08f71b',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: '18P674T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e4fed5052df27065ca8d063',
  },
  {
    createdAt: '2020-02-21T14:48:40.325Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.653Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-25T08:30:00.000Z',
    },
    end: {
      dateTime: '2020-02-25T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Robert Jolley Meeting',
    seriesStart: '2020-02-25T08:30:00.000Z',
    seriesEnd: '2020-02-25T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d4424a44671ac008c08f71b',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: 'HKNQX4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e4fedc852df27065ca8e395',
  },
  {
    createdAt: '2020-02-27T16:54:24.909Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.666Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-02-28T14:00:00.000Z',
    },
    end: {
      dateTime: '2020-02-28T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting 3-5pm',
    seriesStart: '2020-02-28T14:00:00.000Z',
    seriesEnd: '2020-02-28T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: ['5e285a097d7c5d02160b853c'],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'PB6FG4T',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e57f4402a8537010f90ec97',
  },
  {
    createdAt: '2020-03-05T13:15:34.435Z',
    createdBy: '5d43461d4032200094acdd98',
    modifiedAt: '2021-01-09T07:38:54.165Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-06T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-03-06T09:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Testing emails',
    seriesStart: '2020-03-06T08:00:00.000Z',
    seriesEnd: '2020-03-06T09:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-06T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e819998f54cd70010db8bb2',
          },
          _id: '5e60fbcfa8b47100a17a7603',
          quantity: 1,
          price: 20,
          name: 'Cancelled Ankerwycke, £40.00, 2 x £20.00 / hour - Mar 6, 2020',
          discountedPrice: 20,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5cfff39634e36509d69beb46',
    member: '5d4346184f0f2c0010095cc0',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: 'R816F4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e60fb765da8ed007360259f',
  },
  {
    createdAt: '2020-03-05T15:44:01.916Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.162Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-04-08T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-04-08T12:00:00.000Z',
    },
    serviceSlots: {
      before: 10,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Julia Aratoon/ Sue Cavalier Meeting with Catering: veggie lunch',
    seriesStart: '2020-04-08T08:00:00.000Z',
    seriesEnd: '2020-04-08T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e550c1a4821d801033f8aaa',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'XG2264W',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e611e41bd6f460010d2bd66',
  },
  {
    createdAt: '2020-03-12T15:12:17.058Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.663Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-03-18T08:30:00.000Z',
      date: null,
    },
    end: {
      dateTime: '2020-03-18T10:30:00.000Z',
      date: null,
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: '6 person meeting/ biscuits and tea',
    seriesStart: '2020-03-18T08:30:00.000Z',
    seriesEnd: '2020-03-18T10:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2020-03-18T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '5e8c25605bfb66001070abef',
          },
          _id: '5e71e3313832f70010e1eeee',
          quantity: 1,
          price: 24,
          name:
            'Cancelled Ashbrittle, £24.00, 2 x £12.00 / hour (20% discount) - Mar 18, 2020',
          discountedPrice: 24,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e57d8e6e6a44d0121f3b035',
    member: '5e57d98367996401641e87b1',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'DDTWD4V',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5e6a5151dd7ba70010ef13e6',
  },
  {
    createdAt: '2020-07-20T13:28:58.385Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.140Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-23T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-24T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: '4 Person Meeting',
    seriesStart: '2020-09-23T23:00:00.000Z',
    seriesEnd: '2020-09-24T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'LXL4W52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f159c1a4f632f0012495bce',
  },
  {
    createdAt: '2020-08-04T10:39:51.735Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.159Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-13T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-14T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Contact Day',
    seriesStart: '2020-09-13T23:00:00.000Z',
    seriesEnd: '2020-09-14T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'N9JR952',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f293af76925580133c5b0f5',
  },
  {
    createdAt: '2020-08-04T10:40:45.237Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.155Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-20T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-21T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Contact Day',
    seriesStart: '2020-09-20T23:00:00.000Z',
    seriesEnd: '2020-09-21T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '868T852',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f293b2df2316300d3e0ec84',
  },
  {
    createdAt: '2020-08-04T10:42:00.317Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.169Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-27T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-28T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Contact Day',
    seriesStart: '2020-09-27T23:00:00.000Z',
    seriesEnd: '2020-09-28T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '0DSF452',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f293b78f2316300d3e0fdbc',
  },
  {
    createdAt: '2020-08-07T12:06:45.730Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.152Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-06T07:30:00.000Z',
    },
    end: {
      dateTime: '2020-10-06T16:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Lemongrass Appraisals',
    seriesStart: '2020-10-06T07:30:00.000Z',
    seriesEnd: '2020-10-06T16:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'P3DJ653',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f2d43d5c37eed0051eac541',
  },
  {
    createdAt: '2020-08-12T11:06:02.735Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.160Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-14T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-15T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MBA All day meeting',
    seriesStart: '2020-09-14T23:00:00.000Z',
    seriesEnd: '2020-09-15T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5d441cf14f0f2c001022208f',
    member: '5f33cc2482a30001051b53d2',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'TLDBM52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f33cd1a82a30001051b803e',
  },
  {
    createdAt: '2020-08-12T11:06:33.905Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.152Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-15T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-16T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MBA All day meeting',
    seriesStart: '2020-09-15T23:00:00.000Z',
    seriesEnd: '2020-09-16T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5d441cf14f0f2c001022208f',
    member: '5f33cc2482a30001051b53d2',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'BPCLB52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f33cd390a8d76001295f10b',
  },
  {
    createdAt: '2020-08-18T09:27:56.812Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.169Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-16T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-17T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'All Day Meeting',
    seriesStart: '2020-09-16T23:00:00.000Z',
    seriesEnd: '2020-09-17T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '9XL0852',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f3b9f1c9ad7b40032c54afb',
  },
  {
    createdAt: '2020-08-27T15:45:03.808Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.169Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-01T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-02T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Client meeting',
    seriesStart: '2020-09-01T23:00:00.000Z',
    seriesEnd: '2020-09-02T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'KRW6752',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f47d4ff8773520011bd111a',
  },
  {
    createdAt: '2020-08-27T17:27:35.438Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.677Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-14T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-15T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Tuesday Meeting',
    seriesStart: '2020-09-14T23:00:00.000Z',
    seriesEnd: '2020-09-15T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'NLMCW52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f47ed075ca72f0011ff7aee',
  },
  {
    createdAt: '2020-09-17T13:36:33.553Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.154Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-30T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-01T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Meeting',
    seriesStart: '2020-09-30T23:00:00.000Z',
    seriesEnd: '2020-10-01T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5ee0f4a88f23f00011d5b2bf',
    member: '5ee0f4f58f23f00011d5b84b',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'F4JB152',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6366615d9d520011081f01',
  },
  {
    createdAt: '2020-09-22T16:35:46.751Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.147Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-09-23T08:00:00.000Z',
    },
    end: {
      dateTime: '2020-09-23T10:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Short Meeting',
    seriesStart: '2020-09-23T08:00:00.000Z',
    seriesEnd: '2020-09-23T10:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5e5e40446b84b30083e4276a',
    member: '5f3d3ebea6e9fc0011cd9b3c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'J933R52',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f6a27e241d05a01376fb266',
  },
  {
    createdAt: '2020-10-01T09:26:01.075Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.675Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-10-05T23:00:00.000Z',
    },
    end: {
      dateTime: '2020-10-06T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-10-05T23:00:00.000Z',
    seriesEnd: '2020-10-06T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5e28583a7d7c5d02160ab88b',
    member: '5e285a097d7c5d02160b853c',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'FJ89P53',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f75a0a9ef0e000011b307d0',
  },
  {
    createdAt: '2020-10-20T11:26:52.588Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.679Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-17T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-17T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-11-17T09:00:00.000Z',
    seriesEnd: '2020-11-17T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '87FVM54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f8ec97c840c6f01664fece2',
  },
  {
    createdAt: '2020-10-20T11:27:24.449Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.666Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-24T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-24T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2020-11-24T09:00:00.000Z',
    seriesEnd: '2020-11-24T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'MJNZK54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f8ec99c840c6f01664ff439',
  },
  {
    createdAt: '2020-10-26T16:42:07.634Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.146Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-11T00:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-12T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Cafe Interviews',
    seriesStart: '2020-11-11T00:00:00.000Z',
    seriesEnd: '2020-11-12T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f96fc26ee33af00118930a6',
    member: '5f96fc261067e30011829d8c',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '3Q5PW54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5f96fc5fee33af0011893b3e',
  },
  {
    createdAt: '2020-11-02T13:54:16.529Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-11T13:06:29.134Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-25T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-26T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-01-25T00:00:00.000Z',
    seriesEnd: '2021-01-26T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'S86RQ56',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa00f884f4b5a001172a48c',
  },
  {
    createdAt: '2020-11-02T13:54:47.956Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-19T15:55:12.371Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-22T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-23T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-02-22T00:00:00.000Z',
    seriesEnd: '2021-02-23T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: '8W72M57',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa00fa74f4b5a001172b488',
  },
  {
    createdAt: '2020-11-02T13:55:24.269Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-03-04T13:59:05.600Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-28T23:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-29T23:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Monthly Meeting',
    seriesStart: '2021-03-28T23:00:00.000Z',
    seriesEnd: '2021-03-29T23:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f7f2469813c060011b92db4',
    member: '5f7f26cd583a3d001198e408',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'LSQFM58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa00fcc4f4b5a001172cc3f',
  },
  {
    createdAt: '2020-11-06T16:06:02.968Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.650Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-11-24T09:00:00.000Z',
    },
    end: {
      dateTime: '2020-11-24T11:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: '0915 - 1115 Meeting',
    seriesStart: '2020-11-24T09:00:00.000Z',
    seriesEnd: '2020-11-24T11:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'BF84J54',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa5746ab4382bbf8788bc21',
  },
  {
    createdAt: '2020-11-06T16:10:08.230Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.654Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-08T11:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-08T13:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    seriesStart: '2020-12-08T11:00:00.000Z',
    seriesEnd: '2020-12-08T13:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'PD0CH55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fa5756099c545d53410ff6e',
  },
  {
    createdAt: '2020-11-16T13:40:37.980Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.645Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-09T10:00:00.000Z',
    },
    end: {
      dateTime: '2020-12-09T12:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'MacFarlane Mediation',
    seriesStart: '2020-12-09T10:00:00.000Z',
    seriesEnd: '2020-12-09T12:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'PBSDM55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fb281555140984adde926f9',
  },
  {
    createdAt: '2020-11-16T13:41:36.363Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:54.150Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2020-12-07T11:30:00.000Z',
    },
    end: {
      dateTime: '2020-12-07T13:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Tuttle Mediation',
    seriesStart: '2020-12-07T11:30:00.000Z',
    seriesEnd: '2020-12-07T13:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'ZQJ8S55',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fb28190a477fc062c53f566',
  },
  {
    createdAt: '2020-12-10T12:33:26.794Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-09T07:38:56.652Z',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-12T10:30:00.000Z',
    },
    end: {
      dateTime: '2021-01-12T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Mediation',
    seriesStart: '2021-01-12T10:30:00.000Z',
    seriesEnd: '2021-01-12T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'GBWSF56',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fd215962d636871149b69df',
  },
  {
    createdAt: '2020-12-15T15:37:06.116Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-12T10:51:46.565Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-19T10:30:00.000Z',
    },
    end: {
      dateTime: '2021-01-19T12:30:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Tuttle Mediation',
    seriesStart: '2021-01-19T10:30:00.000Z',
    seriesEnd: '2021-01-19T12:30:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'XC0WW56',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fd8d822146eec9c58d1cec4',
  },
  {
    createdAt: '2020-12-16T14:22:32.780Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-01-12T10:52:01.026Z',
    modifiedBy: '5d4424b0e6b60a00a5876525',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-01-27T12:00:00.000Z',
    },
    end: {
      dateTime: '2021-01-27T14:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'Threadgold Mediation',
    seriesStart: '2021-01-27T12:00:00.000Z',
    seriesEnd: '2021-01-27T14:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021b454f46900010afcd8b',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd4b1e5c810010500096',
      name: 'Ashbrittle',
      number: 57,
      size: 8,
      description:
        'meeting room for up to 8 people\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#26ff00',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:45:41.177Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-01-09T07:38:56.089Z',
      rate: '5ff95d8f45b4815a30f27674',
      parents: [],
      target: '5d5aa485324ca500102f1793',
    },
    team: '5f47cc2c03de080011d4b94c',
    member: '5f47d1c98773520011bc69b6',
    visitors: [],
    members: [],
    plan: '5ff95d8f45b4815a30f27674',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'SN2GN56',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '5fda182842d9a6d649dfd963',
  },
  {
    createdAt: '2021-02-19T15:57:38.293Z',
    createdBy: '5d4424b0e6b60a00a5876525',
    modifiedAt: '2021-02-23T12:30:27.602Z',
    modifiedBy: '5d43461d4032200094acdd98',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-02-22T00:00:00.000Z',
    },
    end: {
      dateTime: '2021-02-23T00:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'admin',
    summary: 'NHS 1000 - 1600 event',
    seriesStart: '2021-02-22T00:00:00.000Z',
    seriesEnd: '2021-02-23T00:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-02-22T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'invoiced',
            _id: '6034f5d9a0307063bc318ece',
          },
          _id: '6034f563eba2ee5f38e25c0d',
          quantity: 1,
          price: 150,
          name: 'Cancelled Sequoia, £100.00, 1 x £100.00 / day - Feb 22, 2021',
          discountedPrice: 150,
        },
        credits: [],
        valueCredits: [],
        customPrice: 150,
      },
    ],
    resourceId: {
      access: {
        full: false,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2020-10-14T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5f86c78b08339d001110ff71',
      price: 0,
      deposit: 0,
      parents: [],
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Sequoia',
      number: 88,
      size: 10,
      rate: '5f86c6b408339d001110a280',
      description:
        'Additional meeting room\nhalf day is 4.5 hours  so please book this time for the half day rate',
      color: '#e51c1c',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2020-10-14T09:40:27.644Z',
      modifiedAt: '2020-10-14T09:41:14.192Z',
      createdBy: '5d43461d4032200094acdd98',
      modifiedBy: '5d43461d4032200094acdd98',
    },
    team: '5dc5632f41e03f036a70a841',
    member: '5dc5636ed9b2fd02ae89f2e9',
    visitors: [],
    members: [],
    plan: '5f86c6b408339d001110a280',
    accountedUntil: null,
    canceled: true,
    accounted: true,
    reference: 'VD7VV57',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '602fdff26a99099b63b9de1d',
  },
  {
    createdAt: '2021-03-22T10:48:45.497Z',
    createdBy: '6011948fce8e8c91d35cc116',
    modifiedAt: '2021-03-23T09:23:23.587Z',
    modifiedBy: '6011948fce8e8c91d35cc116',
    organization: '5c3cb6f8994fcf013e08f36d',
    start: {
      dateTime: '2021-03-23T09:00:00.000Z',
    },
    end: {
      dateTime: '2021-03-23T17:00:00.000Z',
    },
    serviceSlots: {
      before: 0,
      after: 0,
    },
    timezone: 'Europe/London',
    source: 'portal',
    summary: 'Optec ',
    seriesStart: '2021-03-23T09:00:00.000Z',
    seriesEnd: '2021-03-23T17:00:00.000Z',
    recurrence: {
      rrule: null,
    },
    fees: [
      {
        extraFees: [],
        date: '2021-03-23T00:00:00.000Z',
        fee: {
          invoice: {
            status: 'not_paid',
          },
          _id: '6059b38b655f4c1ef30207bb',
          quantity: 1,
          price: 100,
          name:
            'Cancelled Ankerwycke, £100.00, 1 x £100.00 / day - Mar 23, 2021',
          discountedPrice: 100,
        },
        credits: [],
        valueCredits: [],
      },
    ],
    resourceId: {
      access: {
        full: true,
        public: false,
        teams: [],
        plans: [],
      },
      availability: [
        {
          startDate: '2019-06-01T00:00:00.000Z',
          endDate: null,
        },
      ],
      _id: '5d021ad5e71ddc0010b66006',
      price: 0,
      deposit: 0,
      type: 'meeting_room',
      office: '5c62cd1855d7470010d90776',
      room: '5c62cd41033f8e0079896a57',
      name: 'Ankerwycke',
      number: 56,
      size: 12,
      description:
        'Meeting room for up to 12 people half day is 4.5 hours  so please book this time for the half day rate',
      organization: '5c3cb6f8994fcf013e08f36d',
      createdAt: '2019-06-13T09:43:49.924Z',
      createdBy: '5c3cb703994fcf013e091273',
      color: '#0033ff',
      modifiedAt: '2021-01-09T07:38:53.676Z',
      rate: '5ff95d8d45b4815a30f27536',
      parents: [],
      target: '5d5aa349324ca500102ee11b',
    },
    team: '5f72097275490b023ff07a4d',
    member: '5f7209f175490b023ff0f0a1',
    visitors: [],
    members: [],
    plan: '5ff95d8d45b4815a30f27536',
    accountedUntil: null,
    tentative: false,
    canceled: true,
    accounted: true,
    reference: '29CBH58',
    office: {
      createdAt: '2019-02-12T13:41:44.443Z',
      createdBy: '5c3cb703994fcf013e091273',
      modifiedAt: '2021-03-22T15:39:01.473Z',
      modifiedBy: '5d43461d4032200094acdd98',
      name: 'Perch - Eco Business Centre',
      slug: 'perch-eco-business-centre',
      code: 'BIC',
      image:
        '//dzrjcxtasfoip.cloudfront.net/user-resources/organization/the-perch-bicester-1616427243787.jpeg',
      description:
        'The Eco Business Centre is the perfect space for ambitious founders, growing teams and contributing to a more sustainable and responsible future.  Launched in 2019, it is the UK’s first PassivHaus Plus workspace, and is an iconic building based in Elmsbrook, a rapidly expanding zero-carbon eco-village.  Run by the team at TownSq Spaces the building offers coworking spaces for freelancers, homeworkers, and employees fed up of the London commute, and offices for growing teams or hosting bespoke events, the Eco Business Centre is as flexible as you need it to be.',
      isOpen: true,
      targetRevenuePerPeriod: [],
      order: 0,
      public: true,
      zip: 'OX27 8BL',
      city: 'Bicester',
      address: 'The Eco Business centre\nElmsbrook\nCharlotte Avenue',
      _id: '5c62cd1855d7470010d90776',
    },
    _id: '6058760d41a32e3213baa2fa',
  },
];
