import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { SignUpRequest, SignUpResponse } from '../types/officeRND';
import { Profile } from '../types/officeRND/profile';
import OfficeRndAPI from '../utils/OfficeRndAPI';
import townSquareOfficeIds from '../utils/townSqOfficeIds';

export interface User {
  _id: string;
  name: string;
  office: string;
  description?: string;
  tags?: any[];
  team?: string;
  properties?: Properties;
  status?: string;
  portalPrivacy?: PortalPrivacy;
  email: string;
  phone?: string;
}

export interface Properties {
  [key: string]: string;
}

export interface PortalPrivacy {
  hideContactDetails: boolean;
  hidePublicProfiles: boolean;
}

type DefaultAuthContext = {
  profile?: Profile | null | undefined;
  signup: (params: SignUpRequest) => Promise<SignUpResponse>;
  signOut: () => null;
  login: (email: string, password: string) => Promise<Profile | undefined>;
  loading: boolean;
  isTownSquare: boolean;
};

const defaultUser = null;
const defaultValues: DefaultAuthContext = {
  profile: defaultUser,
  signup: () =>
    new Promise((res) => {
      return null;
    }),
  signOut: () => null,
  login: (email, password) =>
    new Promise((res) => {
      return null;
    }),
  loading: true,
  isTownSquare: false,
};
const api = new OfficeRndAPI();

export const UserContext = React.createContext(defaultValues);
const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Profile | null>();
  const [loading, setLoading] = useState(true);
  const { update } = useIntercom();

  useEffect(() => {
    if (user) {
      update({
        userId: user.member._id,
        name: user.member.name,
        email: user.member.email,
        phone: user.member.phone,
        company: {
          name: user.team.name,
          companyId: user.team._id,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!loading) {
          setLoading(true);
        }
        const user = await api.getCurrentUser();
        setUser(user);
      } catch (error) {
        console.log(`error`, error);
      } finally {
        setLoading(false);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (email: string, password: string) => {
    try {
      if (!email) {
        console.error('email is required');
        throw new Error('No email found');
      }

      if (!password) {
        console.error('password is required');
        throw new Error('No password provided');
      }
      await api.signIn({ username: email, password: password });
      const user = await api.getCurrentUser();

      if (!user) {
        return undefined;
      }
      setUser(user);
      return user;
    } catch (error: any) {
      console.error(`error signing in`, error);

      const message =
        error?.response?.data?.message ?? 'Sorry could not log you in';
      throw new Error(message);
    }
  };

  const handleSignUp = async ({
    name,
    email,
    company,
    password,
    phone,
  }: SignUpRequest): Promise<SignUpResponse> => {
    try {
      const account = await api.signUp({
        email,
        password,
        name,
        company,
        phone,
      });

      if (!account) {
        throw new Error('No account created');
      }

      return account;
    } catch (error: any) {
      throw new Error(
        error?.response?.data?.message ?? 'Sorry something went wrong'
      );
    }
  };

  const signOut = () => {
    api.signOut();
    setUser(null);
    return null;
  };

  const userOfficeId = user?.member?.office ?? '';
  let isTownSquare =
    !!userOfficeId && townSquareOfficeIds.includes(userOfficeId);

  return (
    <UserContext.Provider
      value={{
        profile: user || defaultUser,
        signup: handleSignUp,
        login: handleLogin,

        signOut,
        isTownSquare,
        // cog: nitoUser: cognitoUser,
        loading: loading,
        // group: group,
        // isLoggedIn: authState === AuthState.SignedIn && !!cognitoUser,
        // updateUserProfile,
        // updateCognitoUserProfile,
        // updatePassword,
        // logout,
        // authorize,
        // forgotPassword,
        // resetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
