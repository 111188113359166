import { BookingRequestResponse } from '../types/officeRND/BookingRequest';
export const isUsingCredits = (req: BookingRequestResponse | undefined) => {
  if (!req) {
    return false;
  }

  const price = req.currentPrice;

  const valueCreditsTotal = req.fees.reduce((finalTotal, fee) => {
    const total = fee.valueCredits.reduce(
      (allCredits, credit) => allCredits + credit.count,
      0
    );

    return finalTotal + total;
  }, 0);

  return price - valueCreditsTotal < 1;
};
