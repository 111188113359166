import './index.css';
import './styles/tailwind.css';

import React from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
// @ts-expect-error
import MenuProvider from 'react-flexible-sliding-menu';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';

import MainLayoutComponent from './components/MainLayoutComponent';
import UserContextProvider from './context/user';
import BookingsScreen from './screens/BookingsScreen';
import EventsScreen from './screens/EventsScreen/EventsScreen';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import MembershipScreen from './screens/MembershipScreen';
import OfficeDetailScreen from './screens/OfficeDetailScreen';
import RegisterScreen from './screens/RegisterScreen';
// import WorklocalSpacesScreen from './screens/WorklocalSpacesScreen';
import * as serviceWorker from './serviceWorker';
import theme from './styles/theme';
import OfficesView from './views/OfficesView';
import 'react-datepicker/dist/react-datepicker.css';
import CheckInScreen from './screens/CheckInScreen';
import {
  IntercomProvider, //useIntercom
} from 'react-use-intercom';

const INTERCOM_APP_ID = 'utj2f90u';

let width = '20%';

if (isMobile) {
  width = '33%';
}

if (isMobileOnly) {
  width = '100%';
}

// The drawer package has an alert in there somewhere. So I need to disable it to stop it from happening.
window.alert = function () {};

const RedirectWithQuery = ({ to = '', exact = false, from = '' }) => {
  return (
    <Route
      exact={exact}
      path={from}
      render={(props) => <Redirect to={`${to}${props.location.search}`} />}
    />
  );
};

export const App = () => (
  <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}>
    <UserContextProvider>
      <ToastProvider autoDismiss>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <MenuProvider
                width={width}
                className="w-full md:w-1/5"
                direction="right"
                MenuComponent={OfficesView}
                animation="reveal"
              >
                <MainLayoutComponent>
                  <Route exact path="/" component={HomeScreen} />
                  <Route exact path="/dashboard" component={HomeScreen} />
                  <Route
                    exact
                    path="/venues/:officeId"
                    component={OfficeDetailScreen}
                  />
                  {/* <Route
                    exact
                    path="/office/:officeId"
                    component={OfficeDetailScreen}
                  /> */}
                  <Route exact path="/office" component={OfficeDetailScreen} />
                  <Route exact path="/venues" component={HomeScreen} />
                  <Route exact path="/bookings" component={BookingsScreen} />
                  <Route exact path="/events" component={EventsScreen} />
                  <Route
                    exact
                    path="/membership"
                    component={MembershipScreen}
                  />
                  <Route exact path="/login" component={LoginScreen} />
                  <Route exact path="/register" component={RegisterScreen} />
                  <Route exact path="/check-in" component={CheckInScreen} />
                  <RedirectWithQuery exact from="/checkin" to="/check-in" />
                </MainLayoutComponent>
              </MenuProvider>
            </Switch>
          </Router>
        </ThemeProvider>
      </ToastProvider>
    </UserContextProvider>
  </IntercomProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
