import React from 'react';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';

interface BookingConfirmationSceneProps {
  handleCloseModal: () => void;
  message?: string;
}

const BookingConfirmationScene: React.FC<BookingConfirmationSceneProps> = (
  props
) => {
  return (
    <>
      <div className="mb-4 bg-gray-100 p-4 text-center text-sm text-gray-700">
        <p>
          {props.message ||
            `After booking you will receive an email with your booking
          confirmation. Please read the instructions before arriving at the
          venue. If it is your first time using the space, you will need to
          arrive between 9am and 10am so that your host can greet you, get you
          connected to the Wi-Fi, and make sure you have everything you need.`}
        </p>
      </div>
      <div className="flex justify-center">
        <PrimaryButtonComponent onClick={props.handleCloseModal}>
          Got it
        </PrimaryButtonComponent>
      </div>
    </>
  );
};

export default BookingConfirmationScene;
