// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Marker } from '!react-map-gl';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listing } from './MapMultiComponent';

export const PinWithAction = ({
  listing,
  setAction,
}: {
  listing: Listing;
  setAction: (value: any) => void;
}) => {
  return listing.lng && listing.lat ? (
    <Marker
      longitude={+listing.lng}
      latitude={+listing.lat}
      anchor="bottom"
      onClick={(e: { originalEvent: { stopPropagation: () => void } }) => {
        // If we let the click event propagates to the map, it will immediately close the popup
        // with `closeOnClick: true`
        e.originalEvent.stopPropagation();
        console.log(listing);
        setAction(listing);
      }}
    >
      <div
      // type={listing.type}
      >
        <FontAwesomeIcon
          className="mb-4 mt-2 h-8 w-8 text-cowork-blue"
          icon={faLocationDot}
        />
      </div>
    </Marker>
  ) : (
    <></>
  );
};

export default PinWithAction;
