import { faMapMarkerAlt, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
// @ts-expect-error
import { MenuContext } from 'react-flexible-sliding-menu';
import ReactLoading from 'react-loading';
import { useHistory, useParams } from 'react-router-dom';

import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';
import useOffices from '../../hooks/useOffices';
import { TailwindTheme } from '../../types';
import { Office } from '../../types/officeRND/offices';

export type OfficesViewProps = {
  asSelect?: boolean;
};
export type OfficeCardProps = {
  office: Office;
  isActive?: boolean;
};
const OfficeCard = (props: OfficeCardProps) => {
  const office = props.office;
  const { toggleMenu } = useContext(MenuContext);

  const history = useHistory();

  const activeClassName = classNames({
    'sm:bg-realGray-500': props.isActive,
    'sm:bg-realGray-600': !props.isActive,
  });

  const handleGoToOfffice = () => {
    history.push(`/venues/${office._id}`);
    if (toggleMenu) {
      toggleMenu();
    }
  };

  return (
    <button
      onClick={handleGoToOfffice}
      className={`${activeClassName} mb-4 w-full cursor-pointer rounded-lg bg-realGray-600 p-4 text-left text-realGray-200 transition-all hover:bg-realGray-500`}
    >
      <div className="flex">
        <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt} />
        <div className="w-full text-sm">
          <p className="font-bold text-white">{office.name}</p>
          <div className="mt-2 flex justify-between text-xs">
            <p>{office.city}</p>
            <p>{office.zip}</p>
          </div>
        </div>
      </div>
    </button>
  );
};
type Params = {
  officeId?: string;
};
const OfficesView: React.FC<OfficesViewProps> = (props) => {
  const { offices, searchOffices, loading } = useOffices({ mock: false });
  const { isTownSquare } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState<string>();
  const { toggleMenu } = useContext(MenuContext);

  const params = useParams<Params>();

  const activeId = params?.officeId;
  useEffect(() => {
    searchOffices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    searchOffices(searchTerm);
  };

  const onReset = () => {
    setSearchTerm('');
    searchOffices();
  };

  const theme = classNames({
    [TailwindTheme.TOWNSQ]: isTownSquare,
    [TailwindTheme['COWORK-LOCAL']]: !isTownSquare,
  });

  return (
    <div
      id="sidebar"
      className={`h-full min-h-screen overflow-y-auto bg-realGray-800 p-2 ${theme}`}
    >
      <div className="flex justify-end">
        <button onClick={toggleMenu}>
          <FontAwesomeIcon className="mr-2 mt-4 text-gray-100" icon={faTimes} />
        </button>
      </div>
      <div className="my-8 text-right">
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearch();
            }
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="focus:outline-none mb-4 w-full border-b-2 border-t-0 border-l-0 border-r-0 border-white bg-transparent p-2 text-white"
          type="text"
          placeholder="Search locations"
        />

        <button className="mr-4 text-sm text-gray-200" onClick={onReset}>
          Reset
        </button>
        <PrimaryButtonComponent onClick={onSearch}>
          Search
        </PrimaryButtonComponent>
      </div>
      <div className="mb-4 flex justify-center">
        {loading && <ReactLoading delay={500} width={24} height={24} />}
      </div>

      <div className="h-full overflow-y-auto">
        {offices.map((office) => {
          return (
            <OfficeCard
              isActive={activeId === office._id}
              key={office._id}
              office={office}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OfficesView;
