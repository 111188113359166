import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { GetBookingsRequest } from '../types/officeRND';
import { Amenity } from '../types/officeRND/Amenity';
import { Booking, CancelBookingRequest } from '../types/officeRND/Booking';
import {
  BookingRequestInput,
  BookingRequestResponse,
  CreateBookingInput,
  CreateBookingResponse,
} from '../types/officeRND/BookingRequest';
import { Membership } from '../types/officeRND/membership';
import { Office } from '../types/officeRND/offices';
import { CreditAccount, Profile } from '../types/officeRND/profile';
import { Resource } from '../types/officeRND/Resource';
import hiddenOfficeIds from './hiddenOfficeId';
import { OfficeRndAPIAuth } from './OfficeRndAPIAuth';

type GetOfficesRequest = {
  name?: string;
};

type TokenResponse = {
  token: string;
};
export default class OfficeRndAPI extends OfficeRndAPIAuth {
  url: string;
  public constructor() {
    super();
    this.url = '/organizations/townsquare';
    this._initializeRequestInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequest);
  };

  /**
   * This just adds the token to each request
   */
  private _handleRequest = (config: AxiosRequestConfig) => {
    const token = window.localStorage.getItem('user_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  };

  /**
   * Get the currently logged in users profile
   */
  public getCurrentUser = async (): Promise<Profile> => {
    try {
      const userId = this.getUserId();
      if (!userId) {
        throw new Error('No member id found');
      }

      const user = await this.instance.get(`${this.url}/user/profile`);

      return user;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public getCurrentUserToken = (): string | null | undefined => {
    return this.user_token;
  };

  /**
   * Fetch all bookings for the logged in member
   */
  public getBookings = async (filter?: GetBookingsRequest) => {
    const profile = await this.getCurrentUser();

    if (!profile) {
      throw new Error('No profile found');
    }

    const filtersWithMemberId = {
      $populate: 'office',
      ...filter,
      team: profile.team._id,
    };

    return this.instance.get<GetBookingsRequest, Booking[]>(
      `${this.url}/user/bookings`,
      {
        params: filtersWithMemberId,
      }
    );
  };

  // Bookings

  /**
   * Booking request will check if its possible to book the resource on the given time and date
   */
  public bookingRequest = (input: BookingRequestInput) => {
    // console.log(`input`, input);
    return this.instance.post<BookingRequestInput, BookingRequestResponse[]>(
      `${this.url}/user/bookings/checkout-summary`,
      input
    );
  };

  /**
   * create booking with make a booking on office rnd
   */
  public createBooking = (input: CreateBookingInput) => {
    return this.instance.post<CreateBookingInput, CreateBookingResponse[]>(
      `${this.url}/user/bookings`,
      input
    );
  };

  /**
   * Cancel a booking. Not sure what the implications of this is. Needs more research.
   */

  public cancelBooking = (bookingId: string) => {
    return this.instance.post<CancelBookingRequest, Booking>(
      `${this.url}/user/bookings/${bookingId}/cancel`
    );
  };

  /**
   * List all offices
   */
  public getOffices = async (name?: string) => {
    const results = await this.instance.get<GetOfficesRequest, Office[]>(
      `${this.url}/offices`,
      {
        params: {
          isOpen: true,
          public: true,
          name: name,
        },
      }
    );
    // Remove any offices we don't want to show
    const withoutHiddenOffices = results.filter(
      (off) => !hiddenOfficeIds.includes(off._id)
    );
    return withoutHiddenOffices;
  };

  /**
   * Get a specific office by id
   */
  public getOffice = (id: string) => {
    return this.instance.get<GetOfficesRequest, Office>(
      `${this.url}/offices/${id}`
    );
  };

  // Membership API

  /**
   * List all the memberships the user has had.
   */
  public getMemberships = () => {
    return this.instance.get<null, Membership[]>(
      `${this.url}/user/memberships`
    );
  };

  /**
   * The currently active membership for the user.
   */
  public getActiveMembership = async (): Promise<
    Membership | null | undefined
  > => {
    try {
      const user = await this.getCurrentUser();
      if (!user) return;
      const memberships = await this.getMemberships();

      const activeMembership = _.chain(memberships)
        .filter((mem) => mem.status === 'active' || mem.status === 'pending')
        .filter((mem) => mem.member === user.member._id)
        .filter((mem) => dayjs(mem.endDate).isAfter(dayjs()))
        .filter((mem) => dayjs(mem.startDate).isBefore(dayjs()))
        .sortBy(memberships, 'startDate')
        .first()
        .value();

      return activeMembership;
    } catch (error) {
      console.log(`could not get memberships`, error);
    }
  };

  /**
   * The currently credit balance
   */
  public getCreditBalance = async (date: Date): Promise<number | undefined> => {
    try {
      const user = await this.getCurrentUser();
      const accounts = await this.instance.get<null, CreditAccount[]>(
        `${this.url}/credit-accounts/stats?team=${user.team._id}&month=${dayjs(
          date
        ).format('YYYY-MM-DD')}`
      );
      // console.log(accounts);
      return accounts.reduce((total, acc) => {
        return total + acc.summary.remaining;
      }, 0);
    } catch (error) {
      console.log(`could not get memberships`, error);
    }
  };

  // General APIs

  public getResources = (filter?: Partial<Resource>): Promise<Resource[]> => {
    return this.instance.get<Partial<Resource>, Resource[]>(
      `${this.url}/resources`,
      {
        params: {
          ...filter,
          $populate: 'targetPlan,target,rate',
        },
      }
    );
  };

  public getAmenities = async (ids?: string[]): Promise<Amenity[]> => {
    const result = await this.instance.get<any, Amenity[]>(
      `${this.url}/amenities`
    );

    if (ids) {
      return result.filter((res) => ids.includes(res._id));
    }

    return result;
  };

  public getToken = async () => {
    try {
      const profile = await this.getCurrentUser();

      const result = await this.instance.post<any, TokenResponse>(
        `${this.url}/auth/member`,
        {
          body: {
            email: profile.member.email,
          },
        }
      );

      return result;
    } catch (error: any) {
      console.log(`error`, error.message);
    }
  };
}
