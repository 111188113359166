import classNames from 'classnames';
import React from 'react';

export type TabItemComponentProps = {
  id?: string;
  isActive?: boolean;
  onClick?: (id: string) => void;
};

const TabItemComponent: React.FC<TabItemComponentProps> = (props) => {
  const isActive = props.isActive;
  const activeClassName = classNames({
    'border-b-4': isActive,
    'border-primary-500': isActive,
    'text-gray-700': isActive,
    'font-bold': isActive,
  });

  return (
    <button
      key={props.id}
      onClick={() => props.onClick && props.onClick(props?.id ?? '')}
      className={`text-gray-500 py-1 px-2 mr-4 block hover:text-primary-500 focus:outline-none outline-none transition-all ${activeClassName}`}
    >
      {props.children}
    </button>
  );
};

export default TabItemComponent;
