import React from 'react';
import CardComponent from '../CardComponent';

export type AuthCardComponentProps = {
  title: string;
  description?: string;
};

const AuthCardComponent: React.FC<AuthCardComponentProps> = (props) => {
  const url = window.location.href;
  const hasCoworkInURL = url.includes('cowork');

  return (
    <>
      <CardComponent className="text-center">
        <h2 className="mb-4 text-lg font-bold">{props.title}</h2>
        {props.description && (
          <p className="mb-4 max-w-sm text-sm text-gray-500">
            {props.description}
          </p>
        )}
        <div className="flex w-full max-w-sm flex-col text-center lg:p-8">
          {props.children}
        </div>
        {hasCoworkInURL && (
          <div className="mt-2 mb-2 flex items-center justify-center text-xs text-gray-500">
            <img
              src="https://dzrjcxtasfoip.cloudfront.net/user-resources/organization/town-square-1564689794134.png"
              className="mr-2 h-12 w-12 rounded"
              alt="town square logo"
            />
            Powered by{' '}
            <a
              className="ml-1 font-bold text-primary-500 underline"
              href="https://thetownsquare.co.uk/"
            >
              TownSq
            </a>
          </div>
        )}
      </CardComponent>
    </>
  );
};

export default AuthCardComponent;
