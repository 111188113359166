import {
  faDesktop,
  faDoorClosed,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import classnames from 'classnames';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ReactModal from 'react-modal';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { UserContext } from '../../context/user';
import BookingForm from '../../forms/BookingForm';
import { CreateBookingType } from '../../hooks/useBookings/useBookings';
import useResources from '../../hooks/useResources';
import { TailwindTheme } from '../../types';
import { Rate2 } from '../../types/officeRND/Resource';
import bottom from './bottom.svg';

type BookingModalProps = {
  officeId: string;
  onRequestClose: () => void;
  isOpen: boolean;
};

type SpaceCardProps = {
  title: string;
  price?: string;
  rates?: Rate2[] | undefined;
  onSelect: (type: CreateBookingType) => void;
  type: CreateBookingType;
  isActive: boolean;
  disabled?: boolean;
};

const SpaceCard = (props: SpaceCardProps) => {
  const style = classnames({
    'border-primary-500': props.isActive,
    'shadow-lg': !props.disabled,
    'bg-white': !props.disabled,
    'bg-gray-100': props.disabled,
    'pointer-events-none': props.disabled,
  });

  const textColor = classnames({
    'text-gray-700': !props.disabled,
    'text-gray-500': props.disabled,
  });

  return (
    <button
      disabled={props.disabled}
      onClick={() => props.onSelect(props.type)}
      className={`outline-none mx-2 mt-4 mb-4 w-full cursor-pointer rounded-xl border-4 p-8 text-center ring-0 transition-all focus-visible:ring-2 focus-visible:ring-primary-600 ${style}`}
    >
      {props.type === CreateBookingType.HOTDESK && (
        <FontAwesomeIcon className="mb-4 mt-2" icon={faDesktop} />
      )}
      {props.type === CreateBookingType.MEETING_ROOM && (
        <FontAwesomeIcon className="mb-4 mt-2" icon={faDoorClosed} />
      )}
      <p className={textColor}>{props.title}</p>
      <p className="font-bold">{props.price}</p>

      {/* {props.rates?.map((rate) => {
        const interval = rate.isHalfDayRate
          ? 'half day'
          : `${rate.intervalQuantity} ${rate.interval}`;

        return (
          <p>
            <span className="font-bold">£{rate.price} </span>
            <span className="text-sm text-gray-500">{interval}</span>
          </p>
        );
      })} */}
    </button>
  );
};

const BookingModal = (props: BookingModalProps) => {
  const { isTownSquare } = useContext(UserContext);
  const { resources, loading, getResources } = useResources();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<CreateBookingType>(
    CreateBookingType.HOTDESK
  );

  const [modalTitle, setModalTitle] = useState<string>('Book a space');

  useEffect(() => {
    // console.log(`getting resources`);
    getResources({
      office: props.officeId,
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedByPrice = _.sortBy(resources, 'price');

  const hasMeetingRoom =
    (resources?.findIndex((res) => res.type === 'meeting_room') ?? -1) > -1;
  const hasHotDesk =
    (resources?.findIndex((res) => res.type === 'hotdesk') ?? -1) > -1;

  useEffect(() => {
    // If there is only a meeting room available
    if (
      !hasHotDesk &&
      hasMeetingRoom &&
      selectedType === CreateBookingType.HOTDESK
    ) {
      setSelectedType(CreateBookingType.MEETING_ROOM);
    }

    // If there is only a hot desk available.
    if (
      !hasMeetingRoom &&
      hasHotDesk &&
      selectedType === CreateBookingType.MEETING_ROOM
    ) {
      setSelectedType(CreateBookingType.HOTDESK);
    }
  }, [hasHotDesk, hasMeetingRoom, selectedType]);

  const meetingRoomRates = sortedByPrice?.find(
    (res) => res.type === 'meeting_room'
  )?.rate?.rates;
  const hotDeskRates = sortedByPrice?.find((res) => res.type === 'hotdesk')
    ?.rate?.rates;

  // TODO: Fix this so that it is using the theme set in main layout

  const theme = classNames({
    [TailwindTheme.TOWNSQ]: isTownSquare,
    [TailwindTheme['COWORK-LOCAL']]: !isTownSquare,
  });

  const handleClose = () => {
    setShowForm(false);
    props.onRequestClose();
  };

  return (
    <ReactModal
      className={`${theme} translate-y-1/5 absolute right-1/2 w-full max-w-lg translate-x-1/2 transform rounded bg-white p-8 text-center shadow-lg md:translate-y-1/4`}
      isOpen={props.isOpen}
      onRequestClose={handleClose}
    >
      {loading && (
        <div className="flex min-h-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )}
      <div className="absolute bottom-0 left-0 -z-10 w-full">
        <img src={bottom} alt="triange svg" className="w-full" />
      </div>

      <div className="z-50">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-700"
        >
          <FontAwesomeIcon className="mb-4 mt-2" icon={faTimes} />
        </button>
        <h2 className="mb-4 text-2xl font-bold">{modalTitle}</h2>

        {!showForm && (
          <div className="items-between flex text-center">
            <SpaceCard
              onSelect={setSelectedType}
              isActive={
                selectedType === CreateBookingType.HOTDESK && hasHotDesk
              }
              type={CreateBookingType.HOTDESK}
              title="Hot Desk"
              rates={hotDeskRates}
              disabled={!hasHotDesk}
            />

            <SpaceCard
              onSelect={setSelectedType}
              isActive={
                selectedType === CreateBookingType.MEETING_ROOM &&
                hasMeetingRoom
              }
              type={CreateBookingType.MEETING_ROOM}
              title="Meeting Room"
              rates={meetingRoomRates}
              disabled={!hasMeetingRoom}
            />
          </div>
        )}
        {showForm && (
          <BookingForm
            type={selectedType}
            officeId={props.officeId}
            setModalTitle={setModalTitle}
            handleCloseModal={handleClose}
            goBack={() => setShowForm(false)}
          />
        )}

        <div className="mt-8 flex items-center justify-center">
          {!showForm && (
            <PrimaryButtonComponent
              disabled={!hasMeetingRoom && !hasHotDesk}
              onClick={() => setShowForm(true)}
            >
              Next
            </PrimaryButtonComponent>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default BookingModal;
