import RegisterScreen from './RegisterScreen';

type RegisterScreenContainerProps = {};

const RegisterScreenContainer: React.FC<RegisterScreenContainerProps> = (
  props
) => {
  return <RegisterScreen />;
};

export default RegisterScreenContainer;
