import dayjs from 'dayjs';
import officeInfo from './officeInformation';

export default function customDaysFunc(
  slug: string | undefined,
  date: string | Date
) {
  const officeMeta = officeInfo.find((off) => off.slug === slug);

  // IF CUSTOM DAYS EXIST, USE THEM. OTHERWISE RETURN EMPTY ARRAY
  const customDays = officeMeta?.custom_days ?? [];

  const day = dayjs(date).day();

  const month = dayjs(date).month();

  const dayjsdate = dayjs(date).date();

  const year = dayjs(date).year();

  // ! TEMPORARY FIX FOR OLD PENARTHIANS VENUE, SINCE BOOKING AVAILABLE AFTER 5TH SEPTEMBER 2022

  const oldPenarthianCustom =
    year > 2022 || month > 8 || (month === 8 && dayjsdate > 5);

  const isWeekdayCustom =
    slug === 'old-penarthians-rfc-cowork-local'
      ? oldPenarthianCustom &&
        day !== 0 &&
        day !== 6 &&
        customDays.includes(day)
      : customDays.length === 0
      ? // IF NO CUSTOM DAYS, RETURN TRUE IF THE DATE IS NOT A WEEKEND
        day !== 0 && day !== 6
      : // IF CUSTOM DAYS EXIST, RETURN TRUE IF DATE IS NOT A WEEKEND _AND_ DATE IS INCLUDED IN THE LIST OF CUSTOM DAYS (THESE ARE IN SAME ORDER AS Date.prototype.getDay(), WHERE 0 REPRESENTS SUNDAY)
        day !== 0 && day !== 6 && customDays.includes(day);

  if (officeMeta?.custom_days) {
    return isWeekdayCustom;
  } else {
    return day !== 0 && day !== 6;
  }
}
