import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory, useLocation } from 'react-router';
//import { useParams } from 'react-router-dom';
import Select from 'react-select';
//import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import BookingCardComponent from '../../components/BookingCardComponent';
// import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import TabItemComponent from '../../components/TabItemComponent';
import TabsComponent from '../../components/TabsComponent';
import { UserContext } from '../../context/user';
import useBookings from '../../hooks/useBookings';
import { GetBookingsRequest } from '../../types/officeRND';
import BookingModal from '../../views/BookingsModal';
import ConfirmedBookingModal from '../../views/ConfirmedBookingModal';

export type BookingsScreenProps = {};

const BookingsScreen: React.FC<BookingsScreenProps> = (props) => {
  const { bookings, getBookings, loading, cancelBooking } = useBookings({
    mock: false,
  });
  console.log({ bookings });
  const [showBookingsModal, setShowBookingsModal] = useState<boolean>(false);
  const [showConfirmedBookingsModal, setShowConfirmedBookingsModal] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<string>();
  const { profile } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  //const { addToast } = useToasts();

  const path = location.search ?? 'upcoming';

  useEffect(() => {
    const today = dayjs().startOf('day').toISOString();

    // Be sure it includes today 'gte'
    let filterParams: GetBookingsRequest | null = {
      'start.dateTime.$gte': today,
    };

    if (path.includes('past')) {
      filterParams = { 'end.dateTime.$lt': today };
    }

    // Always filter by teamId otherwise it returns all bookings
    filterParams = { ...filterParams, team: profile?.member.team };

    // Be sure it is using user_id not member_id
    if (filter === 'createdByMe') {
      filterParams = { ...filterParams, createdBy: profile?.user._id };
    }

    if (filter === 'myBookings') {
      filterParams = { ...filterParams, member: profile?.member._id };
    }

    getBookings(filterParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, filter]);

  // get params for modal
  const url = new URL(window.location.href);
  const date = url.searchParams.get('date') ?? '';
  const office = url.searchParams.get('office') ?? '';
  // useeffect to show modal if the query string has a date
  useEffect(
    () => {
      if (date && office && date !== 'undefined' && office !== 'undefined') {
        setShowConfirmedBookingsModal(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  const handleFilter = (search: string) => {
    history.push({ search: `filter=${search}` });
  };

  const options = [
    { value: 'teamBookings', label: 'Team Bookings' },
    { value: 'myBookings', label: 'My Bookings' },
    { value: 'createdByMe', label: 'Created by me' },
  ];

  return (
    <Wrapper>
      <div className="flex flex-wrap items-center justify-between">
        <h2 className="max-w-sm text-2xl font-bold text-gray-700">
          Your Bookings
        </h2>
        {/* {profile?.member.office ? (
          <PrimaryButtonComponent onClick={() => setShowBookingsModal(true)}>
            Make Booking
          </PrimaryButtonComponent>
        ) : (
          <Link to="/venues">
            <PrimaryButtonComponent>Make Booking</PrimaryButtonComponent>
          </Link>
        )} */}
      </div>
      <div className="align-center my-8 flex flex-wrap justify-between">
        <TabsComponent>
          <TabItemComponent
            onClick={handleFilter}
            id="upcoming"
            isActive={path.includes('upcoming') || !path}
          >
            Upcoming bookings
          </TabItemComponent>

          <TabItemComponent
            onClick={handleFilter}
            id="past"
            isActive={path.includes('past')}
          >
            Past bookings
          </TabItemComponent>
        </TabsComponent>
        <Select
          defaultValue={options[1]}
          onChange={(opt) => setFilter(opt?.value)}
          className="w-full max-w-md md:w-48"
          options={options}
        />
      </div>

      {loading && (
        <div className="flex min-h-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )}

      {bookings.length < 1 && !loading && (
        <div className="flex items-center justify-center">
          <h2 className="text-lg font-bold text-gray-500">No bookings</h2>
        </div>
      )}
      <div className="grid gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-3">
        {bookings.map((booking) => {
          return (
            <BookingCardComponent
              key={booking._id}
              booking={booking}
              onCancelBooking={cancelBooking}
            />
          );
        })}
      </div>

      <BookingModal
        officeId={profile?.member.office ?? profile?.team.office ?? ''}
        isOpen={showBookingsModal}
        onRequestClose={() => setShowBookingsModal(false)}
      />
      <ConfirmedBookingModal
        isOpen={showConfirmedBookingsModal}
        onRequestClose={() => setShowConfirmedBookingsModal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default BookingsScreen;
