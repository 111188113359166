import axios from 'axios';
import { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { UserContext } from '../../context/user';
import MembershipScreen from './MembershipScreen';

type MembershipScreenContainerProps = {};

const MembershipScreenContainer: React.FC<MembershipScreenContainerProps> = (
  props
) => {
  const { profile } = useContext(UserContext);
  const { addToast } = useToasts();

  const enquireAboutTeams = async () => {
    const body = {
      memberName: profile?.member.name,
      memberEmail: profile?.member.email,
      memberNumber: profile?.member.phone,
    };

    await axios.post('/.netlify/functions/request-teams', {
      ...body,
    });

    addToast(
      `Your request has been sent. The Cowork Local team will get back to you shortly about our teams offering.`,
      { appearance: 'success' }
    );
    return true;
  };

  return (
    <MembershipScreen enquireAboutTeams={enquireAboutTeams} />
  );
};

export default MembershipScreenContainer;
