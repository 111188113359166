/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  // faArrowLeft,
  faCheck,
  // faDoorClosed,
  // faDoorOpen,
} from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import CardComponent from '../../components/CardComponent';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import DatePickerComponent from '../../components/DatePickerComponent';
import type {
  CheckAvailabilityFunc,
  CreateBookingFunc,
  Availability,
  Method,
} from './NewBookingFormContainer';
import { Office } from '../../types/officeRND/offices';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { CreateBookingType } from '../../hooks/useBookings/useBookings';
import { formMinBookingDate } from '../../utils/formMinBookingDate';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router';
import { Resource } from '../../types/officeRND/Resource';
import { UserContext } from '../../context/user';
import { watch } from 'fs';
import officeInfo from '../../utils/officeInformation';
import customDaysFunc from '../../utils/customDaysFunc';
import formMinDate, { formMinDateTest } from '../../utils/formMinDate';

import utc from 'dayjs/plugin/utc';
import compareLocalDateToUTCDate from '../../utils/compareLocalDateToUTCDate';
dayjs.extend(utc);

export type NewBookingFormProps = {
  office?: Office;
  checkAvailability: CheckAvailabilityFunc;
  confirmBooking: CreateBookingFunc;
  availability?: Availability;
  resetForm: () => void;
  loading: boolean;
  resources: Resource[];
};

export type Inputs = {
  time: 'am' | 'pm' | 'allDay';
  title: string;
  description: string;
  date: Date;
  size?: string;
};

type OptionType = { value: CreateBookingType; label: string };

type FormValues = {
  type?: OptionType;
  date: Date;
};

const NewBookingForm: React.FC<NewBookingFormProps> = (props) => {
  // test
  // const testDay = dayjs().set('hour', 21).toDate();
  // const minDate = formMinDateTest(testDay);

  const minDate = formMinDate(props.office?.slug);

  // Module Hooks
  const [formLoading, setFormLoading] = useState(false);
  const url = new URL(window.location.href);
  const defaultDate = url.searchParams.get('date');
  const [type, setType] = useState<CreateBookingType>(
    (url.searchParams.get('type') as CreateBookingType) ??
      CreateBookingType.HOTDESK
  );
  const params = useParams<{ officeId?: string }>();
  let officeId = params?.officeId;
  const { addToast } = useToasts();
  const { profile } = useContext(UserContext);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const [startDate, setStartDate] = useState(minDate);

  useEffect(() => {
    if (defaultDate && type) {
      // IN CASE OF BST/UTC TIMEZONE DIFFERENCE
      // compareLocalDateToUTCDate(defaultDate);
      const checkedDate = dayjs(defaultDate);

      props.checkAvailability({
        date: checkedDate.toDate(),
        type,
        period: 'allDay',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: OptionType[] = [
    { value: CreateBookingType.HOTDESK, label: 'Book a desk' },
  ];

  const hasMeetingRoom =
    props.resources.filter((r) => {
      return r.type === 'meeting_room';
    }).length > 0;

  if (hasMeetingRoom) {
    options.push({
      value: CreateBookingType.MEETING_ROOM,
      label: 'Book a meeting room',
    });
  }

  const checkAvailabilitySubmit = () => {
    try {
      setFormLoading(true);
      return handleSubmit(
        async (values: FormValues) => {
          // console.log('Form values: ', values);
          // Check if we have an availability - if not we're just checking availability

          // IN CASE OF BST/UTC TIMEZONE DIFFERENCE
          // compareLocalDateToUTCDate(dayjs(values.date));
          const checkedDate = dayjs(values.date);

          await props.checkAvailability({
            date: checkedDate.toDate(),
            type: values.type?.value ?? CreateBookingType.HOTDESK,
            period: 'allDay',
          });
        },
        (errors) => {
          console.log('Form Error!', errors);
          setFormLoading(false);
        }
      )();
    } catch (e) {
      console.log(e);
      addToast('Sorry, something went wrong', { appearance: 'error' });
      // Do a snackbar log or something to share the errors
    } finally {
      setFormLoading(false);
    }
  };

  const createBookingSubmit = (method: Method) => {
    try {
      setFormLoading(true);
      return handleSubmit(
        async (values: FormValues) => {
          // console.log('Form values: ', values);
          // TODO: add modal on confirmation
          if (props.availability) {
            await props.confirmBooking(method);
            // addToast('Booking created', { appearance: 'success' });
          }
        },
        (errors) => {
          console.log('Form Error!', errors);
          setFormLoading(false);
        }
      )();
    } catch (e) {
      console.log(e);
      addToast('Sorry, something went wrong', { appearance: 'error' });
      // Do a snackbar log or something to share the errors
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div className="w-full md:w-1/3 md:pl-8">
      <CardComponent className="relative w-full">
        {/* booking card */}
        <div className="h-5/6 divide-y divide-primary-900">
          <div className="flex h-0.47 flex-col justify-between px-6">
            <div className="mb-8 flex flex-col items-center pt-4 font-bold text-gray-700">
              <h3 className="mb-6 max-w-md text-xl font-bold text-gray-700">
                Book a desk
              </h3>
              <h2 className="max-w-md text-2xl font-bold text-gray-700">
                <span className="mr-3 text-2xl font-light">
                  <s>£20</s>
                </span>
                £12.50/day
              </h2>
              <p className="text-md my-2 max-w-xl font-light text-gray-500">
                or
              </p>
              <h2 className="max-w-md text-2xl font-bold text-gray-700">
                1 day pass
              </h2>
            </div>
          </div>
          <div className="flex h-0.47 flex-col justify-between">
            <section>
              <form
                className="w-full pt-4"
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <Controller
                  {...register('type', { required: true })}
                  render={({ field }) => (
                    <Select
                      {...field}
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: '#cbd5e1',
                          border: '1px solid #cbd5e1',
                          borderRadius: '0px',
                          height: '3em',
                        }),
                        indicatorsContainer: (base) => ({
                          ...base,
                          color: '#000000',
                        }),
                      }}
                      className="w-full mt-8 mb-4"
                      options={options}
                      onChange={(val, e) => {
                        props.resetForm();
                        setType(val?.value ?? CreateBookingType.HOTDESK);
                        field.onChange(val, e);
                      }}
                      // isDisabled={options.length === 1}
                    />
                  )}
                  name="type"
                  defaultValue={
                    options.find((o) => o.value === type) ?? options[0]
                  }
                  control={control}
                /> */}
                <Controller
                  {...register('date', { required: true })}
                  render={({ field }) => (
                    <DatePickerComponent
                      {...field}
                      selected={field?.value}
                      value={undefined}
                      dateFormat="dd/MM/yyyy"
                      minDate={minDate}
                      excludeTimes={[]}
                      //filterDate={customDays ? isWeekdayCustom : isWeekday}
                      filterDate={(val) =>
                        customDaysFunc(props.office?.slug, val)
                      }
                      onChange={(val, e) => {
                        props.resetForm();
                        field.onChange(val, e);
                      }}
                      placeholderText={
                        dayjs(field?.value).isValid()
                          ? dayjs(field?.value)
                              .add(1, 'day')
                              .format('ddd DD MMM')
                          : 'Select a date'
                      }
                      // placeholderText="Select a date"
                      className="bordered focus:outline-none w-full border-2 border-gray-400 p-2 text-center"
                      wrapperClassName="w-full"
                    />
                  )}
                  name="date"
                  defaultValue={
                    defaultDate ? dayjs(defaultDate).toDate() : undefined
                  }
                  control={control}
                />
                {type === CreateBookingType.HOTDESK && (
                  <button
                    className={
                      !watch('date')
                        ? 'mt-4 mb-8 h-10 w-full cursor-not-allowed border border-black opacity-25'
                        : 'mt-4 mb-8 h-10 w-full border border-black'
                    }
                    type="submit"
                    value="Submit"
                    onClick={checkAvailabilitySubmit}
                    disabled={!watch('date')}
                    // disabled={props.loading || formLoading}
                  >
                    {props.loading || formLoading
                      ? 'Loading...'
                      : 'Check availability'}
                  </button>
                )}
                {type === CreateBookingType.MEETING_ROOM && (
                  <>
                    <p className="mt-4 text-sm">
                      We can't accept meeting room bookings through this
                      platform yet. Click below to enquire.
                    </p>
                    <PrimaryButtonComponent
                      className="mt-4 w-full"
                      type="submit"
                      value="Submit"
                      onClick={checkAvailabilitySubmit}
                      disabled={props.loading || formLoading}
                    >
                      Enquire Now
                    </PrimaryButtonComponent>
                  </>
                )}
              </form>
            </section>
          </div>
          {props.availability && (
            <div className="flex flex-col items-center justify-center">
              <div className="mt-8 mb-8 flex align-middle">
                {props.availability.hasAvailability ? (
                  <>
                    <p>Available</p>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mt-1 ml-2 text-green-500"
                    />
                  </>
                ) : (
                  <>
                    <p>Not Available</p>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="mt-1 ml-2 text-red-500"
                    />
                  </>
                )}
              </div>
              {props.availability.dayPassesRemaining <= 20 && !!profile && (
                <div className="items-center text-center lg:block lg:w-full lg:justify-center lg:pb-10">
                  <p className="text-sm">
                    {props.availability.dayPassesRemaining +
                      ' day passes remaining for ' +
                      dayjs(props.availability.date).format('MMMM')}
                  </p>
                </div>
              )}
              {!props.availability.hasAvailability && (
                <p className="text-sm">Please select another date.</p>
              )}
              {props.availability.hasAvailability && !!profile && (
                <>
                  <PrimaryButtonComponent
                    className="mb-4 w-full"
                    type="submit"
                    disabled={!props.availability.canUsePayment}
                    onClick={() => createBookingSubmit('PAYMENT')}
                  >
                    Make payment (£12.50)
                  </PrimaryButtonComponent>
                  <PrimaryButtonComponent
                    className="w-full"
                    type="submit"
                    disabled={!props.availability.canUseDayPasses}
                    onClick={() => {
                      createBookingSubmit('DAYPASS');
                      // FIXME: searchParams hook
                    }}
                  >
                    Use 1 day pass
                  </PrimaryButtonComponent>
                </>
              )}
              {!profile && (
                <>
                  <p className="mb-4 text-sm">
                    You need to create an account to use Cowork Local.
                  </p>
                  <PrimaryButtonComponent
                    className="mb-4"
                    onClick={() =>
                      history.push(
                        `/register?type=${type}&office=${
                          props.office?._id
                        }&date=${props.availability?.date?.toISOString() ?? ''}`
                      )
                    }
                  >
                    Create Account
                  </PrimaryButtonComponent>
                </>
              )}
            </div>
          )}
        </div>
      </CardComponent>
    </div>
  );
};

export default NewBookingForm;
