// example theme.js
export const theme = {
  fonts: {
    body: 'system-ui, sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: 'Menlo, monospace',
  },
  textColor: {
    primary: 'blue',
    secondary: '#ffed4a',
    danger: '#e3342f',
  },
  colors: {
    text: '#000',

    background: '#fff',
    primary: '#33e',
  },
};

export default theme;
