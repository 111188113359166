import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import CardComponent from '../../components/CardComponent';
import ContentCardComponent from '../../components/ContentCardComponent';
import useOffices from '../../hooks/useOffices';
import { Office } from '../../types/officeRND/offices';
import pattern from './pattern.svg';

export type WorklocalSpacesScreenProps = {};

export type LocationCardProps = {
  name: string;
  image: string;
  location: string;
  id: string;
};

const LocationCard = (props: LocationCardProps) => {
  const history = useHistory();

  return (
    <CardComponent className="relative m-2 w-full max-w-xs" isLocationCard>
      <div className="absolute top-3 right-3">
        <Button className="h-8 px-2 text-white">
          <span className="text-lg font-medium tracking-wide">£12.50/day</span>
        </Button>
      </div>
      {props.image && (
        <img
          className={` h-48 w-full rounded-t-xl object-cover `} // rounded-full w-24 lg:w-48
          src={props.image ?? pattern}
          alt={props.name}
        />
      )}
      <div className="h-40 flex-col justify-items-stretch p-8 pt-6">
        <div className="flex h-1/2 items-center font-bold text-gray-700">
          <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt} />
          <p>{props.name}</p>
        </div>
        <div className="mt-4 flex h-1/2 items-center justify-between ">
          <p className="text-gray-700">{props.location}</p>
          <button
            onClick={() => history.push(`/office/${props.id}`)}
            className="outline-none rounded border-2 border-primary-500 p-2 text-xs font-bold text-gray-800 hover:border-primary-600"
          >
            Select Location
          </button>
        </div>
      </div>
    </CardComponent>
  );
};

const WorklocalSpacesScreen: React.FC<WorklocalSpacesScreenProps> = (props) => {
  const { getAndSetOffices, offices, loading } = useOffices({
    mock: false,
  });

  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    getAndSetOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filteredOffices: Office[] = offices;

  if (searchTerm) {
    const options = {
      keys: ['name', 'city'],
    };

    const fuse = new Fuse(offices, options);
    const results = fuse.search(searchTerm);

    filteredOffices = results.map((res) => res.item);
  }

  return (
    <div>
      <ContentCardComponent
        className="relative overflow-hidden"
        title="Find a space"
        description="Working closer to home is good for our communities, environments and local economies. By choosing to use a Cowork Local space, you're contributing to a greener planet and healthier world"
      >
        <img
          className="invisible absolute -bottom-8 -right-32 max-w-sm md:visible lg:-right-24"
          alt="pattern"
          src={pattern}
        />
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          placeholder="The Track"
          className="mt-8 w-full max-w-sm border-b-2 border-primary-500 p-2"
        />
      </ContentCardComponent>

      {loading && (
        <div className="flex h-full w-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )}
      <div className="mt-24 flex flex-wrap justify-start">
        {filteredOffices.map((office) => {
          return (
            <LocationCard
              id={office._id}
              key={office._id}
              name={office.name}
              image={office.image ?? ''}
              location={office.city ?? ''}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WorklocalSpacesScreen;

const Button = styled.button`
  background-color: #25b092;
  font-family: 'Futura PT', sans-serif;
  border-radius: 5px;
`;
