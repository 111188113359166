import { HttpClient } from './axios';
import { Response } from '../hooks/useEvents/types';
export class EventBriteAPI extends HttpClient {
  public constructor() {
    super('/api/events');
  }

  public getEvents = () => {
    return this.instance.get<any, Response>('/upcoming');
  };
}
