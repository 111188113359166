import React, { //useEffect 
} from 'react';
import OfficeDetailScreen from './OfficeDetailScreen';

//import { Resource } from '../../types/officeRND/Resource';
//import useResources from '../../hooks/useResources';
//import _ from 'lodash';
import { Office } from '../../types/officeRND/offices';

type OfficeDetailScreenContainerProps = {
  officeId: string;
  office?: Office;
};

const OfficeDetailScreenContainer: React.FC<
  OfficeDetailScreenContainerProps
> = ({ office }) => {
  // const { resources, loading, getResources } = useResources();

  // useEffect(() => {
  //   getResources({
  //     office: office?._id,
  //   });
  // }, []);

  // //  get office id from url
  // const officeId = window.location.pathname.split('/')[2];
  // // console.log('🚀 ~ officeId', officeId);

  // const meetingRooms = resources?.filter((r) => r.type === 'meeting_room');

  // // filter meetingRooms with officeId
  // const currentOffice = meetingRooms?.filter((r) => r.office === officeId);
  // console.log('🚀 ~ currentOffice', currentOffice);
  // // console.log('🚀 ~ meetingRooms', meetingRooms);
  // const sortedByPrice = _.sortBy(meetingRooms, 'price');
  // // console.log('🚀 ~ sortedByPrice', sortedByPrice);

  return <OfficeDetailScreen />;
};

export default OfficeDetailScreenContainer;
