import axios from 'axios';
import React from 'react';
import { OptionTypeBase } from 'react-select';

import AuthCardComponent from '../../components/AuthCardComponent';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import useOffices from '../../hooks/useOffices';

enum CheckedInStatus {
  CHECKED_IN = 'CHECKED_IN',
  ERROR = 'ERROR',
  VALIDATING = 'VALIDATING',
  CHECKED_OUT = 'CHECKED_OUT',
}

function CheckInScreen() {
  const { getOffices, offices, //loading 
} = useOffices({ mock: false });

  // check if there is a venueId in the url query params
  const url = new URL(window.location.href);
  const venueId =
    url.searchParams.get('venueId') || url.searchParams.get('venue');
  const urlCode = url.searchParams.get('code');

  React.useEffect(() => {
    getOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (urlCode) {
      onSubmit(urlCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCode]);

  const options: OptionTypeBase[] = offices.map((office) => ({
    value: office._id,
    label: office.name,
    slug: office.slug,
  }));

  const option = options.find((option) => option.slug === venueId);

  const [selectedVenue, setSelectedVenue] = React.useState<
    OptionTypeBase | undefined | null
  >(option);

  const [code, setCode] = React.useState<string | null | undefined>(urlCode);

  const [checkedInStatus, setCheckedInStatus] = React.useState<CheckedInStatus>(
    CheckedInStatus.CHECKED_OUT
  );

  const onSubmit = async (userCode?: string) => {
    if (!code && !userCode) {
      return;
    }

    const body = {
      reference: (code || userCode)?.toLocaleUpperCase(),
      venueId: selectedVenue?.value,
    };
    try {
      setCheckedInStatus(CheckedInStatus.VALIDATING);

      const { data } = await axios.post('/.netlify/functions/check-in-member', {
        ...body,
      });
      const booking = data?.booking;

      if (!booking) {
        setCheckedInStatus(CheckedInStatus.ERROR);
        return;
      }

      setSelectedVenue({
        value: booking?.office?.slug,
        slug: booking?.office?.slug,
        label: booking?.office?.name,
      });

      setCheckedInStatus(CheckedInStatus.CHECKED_IN);
      // console.log('booking', booking);
    } catch (error) {
      setCheckedInStatus(CheckedInStatus.ERROR);
      console.log('error', error);
    }
  };

  let title = 'Welcome - Check in';
  if (selectedVenue && selectedVenue.label) {
    title = `Welcome - Check in @ ${selectedVenue.label}`;
  }

  if (checkedInStatus === CheckedInStatus.CHECKED_IN) {
    return (
      <AuthCardComponent
        title={`@${selectedVenue?.label}`}
        description="You have been checked-in successfully!"
      >
        <h2 className="mb-4 w-full text-xl font-bold text-gray-900 ">
          Welcome to {selectedVenue?.label}.
        </h2>

        <p className="mb-4 text-gray-600">
          Let us know how you enjoyed your day{' '}
          <a
            href="https://twitter.com/coworklocal"
            className="font-bold text-primary-500 hover:text-primary-600"
          >
            @coworklocal
          </a>
        </p>
      </AuthCardComponent>
    );
  }

  return (
    <AuthCardComponent title={title}>
      <div>
        <div className="">
          <p className="mb-4 text-gray-600">
            Please provide the booking reference provided in your confirmation
            email to check in.
          </p>

          {/* {!option ? (
            <Select
              value={selectedVenue}
              onChange={(v) => setSelectedVenue(v)}
              className="text-gray-500"
              classNamePrefix="text-gray-800 hover:bg-primary-500"
              placeholder="Choose your venue"
              options={options}
            />
          ) : null} */}

          <div className="my-8">
            <label
              className=" mr-2 hidden text-sm text-gray-600"
              htmlFor="code-input"
            >
              Ref
            </label>
            <input
              disabled={checkedInStatus === CheckedInStatus.VALIDATING}
              value={code ?? ''}
              onChange={(e) => setCode(e.target.value)}
              className="w-full rounded border-2 border-gray-200 p-2 uppercase"
              id="code-input"
              type="text"
              placeholder="WLTQ21B"
            />
            <p className="mt-4 text-xs text-gray-600">
              You can find the booking reference in the confirmation email of
              your booking. It wil likely be 7 - 10 characters long and a
              mixture of numbers and letters.
            </p>
          </div>
          {checkedInStatus === CheckedInStatus.ERROR ? (
            <div className="mb-8 flex flex-col items-center justify-center">
              <div className="text-center">
                <p className="mb-2 font-bold text-red-500">
                  Sorry something is not right
                </p>
                <p className="text-sm text-gray-700">
                  Please check your{' '}
                  <span className="font-bold text-red-500">ref is correct</span>
                  . The booking reference also has to be valid for{' '}
                  <span className="font-bold text-red-500">today</span>.
                </p>
              </div>
            </div>
          ) : null}
          <PrimaryButtonComponent
            loading={checkedInStatus === CheckedInStatus.VALIDATING}
            className="w-full"
            onClick={() => onSubmit()}
          >
            Check in
          </PrimaryButtonComponent>
        </div>
      </div>
    </AuthCardComponent>
  );
}

export default CheckInScreen;
