import { Event } from '../../types/eventbrite/event';
export const mockData: Event[] = [
  {
    name: {
      text:
        'All Things Social (Media) with Francesca Irving, Part of Devon Startup Club',
      html:
        'All Things Social (Media) with Francesca Irving, Part of Devon Startup Club',
    },
    description: {
      text:
        'In this workshop, Francesca will be presenting how to do all things social media to help you take your business posts to the next level.',
      html:
        'In this workshop, Francesca will be presenting how to do all things social media to help you take your business posts to the next level.',
    },
    url:
      'https://www.eventbrite.co.uk/e/all-things-social-media-with-francesca-irving-part-of-devon-startup-club-tickets-148578298691',
    vanity_url: 'https://allthingssocialmedia.eventbrite.co.uk',
    start: {
      timezone: 'Europe/London',
      local: '2021-04-01T18:00:00',
      utc: '2021-04-01T17:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-04-01T20:00:00',
      utc: '2021-04-01T19:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-29T10:33:34Z',
    changed: '2021-03-29T11:16:52Z',
    published: '2021-03-29T10:41:50Z',
    capacity: 20,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 480,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'In this workshop, Francesca will be presenting how to do all things social media to help you take your business posts to the next level.',
    logo_id: '130531071',
    organizer_id: '30027916274',
    category_id: undefined,
    venue_id: undefined,
    subcategory_id: undefined,
    format_id: '9',
    id: '148578298691',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/148578298691/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 0 }, width: 2160, height: 1080 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130531071%2F433003881468%2F1%2Foriginal.20210329-111320?auto=format%2Ccompress&q=75&sharp=10&s=bfd3a9499dd9d705480e26f0c7b52991',
        width: 2160,
        height: 1080,
      },
      id: '130531071',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130531071%2F433003881468%2F1%2Foriginal.20210329-111320?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C2160%2C1080&s=69a74b85dc01666a8340c8381c596982',
      aspect_ratio: '2',
      edge_color: '#14e56d',
      edge_color_set: true,
    },
  },
  {
    name: {
      text: 'A Super Practical Introduction to 10 Business Planning Concepts',
      html: 'A Super Practical Introduction to 10 Business Planning Concepts',
    },
    description: {
      text:
        'In this workshop session with Simon Brand from Enhancico Limited, we’ll look at 10 business planning concepts to help you formulate your own',
      html:
        'In this workshop session with Simon Brand from Enhancico Limited, we’ll look at 10 business planning concepts to help you formulate your own',
    },
    url:
      'https://www.eventbrite.co.uk/e/a-super-practical-introduction-to-10-business-planning-concepts-tickets-148034931465',
    start: {
      timezone: 'Europe/London',
      local: '2021-04-12T12:00:00',
      utc: '2021-04-12T11:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-04-12T14:00:00',
      utc: '2021-04-12T13:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-24T16:00:52Z',
    changed: '2021-03-24T16:09:33Z',
    published: '2021-03-24T16:09:33Z',
    capacity: 60,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 720,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'In this workshop session with Simon Brand from Enhancico Limited, we’ll look at 10 business planning concepts to help you formulate your own',
    logo_id: '130108871',
    organizer_id: '30027916274',
    venue_id: undefined,
    category_id: '101',
    subcategory_id: '1001',
    format_id: '9',
    id: '148034931465',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/148034931465/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 63 }, width: 1200, height: 600 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130108871%2F433003881468%2F1%2Foriginal.20210324-160912?auto=format%2Ccompress&q=75&sharp=10&s=0c039dbc8ade51bbf213dbbeae6e33c7',
        width: 1200,
        height: 675,
      },
      id: '130108871',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130108871%2F433003881468%2F1%2Foriginal.20210324-160912?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C63%2C1200%2C600&s=34628b71f1c2e138e6ca79506e5952c5',
      aspect_ratio: '2',
      edge_color: '#14e56d',
      edge_color_set: true,
    },
  },
  {
    name: {
      text: 'Startup Club: 8 Week Programme',
      html: 'Startup Club: 8 Week Programme',
    },
    description: {
      text:
        'Over 8 weeks we provide you with fully funded expert support to help you start and develop your business.',
      html:
        'Over 8 weeks we provide you with fully funded expert support to help you start and develop your business.',
    },
    url:
      'https://www.eventbrite.co.uk/e/startup-club-8-week-programme-tickets-145882896671',
    start: {
      timezone: 'Europe/London',
      local: '2021-04-15T18:00:00',
      utc: '2021-04-15T17:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-06-03T20:00:00',
      utc: '2021-06-03T19:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-12T14:27:58Z',
    changed: '2021-03-17T15:16:56Z',
    published: '2021-03-17T10:22:50Z',
    capacity: 60,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 720,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'Over 8 weeks we provide you with fully funded expert support to help you start and develop your business.',
    logo_id: '129342977',
    organizer_id: '30027916274',
    venue_id: undefined,
    category_id: '101',
    subcategory_id: '1001',
    format_id: '9',
    id: '145882896671',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/145882896671/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 68 }, width: 1200, height: 600 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F129342977%2F433003881468%2F1%2Foriginal.20210317-102204?auto=format%2Ccompress&q=75&sharp=10&s=f7d7957666562b6b91ddb14c992225fd',
        width: 1200,
        height: 675,
      },
      id: '129342977',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F129342977%2F433003881468%2F1%2Foriginal.20210317-102204?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C68%2C1200%2C600&s=250c0d3bfd66ac7b82f3e4477999589e',
      aspect_ratio: '2',
      edge_color: undefined,
      edge_color_set: true,
    },
  },
  {
    name: {
      text: 'The Power of PR For Your Business',
      html: 'The Power of PR For Your Business',
    },
    description: {
      text:
        'PR is an incredible tool to build your brand as you scale up and grow, but how can you actually start making it work for your business.',
      html:
        'PR is an incredible tool to build your brand as you scale up and grow, but how can you actually start making it work for your business.',
    },
    url:
      'https://www.eventbrite.co.uk/e/the-power-of-pr-for-your-business-tickets-148760421425',
    start: {
      timezone: 'Europe/London',
      local: '2021-04-20T14:00:00',
      utc: '2021-04-20T13:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-04-20T15:00:00',
      utc: '2021-04-20T14:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-30T12:06:32Z',
    changed: '2021-03-30T12:13:34Z',
    published: '2021-03-30T12:13:34Z',
    capacity: 60,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 480,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'PR is an incredible tool to build your brand as you scale up and grow, but how can you actually start making it work for your business.',
    logo_id: '130666921',
    organizer_id: '30027916274',
    venue_id: undefined,
    category_id: '101',
    subcategory_id: '1001',
    format_id: '9',
    id: '148760421425',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/148760421425/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 68 }, width: 1200, height: 600 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130666921%2F433003881468%2F1%2Foriginal.20210330-121315?auto=format%2Ccompress&q=75&sharp=10&s=53539bf978a69cb0db05af5fd3ec6103',
        width: 1200,
        height: 675,
      },
      id: '130666921',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130666921%2F433003881468%2F1%2Foriginal.20210330-121315?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C68%2C1200%2C600&s=913bd2a760f59e6566f1b2d66541cca2',
      aspect_ratio: '2',
      edge_color: '#14e56d',
      edge_color_set: true,
    },
  },
  {
    name: {
      text: 'Youtube SEO: How To Rank Your Videos on Youtube Search',
      html: 'Youtube SEO: How To Rank Your Videos on Youtube Search',
    },
    description: {
      text:
        'Want to gain more views, subscribers and grow your YouTube channel organically?',
      html:
        'Want to gain more views, subscribers and grow your YouTube channel organically?',
    },
    url:
      'https://www.eventbrite.co.uk/e/youtube-seo-how-to-rank-your-videos-on-youtube-search-tickets-148639423517',
    start: {
      timezone: 'Europe/London',
      local: '2021-04-22T12:00:00',
      utc: '2021-04-22T11:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-04-22T14:00:00',
      utc: '2021-04-22T13:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-29T16:22:17Z',
    changed: '2021-03-29T16:29:46Z',
    published: '2021-03-29T16:29:46Z',
    capacity: 60,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 720,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'Want to gain more views, subscribers and grow your YouTube channel organically?',
    logo_id: '130567865',
    organizer_id: '30027916274',
    venue_id: undefined,
    category_id: '101',
    subcategory_id: '1001',
    format_id: '9',
    id: '148639423517',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/148639423517/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 13 }, width: 1200, height: 600 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130567865%2F433003881468%2F1%2Foriginal.20210329-162245?auto=format%2Ccompress&q=75&sharp=10&s=c7bc05128da0298036a264438f52aea8',
        width: 1200,
        height: 675,
      },
      id: '130567865',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130567865%2F433003881468%2F1%2Foriginal.20210329-162245?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C13%2C1200%2C600&s=89927ac0d3d226221c5f11514c943188',
      aspect_ratio: '2',
      edge_color: undefined,
      edge_color_set: true,
    },
  },
  {
    name: {
      text: 'Goal Setting: Allan Maxwell',
      html: 'Goal Setting: Allan Maxwell',
    },
    description: {
      text:
        'This Workshop aims to help you use goal-setting to get you and your business motivated and moving forwards!',
      html:
        'This Workshop aims to help you use goal-setting to get you and your business motivated and moving forwards!',
    },
    url:
      'https://www.eventbrite.co.uk/e/goal-setting-allan-maxwell-tickets-148245752035',
    start: {
      timezone: 'Europe/London',
      local: '2021-05-13T12:00:00',
      utc: '2021-05-13T11:00:00Z',
    },
    end: {
      timezone: 'Europe/London',
      local: '2021-05-13T13:00:00',
      utc: '2021-05-13T12:00:00Z',
    },
    organization_id: '433009490458',
    created: '2021-03-25T17:38:21Z',
    changed: '2021-03-25T17:45:09Z',
    published: '2021-03-25T17:45:09Z',
    capacity: 60,
    capacity_is_custom: false,
    status: 'live',
    currency: 'GBP',
    listed: true,
    shareable: true,
    invite_only: false,
    online_event: true,
    show_remaining: false,
    tx_time_limit: 720,
    hide_start_date: false,
    hide_end_date: false,
    locale: 'en_GB',
    is_locked: false,
    privacy_setting: 'unlocked',
    is_series: false,
    is_series_parent: false,
    inventory_type: 'limited',
    is_reserved_seating: false,
    show_pick_a_seat: false,
    show_seatmap_thumbnail: false,
    show_colors_in_seatmap_thumbnail: false,
    source: 'coyote',
    is_free: true,
    version: undefined,
    summary:
      'This Workshop aims to help you use goal-setting to get you and your business motivated and moving forwards!',
    logo_id: '130246339',
    organizer_id: '30027916274',
    venue_id: undefined,
    category_id: '101',
    subcategory_id: '1001',
    format_id: '9',
    id: '148245752035',
    resource_uri: 'https://www.eventbriteapi.com/v3/events/148245752035/',
    is_externally_ticketed: false,
    logo: {
      crop_mask: { top_left: { x: 0, y: 61 }, width: 1200, height: 600 },
      original: {
        url:
          'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130246339%2F433003881468%2F1%2Foriginal.20210325-173833?auto=format%2Ccompress&q=75&sharp=10&s=216594aafcc48fab8b8aa9738955341b',
        width: 1200,
        height: 675,
      },
      id: '130246339',
      url:
        'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F130246339%2F433003881468%2F1%2Foriginal.20210325-173833?h=200&w=450&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C61%2C1200%2C600&s=604c3fbab4d74ae1ca1a4d6b803e64b0',
      aspect_ratio: '2',
      edge_color: '#14e56d',
      edge_color_set: true,
    },
  },
];
