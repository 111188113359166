import {
  faEllipsisV,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { Booking, Office } from '../../types/officeRND/Booking';
import CardComponent from '../CardComponent';

export type BookingCardComponentProps = {
  booking?: Booking;
  onCancelBooking: (bookingId: string) => void;
};
type BookingPopOverProps = {
  bookingId: string;
  onCancelBooking: (id: string) => void;
};
const BookingPopOver = (props: BookingPopOverProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const onCancelBooking = async () => {
    if (props.onCancelBooking) {
      setOpen(false);
      await props.onCancelBooking(props.bookingId);
    }
  };

  return (
    <Popover className="relative">
      <button className="p-4" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon className="text-primary-500" icon={faEllipsisV} />
      </button>
      {open && (
        <Popover.Panel
          static
          className="absolute right-0 z-10 w-72 rounded bg-gray-800 p-4 text-white"
        >
          <div className="grid grid-cols-1">
            <button className="hover:underline" onClick={onCancelBooking}>
              Cancel Booking
            </button>
          </div>

          <img src="/solutions.jpg" alt="" />
        </Popover.Panel>
      )}
      <Popover.Overlay
        className={`${
          open ? 'fixed inset-0 opacity-30' : 'opacity-0'
        } bg-black`}
      />
    </Popover>
  );
};

const BookingCardComponent: React.FC<BookingCardComponentProps> = (props) => {
  if (!props.booking) {
    return null;
  }
  const booking = props.booking;
  const office = booking.office as Office;

  const startTime = dayjs(booking.start.dateTime).hour();
  const endTime = dayjs(booking.end.dateTime).hour();

  const isMorningBooking: boolean = startTime < 12 && endTime <= 14;
  const isAfternoonBooking: boolean = startTime >= 12;
  const isAllDayBooking: boolean =
    (startTime <= 12 && endTime) >= 15 || startTime === endTime;

  let timeSlot = 'ALL DAY';
  if (isMorningBooking) {
    timeSlot = 'MORNING';
  }
  if (isAfternoonBooking) {
    timeSlot = 'AFTERNOON';
  }

  if (isAllDayBooking) {
    timeSlot = 'ALL DAY';
  }

  const isInThePast = dayjs(booking.start.dateTime).isBefore(dayjs());
  return (
    <CardComponent disabled={booking.canceled} className="relative">
      {/* <div className="flex w-full flex-row justify-between">
        <div className="bg-primary-100">01</div>
        <div className="w-full grow bg-primary-300">03</div>
      </div> */}
      <div className="mb-4 flex w-full flex-wrap items-start justify-between">
        <div className="flex grow items-center text-gray-700">
          <FontAwesomeIcon className="mr-3" icon={faMapMarkerAlt} />
          {office.name && <p className="text-sm">{office.name}</p>}
        </div>
        {booking.canceled && (
          <span
            className=" m-1 flex items-center justify-end rounded bg-gray-800 px-3 text-sm text-gray-100"
            //py-1
          >
            Cancelled
          </span>
        )}
        {!booking.canceled && !isInThePast && (
          <div className="absolute top-4 right-4">
            <BookingPopOver
              bookingId={booking._id}
              onCancelBooking={props.onCancelBooking}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <h2 className="font-bold text-gray-800">{booking?.summary}</h2>
        {/* {booking.canceled && (
          <span className="flex items-center justify-center rounded bg-gray-800 px-4 py-1 text-sm text-gray-100">
            Cancelled
          </span>
        )} */}
      </div>
      <p className="mt-2 text-sm text-gray-600">{booking.description}</p>
      <div className="mt-4 flex items-center justify-between text-xs uppercase text-gray-400">
        <p>{dayjs(booking.start.dateTime).format('dddd, DD MMMM YYYY')}</p>
        <p className="font-bold text-gray-700">{timeSlot}</p>
      </div>
    </CardComponent>
  );
};

export default BookingCardComponent;
