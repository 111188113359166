import classNames from 'classnames';
import React from 'react';
import ReactLoading from 'react-loading';

export interface PrimaryButtonComponentProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  disabled?: boolean;
}

const PrimaryButtonComponent: React.FC<PrimaryButtonComponentProps> = (
  props
) => {
  const styles = classNames({
    'bg-primary-500': !props.loading || !props.disabled,
    'bg-primary-200': props.loading || props.disabled,
    'shadow-md': !props.loading || !props.disabled,
    'shadow-sm': props.loading || props.disabled,
    'text-white': props.loading,
    'hover:bg-primary-600': !props.loading || !props.disabled,
  });

  return (
    <button
      disabled={props.loading || props.disabled}
      {...props}
      className={`flex justify-center py-2 px-4 ${styles} focus:outline-none rounded-lg font-semibold text-primary-100 transition-all   focus:ring-2 focus:ring-primary-400 focus:ring-opacity-75 ${props.className}`}
    >
      {props.children}
      {props.loading && (
        <ReactLoading width={24} height={24} className="ml-4" />
      )}
    </button>
  );
};

export default PrimaryButtonComponent;
