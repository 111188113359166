import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import classNames from 'classnames';
//import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ReactModal from 'react-modal';
import { UserContext } from '../../context/user';
import { useToasts } from 'react-toast-notifications';
// import { formMinBookingDate } from '../../utils/formMinBookingDate';
//import useBookings, {
//CreateBookingType,
//} from '../../hooks/useBookings/useBookings';
import useResources from '../../hooks/useResources';
import { Office } from '../../types/officeRND/offices';
import dayjs from 'dayjs';
//import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import DatePickerComponent from '../../components/DatePickerComponent';
//import type { CheckAvailabilityFunc } from '../../forms/NewBookingForm/NewBookingFormContainer';
//import type { CreateBookingFunc } from '../../forms/NewBookingForm/NewBookingFormContainer';
//import axios from 'axios';
//import officeInfo from '../../utils/officeInformation';
import useOffices from '../../hooks/useOffices';
import customDaysFunc from '../../utils/customDaysFunc';
import formMinDate from '../../utils/formMinDate';
import { useParams } from 'react-router';

ReactModal.setAppElement('#root');

type BookMeetingRoomModalProps = {
  onRequestClose: () => void;
  // checkAvailability: CheckAvailabilityFunc;
  isOpen: boolean;
  office?: Office;
  capacity?: number;
  // resetForm: () => void;
};

type FormValues = {
  date: Date;
  people: number;
  requirements: string;
};

const BookMeetingRoomModal = (props: BookMeetingRoomModalProps) => {
  const { loading } = useResources();
  //const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showForm, setShowForm] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formLoading, setFormLoading] = useState(false);
  const { addToast } = useToasts();
  const url = new URL(window.location.href);
  const defaultDate = url.searchParams.get('date');
  const {
    handleSubmit,
    control,
    //formState: { errors },
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { profile } = useContext(UserContext);

  const handleClose = () => {
    setShowForm(false);
    props.onRequestClose();
    // setValue('date', defaultDate);
    setValue('people', 1);
    setValue('requirements', '');
  };

  // const isWeekday = (date: string | Date) => {
  //   const day = dayjs(date).day();
  //   return day !== 0 && day !== 6;
  // };

  const params = useParams<{ officeId?: string }>();

  let officeId = params?.officeId;

  // !window.location.pathname.split('/')[2] not working for this func.

  // const today = dayjs().toDate();

  // const formMinDate = formMinBookingDate(today, officeId);

  // request meeting room
  const requestMeetingRoom = () => {
    setFormLoading(true);
    try {
      return handleSubmit(
        async (values: FormValues) => {
          const body = {
            date: values.date,
            people: values.people,
            requirements: values.requirements,
            venue: props.office?.name,
            memberName: profile?.member.name,
            memberEmail: profile?.member.email,
            memberPhone: profile?.member.phone,
          };
          console.log(values, body);
          // const response = await axios.post(
          //   '/.netlify/functions/request-meeting-room',
          //   {
          //     ...body,
          //   }
          // );
          addToast(
            `Your request has been sent. The Cowork Local team will check meeting room availability of ${props.office?.name} and get back to you shortly.`,
            { appearance: 'success' }
          );
        },
        (errors) => {
          console.log('Form error', errors);
        }
      )();
    } catch (error) {
      addToast(`There was an error sending your request. Please try again.`, {
        appearance: 'error',
      });
    } finally {
      setFormLoading(false);
      handleClose();
    }
  };

  //  office details
  const capacity = props.capacity || 0;

  const { office, getOffice } = useOffices({ mock: false });

  const minDate = formMinDate(office?.slug);

  useEffect(() => {
    if (officeId) {
      getOffice(officeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  return (
    <ReactModal
      className={` translate-y-1/5 absolute right-1/2 w-full max-w-lg translate-x-1/2 transform rounded-lg bg-white p-8 text-center shadow-lg md:translate-y-1/4`}
      isOpen={props.isOpen}
      onRequestClose={handleClose}
    >
      {loading && (
        <div className="flex min-h-full items-center justify-center">
          <ReactLoading color="black" type="bubbles" />
        </div>
      )}

      <div className="z-50">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-700"
        >
          <FontAwesomeIcon className="mt-2 mb-4" icon={faTimes} />
        </button>
        <div className="flex flex-col">
          <h2 className="mb-4 text-lg font-bold">The board room</h2>
          <p className="text-sm">Max capacity: {capacity}</p>
          <form
            className="mt-4 mb-4 text-left"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="mb-4 flex flex-col">
              <label className="mb-2 text-sm" htmlFor="a">
                Select a date
              </label>
              <Controller
                {...register('date', { required: true })}
                render={({ field }) => (
                  <DatePickerComponent
                    {...field}
                    selected={field?.value}
                    value={undefined}
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate}
                    excludeTimes={[]}
                    // filterDate={customDays ? isWeekdayCustom : isWeekday}
                    filterDate={(val) => customDaysFunc(office?.slug, val)}
                    onChange={(val, e) => {
                      // props.resetForm();
                      field.onChange(val, e);
                    }}
                    placeholderText={
                      dayjs(field?.value).isValid()
                        ? dayjs(field?.value).add(1, 'day').format('ddd DD MMM')
                        : 'Select a date'
                    }
                    // placeholderText="Select a date"
                    className="bordered focus:outline-none w-full border border-black p-2 text-center"
                    wrapperClassName="w-full"
                  />
                )}
                name="date"
                defaultValue={
                  defaultDate ? dayjs(defaultDate).toDate() : undefined
                }
                control={control}
              />
            </div>
            <div className="mb-2 flex flex-col">
              <label className="mb-2 text-sm" htmlFor="a">
                No. of people
              </label>
              <input
                {...register('people', { required: true })}
                type="number"
                name="people"
                className="w-full border border-black px-4 py-2 text-center"
                placeholder={`Max capacity: ${capacity}`}
                min={1}
                max={capacity}
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-2 text-sm" htmlFor="a">
                Special requirements
              </label>
              <textarea
                {...register('requirements')}
                className="w-full border border-black px-4 py-2"
                rows={3}
              />
            </div>
          </form>
          <button
            className={
              !watch('date') || !watch('people')
                ? 'w-2/3 translate-x-1/4 transform cursor-not-allowed rounded bg-cowork-blue px-4 py-2 font-bold text-white opacity-25'
                : 'w-2/3 translate-x-1/4 transform rounded bg-cowork-blue px-4 py-2 font-bold text-white'
            }
            type="submit"
            onClick={requestMeetingRoom}
            disabled={!watch('date' || 'people')}
          >
            Send booking request
          </button>
          <p className="mt-8 text-sm">
            This will send a request to our team, who will follow up with you to
            book the meeting room.
          </p>
        </div>
      </div>
    </ReactModal>
  );
};

export default BookMeetingRoomModal;
