import React from 'react';
import {
  PlanNames,
  returnMembershipName,
} from '../../screens/MembershipScreen/membershipUtils';
import CardComponent from '../CardComponent';

export type NewAmenitiesCardComponentProps = {
  onClick?: () => void;
  buttonTitle?: string;
  features?: string[];
  title: PlanNames | string;
  // addOn?: string;
  price: number;
  description: string;
};

const NewAmenitiesCardComponent: React.FC<NewAmenitiesCardComponentProps> = (
  props
) => {
  const isDisabled =
    props.buttonTitle === 'Currently Active' ||
    props.buttonTitle?.includes('Subscription ends on');
  const className = `hover:text-bg-primary-900 mt-2 w-full rounded 
  p-2 font-bold text-white transition-all bg-primary-${
    isDisabled ? '300' : '500'
  } hover:bg-primary-300`;
  const title = returnMembershipName(props.title);
  return (
    <CardComponent className="flex flex-col items-center justify-between shadow-none transition-all">
      <h2 className="mb-4 text-lg font-bold text-gray-800">{title}</h2>
      <p className="text-md mb-4 text-center text-gray-500">
        {props.description}
      </p>
      <p className="text-md mb-4 text-center font-bold text-gray-500">
        {props.title === PlanNames.DAYPASS && (
          <div className="line-through">£20 + VAT / day</div>
        )}
        {/* formatCurrency(props.membership.price, 0) */}£{props.price} + VAT /{' '}
        {/* TODO: @sarah format currency above*/}
        {props.title === PlanNames.DAYPASS ? 'day' : 'month'}
      </p>
      <button
        onClick={props.onClick}
        className={className}
        disabled={isDisabled}
      >
        {props.buttonTitle}
      </button>

      {/* <div className="mt-8 flex flex-wrap justify-start">
        {props.features &&
          props.features.map((feature) => {
            return <Feature key={feature} name={feature} />;
          })}
      </div> */}
    </CardComponent>
  );
};

export default NewAmenitiesCardComponent;
