import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import ContentCardComponent, {
  ContentCardComponentType,
} from '../../components/ContentCardComponent/ContentCardComponent';
import useEvents from '../../hooks/useEvents';

export type EventsScreenProps = {};

const EventsScreen: React.FC<EventsScreenProps> = (props) => {
  const { events, loading, getEvents } = useEvents({ mock: false });

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  if (!events && !loading) {
    return <h2>No upcoming events</h2>;
  }

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl text-gray-700 font-bold">Upcoming Events</h2>
        <p className="text-gray-500 mt-4">
          Our holistic and inclusive approach to training and support means that
          whether you are a freelancer, business owner, remote worker, or
          looking for new opportunities, you will find something to meet your
          needs. Manage your wellbeing, grow your business, take control of your
          professional development, or start working through that budding idea,
          with our team of experienced and accomplished experts.
        </p>
      </div>
      <div className="grid gap-4 md:gap-6 lg:grid-cols-2 grid-cols-1">
        {events.map((event) => {
          const date = event.start.local;

          return (
            <ContentCardComponent
              type={ContentCardComponentType.EVENT}
              subTitle={dayjs(date).format('dddd, DD MMMM YYYY').toUpperCase()}
              title={event.name.text}
              description={event.description.text}
              link={event.url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventsScreen;
