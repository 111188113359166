import React, { useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/InputComponent';
import { useToasts } from 'react-toast-notifications';
import { UserContext } from '../../context/user';
import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';

type Inputs = {
  name: string;
  date: string;
  email: string;
  phone: string;
};

type RequestCallBody = {
  user_name: string;
  user_email: string;
  user_phone: string;
  date: string;
  time: string;
  space: string;
};

type ScheduleACallFormProps = {
  onCancel: () => void;
};

const ScheduleACallForm = (props: ScheduleACallFormProps) => {
  const { handleSubmit, control, register } = useForm<Inputs>();
  const [startDate, setStartDate] = useState(
    dayjs().add(2, 'hour').set('minute', 0).toDate()
  );
  const [success, setSuccess] = useState(false);
  const { profile } = useContext(UserContext);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: Inputs) => {
    if (data.name && startDate) {
      try {
        const body: RequestCallBody = {
          user_name: data.name,
          user_email: data.email,
          user_phone: data.phone,
          date: dayjs(startDate).format('DD/MM/YYYY'),
          time: dayjs(startDate).format('HH:mm'),
          space: 'CoWorkLocal',
        };

        await axios.post('/.netlify/functions/request-call', {
          ...body,
        });

        setSuccess(true);
      } catch (error: any) {
        addToast(error.message, { appearance: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  if (success) {
    return (
      <div className="mt-8 flex h-20 items-center">
        <div className="w-full rounded bg-primary-100 p-4 px-8 text-center">
          <FontAwesomeIcon className="mr-2 text-primary-600" icon={faPhone} />
          <div className="flex items-center justify-center">
            <div>
              <h3 className="font-bold text-primary-800">
                We got your request!
              </h3>
              <h4 className="text-sm text-primary-800">
                We will be in touch soon
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="my-4">
      <form className="w-full text-left" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <label
            htmlFor="date"
            aria-label="date"
            className="mb-2 block text-sm text-gray-800"
          >
            When would you like us to contact you?
          </label>
          <Controller
            {...register('date', { required: true })}
            render={({ field, fieldState }) => (
              <DatePickerComponent
                {...field}
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                }}
                minDate={new Date()}
                showTimeSelect
                locale="en-GB"
                dateFormat="dd/MM/yyyy HH:mm"
              />
            )}
            defaultValue={startDate.toISOString()}
            name="date"
            control={control}
          />
        </div>
        <div className="mb-4 mt-4">
          <Controller
            {...register('name', { required: true })}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder=""
                label="Name"
                type="text"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="name"
            control={control}
            defaultValue={profile?.member.name}
            rules={{ required: true }}
          />
        </div>

        <div className="mb-4">
          <Controller
            {...register('email', { required: true })}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="name@company.co.uk"
                label="Email Address"
                type="text"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="email"
            control={control}
            defaultValue={profile?.member.email}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
          />
        </div>

        <div className="mb-4">
          <Controller
            {...register('phone', { required: false })}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Phone Number"
                type="phone"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="phone"
            control={control}
            defaultValue={profile?.member.phone}
          />
        </div>

        <div className=" w-full text-center">
          <PrimaryButtonComponent
            loading={loading}
            className="mt-2 flex w-full justify-center text-center"
            type="submit"
          >
            Contact me
          </PrimaryButtonComponent>
          <button
            onClick={props.onCancel}
            className="mt-4 text-sm text-gray-500 hover:text-gray-600"
            type="button"
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );
};
export default ScheduleACallForm;
