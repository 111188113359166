import Fuse from 'fuse.js';
import { useContext, useState } from 'react';

import { UserContext } from '../../context/user';
import { Office } from '../../types/officeRND/offices';
import OfficeRndAPI from '../../utils/OfficeRndAPI';
// import townSquareOfficeIds from '../../utils/townSqOfficeIds';

type UseOfficesProps = {
  mock?: boolean;
};
/**
 * The React hook for fetching the town sqaure offices
 */
const useOffices = (props: UseOfficesProps) => {
  const api = new OfficeRndAPI();

  const [offices, setOffices] = useState<Office[]>([]);
  const [office, setOffice] = useState<Office>();
  const [loading, setLoading] = useState(false);

  const { isTownSquare } = useContext(UserContext);

  const getOffices = async () => {
    try {
      setLoading(true);
      const data = await api.getOffices();
      // console.log(`isTownSquare`, isTownSquare);
      if (isTownSquare) {
        setOffices(data);
        return data;
      } else {
        const coWorkLocalSpaces = data.filter((off) => {
          return /cowork\slocal/i.test(off.name);
        });
        // @mike, is it possible to load smaller images if parameter is provided?
        setOffices(coWorkLocalSpaces);
        return coWorkLocalSpaces;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getOffice = async (id: string) => {
    try {
      setLoading(true);
      const data = await api.getOffice(id);

      setOffice(data);
    } catch (error) {
      console.log(`error`, error);
    } finally {
      setLoading(false);
    }
  };

  const searchOffices = async (term?: string) => {
    try {
      setLoading(true);
      let data = await getOffices();

      if (!data || !data.length) {
        return;
      }
      if (term) {
        const options = {
          keys: ['name', 'city'],
        };

        const fuse = new Fuse(data, options);
        const results = fuse.search(term);

        data = results.map((res) => res.item);
      }

      setOffices(data);
      return data;
    } catch (error) {
      console.log(`error`, error);
    } finally {
      setLoading(false);
    }
  };

  const getAndSetOffices = async () => {
    try {
      setLoading(true);
      const data = await getOffices();
      if (!data) return;
      setOffices(data);
      return data;
    } catch (error) {
      console.log(`error`, error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getOffices,
    getOffice,
    searchOffices,
    loading,
    offices,
    office,
    getAndSetOffices,
  };
};

export default useOffices;
