// ? update emails and opening times at branch:feature/email-and-opening-times-updates

export enum Days {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

type AdditionalOfficeInfo = {
  slug: string;
  opening_time: string;
  closing_time: string;
  custom_days?: Days[];
};

export const defaultOpeningTime = '01/01/2020 09:00:00';
export const defaultClosingTime = '01/01/2020 17:00:00';

export const officeInfo: AdditionalOfficeInfo[] = [
  {
    slug: 'duke-of-wellington-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'y-shed-cowork-local',
    opening_time: '01/01/2020 09:30:00',
    closing_time: '01/01/2020 16:00:00',
  },
  // { reminder to add memo arts centre when it's live},
  {
    slug: 'diocesan-office-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'swyddfa-grŵp-cynefin-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'old-penarthians-rfc-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
    custom_days: [Days.TUESDAY, Days.WEDNESDAY, Days.THURSDAY],
  },
  {
    slug: 'menter-iaith-abertawe-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: defaultClosingTime,
    custom_days: [Days.TUESDAY, Days.WEDNESDAY, Days.THURSDAY, Days.FRIDAY],
  },
  {
    slug: 'canolfan-y-bont-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'y-cwt-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'cra-martial-arts-and-fitness-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-environment-centre-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
    custom_days: [Days.TUESDAY, Days.THURSDAY, Days.FRIDAY],
  },
  {
    slug: 'the-cwtch-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'canolfan-y-bont-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-old-school-cowork-local',
    opening_time: '01/01/2020 09:15:00',
    closing_time: '01/01/2020 18:00:00',
  },
  {
    slug: 'dinas-powys-library-cowork-local',
    opening_time: '01/01/2020 10:30:00',
    closing_time: '01/01/2020 16:30:00',
  },
  {
    slug: 'emporium-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 18:00:00',
  },
  {
    slug: 'rhoose-activity-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'glamorganshire-golf-club-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 18:00:00',
  },
  {
    slug: 'the-old-bank-dinas-powys-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 16:30:00',
  },
  {
    slug: 'the-six-bells-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-clock-yard-rowleys-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'st-bridgets-farmhouse-bb-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-three-horseshoes-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 23:00:00',
  },
  {
    slug: 'tudor-tavern-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: defaultClosingTime,
  },
  {
    slug: 'st-pauls-community-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'gvs-illtud-house-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'castleland-community-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-roost-on-rock-road-cowork-local',
    opening_time: '01/01/2020 09:30:00',
    closing_time: '01/01/2020 17:30:00',
  },
  {
    slug: 'menter-iaith-abertawe-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'limitless-activity-centre-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 19:00:00',
  },
  {
    slug: 'patti-pavilion-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'hq-urban-kitchen-cowork-local',
    opening_time: '01/01/2020 08:00:00',
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'the-phoenix-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'hoogah-bar-kitchen-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'bonvilston-reading-rooms-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'eirianfa-community-centre-cowork-local',
    opening_time: '01/01/2020 08:00:00',
    closing_time: '01/01/2020 17:30:00',
  },
  {
    slug: 'cadwyn-clwyd-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'capel-hermon-business-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'glan-llyn-inn-cowork-local',
    opening_time: '01/01/2020 07:30:00',
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-carriageworks-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'tnr-coaching-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'parish-hall-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'the-old-schoolroom-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'rhyl-library-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'denbigh-library-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'fforddlas-community-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-little-man-coffee-co-cowork-local',
    opening_time: '12/30/1899 08:00:00',
    closing_time: '12/30/1899 17:00:00',
  },
  {
    slug: 'menter-iaith-sir-ddinbych-cowork-local',
    opening_time: '12/30/1899 09:00:00',
    closing_time: '12/30/1899 17:00:00',
  },
  {
    slug: 'the-red-lion-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: defaultClosingTime,
  },
  {
    slug: 'st-collens-community-hall-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'technology-house-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-business-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'cwrt-cowork-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'costigans-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'wrexham-the-enterprise-hub-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'islington-old-street-yard-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'node-cowork-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'the-track-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'perch-eco-business-centre-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'ymca-barry-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'nilc-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'swyddfa-menter-iaith-conwy-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'taxassist-accountants-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'clwyd-chambers-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'saint-marys-church-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'carne-arms-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'wenvoe-castle-golf-club-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'barry-masonic-hall-cowork-local',
    opening_time: '01/01/2020 10:00:00',
    closing_time: '01/01/2020 16:00:00',
  },
  {
    slug: 'space2work-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
  {
    slug: 'go-commercial-finance-cowork-local',
    opening_time: defaultOpeningTime,
    closing_time: defaultClosingTime,
  },
];

if (process.env.NODE_ENV !== 'development') {
  officeInfo.unshift({
    slug: 'big-lemon-cowork-local',
    opening_time: '12/30/1899 9:00:00',
    closing_time: '12/30/1899 17:00:00',
  });
}
export default officeInfo;
