import { useState } from 'react';
import { Amenity } from '../../types/officeRND/Amenity';
import OfficeRndAPI from '../../utils/OfficeRndAPI';

const api = new OfficeRndAPI();

const useAmenities = () => {
  const [amenities, setAmenities] = useState<Amenity[]>([]);

  const getAmenities = async (ids?: string[]): Promise<Amenity[]> => {
    const result = await api.getAmenities(ids);
    setAmenities(result);
    return result;
  };

  return {
    getAmenities,
    amenities,
  };
};

export default useAmenities;
