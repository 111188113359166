import dayjs from 'dayjs';
import { useState } from 'react';

import { Event } from '../../types/eventbrite/event';
import { EventBriteAPI } from '../../utils/EventBriteAPI';
import { mockData } from './mockdata';

type UseEventsProps = {
  mock?: boolean;
};
const eventbrite = new EventBriteAPI();
const useEvents = (props: UseEventsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);

  const getEvents = async () => {
    if (props.mock) {
      setEvents(mockData);
      return mockData;
    }
    setLoading(true);

    try {
      const data = await eventbrite.getEvents();

      const afterToday = data.events.filter((event) =>
        dayjs(event.start.local).isAfter(dayjs(), 'day')
      );

      setEvents(afterToday);
      return afterToday;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, getEvents, events };
};

export default useEvents;
