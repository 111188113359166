import React from 'react';
import styled from 'styled-components';

import townSquareLogo from '../../assets/town-sq-logo-white.svg';
import pinkTriangle from './triangle.svg';
import blueTriangle from './blueTriangle.svg';
import { TailwindTheme } from '../../types';
export type AuthLayoutComponentProps = {};

const AuthLayoutComponent: React.FC<AuthLayoutComponentProps> = (props) => {
  let logo = townSquareLogo;
  let theme = TailwindTheme.TOWNSQ;
  let triangle = pinkTriangle;
  const url = window.location.href;

  if (!url.includes('members.townsq.co.uk')) {
    logo =
      'https://assets.website-files.com/61e6d79f1e8a4e2af17dae31/61e96cad4939831eafe16662_Asset%203Logo.svg';
    theme = TailwindTheme['COWORK-LOCAL'];
    triangle = blueTriangle;
  }

  return (
    <div className={`lg:flex ${theme} bg-gray-200`}>
      <div className="hidden min-h-screen lg:inline">
        <img
          className="absolute -left-48 float-left m-0 min-h-screen"
          src={triangle}
          alt=""
        />
      </div>
      <Pink className="z-10 flex justify-center bg-primary-500 lg:flex-1 lg:bg-transparent">
        <div className="flex w-full max-w-xs justify-center py-4 lg:justify-start lg:py-8">
          <img
            className="max-h-6 md:max-h-8 lg:max-h-12 lg:w-1/2"
            alt="town sqaure logo"
            src={logo}
          />
        </div>
      </Pink>
      <div className="z-20 flex min-h-screen flex-1 items-center justify-center p-2">
        {props.children}
      </div>
    </div>
  );
};

const Pink = styled.div``;

export default AuthLayoutComponent;
