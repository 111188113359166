import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import NewAmenitiesCardComponent from '../../components/AmenitiesCardComponent/NewAmenitiesCardComponent';
import CardComponent from '../../components/CardComponent';
// import useMembership from '../../hooks/useMembership';
import { formatCurrency } from '../../utils/formatCurrency';
import { PlanNames, Pricing, returnMembershipName } from './membershipUtils';

dayjs.extend(advancedFormat);

type PricingCardProps = {
  index: number;
  membership: Pricing;
  setIsModalOpen: any;
  features: string[] | undefined;
  planTitle: PlanNames;
  planDescription: string;
  cancelling: boolean | undefined;
  cancellingAt: Date | undefined;
};

export const PricingCard = (props: PricingCardProps) => {
  const buttonTitle = [PlanNames.DAYPASS, 'day pass', 'daypass'].includes(
    props.planTitle
  )
    ? 'Currently Active'
    : props.cancelling
    ? 'Cancelling...'
    : props.cancellingAt
    ? `Subscription ends on ${dayjs(props.cancellingAt).format('Do MMM')}`
    : 'Cancel Subscription';

  // console.log('props at PricingCard ' + props.index, props);

  const { description, title, price } = props.membership;

  return (
    <NewAmenitiesCardComponent
      onClick={() => props.setIsModalOpen(true)}
      buttonTitle={buttonTitle}
      features={props.features}
      title={title}
      price={price}
      // addOn={props.planDescription}
      description={description}
    />
  );
};

type OtherPriceCardProps = {
  index: number;
  membership: Pricing;
  planTitle: PlanNames;
  planDescription: string;
  onUpgrade: any;
};

export const OtherPriceCard = (props: OtherPriceCardProps) => {
  // console.log('props at OtherPriceCard ' + props.index, props);
  const title = returnMembershipName(props.planTitle);

  // const { activeMembership } = useMembership();

  const isDisabled = [PlanNames.DAYPASS, 'daypass', 'day pass'].includes(
    props.planTitle.toLowerCase()
  );

  return (
    <CardComponent className="flex flex-col items-center justify-between shadow-none transition-all">
      <div className="w-full h-full flex flex-col justify-between">
        <h2 className="mb-4 text-center text-lg font-bold text-gray-800">
          {title}
        </h2>
        <p className="text-md mb-4 text-center text-gray-500">
          {props.planDescription}
        </p>
        <p className="text-md mb-4 text-center font-bold text-gray-500">
          {formatCurrency(props.membership.price, 0)} + VAT / month
        </p>

        <div
        // className={`${
        //   props.planTitle &&
        //   ![PlanNames.BASIC, PlanNames.UNLIMITED].includes(props.planTitle) &&
        //   'hidden'
        // }`}
        >
          <button
            disabled={isDisabled}
            style={
              props.planTitle === PlanNames.DAYPASS ? { cursor: 'default' } : {}
            }
            onClick={() => props.onUpgrade(props.planTitle)}
            className={`hover:text-bg-primary-900 mt-2 w-full rounded 
            p-2 font-bold text-white transition-all bg-primary-${
              isDisabled ? '300' : '500'
            } hover:bg-primary-300`}
          >
            {props.planTitle === PlanNames.DAYPASS
              ? 'Pay As You Go'
              : 'Subscribe'}
          </button>
        </div>
      </div>
    </CardComponent>
  );
};
