import React from 'react'; //useContext
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AuthCardComponent from '../../components/AuthCardComponent';
import CardComponent from '../../components/CardComponent';
import LoginForm from '../../forms/LoginForm';

export type LoginScreenProps = {};

const LoginScreen: React.FC<LoginScreenProps> = (props) => {
  // const { isTownSquare, loading } = useContext(UserContext);
  const url = window.location.href;
  const isCoworkLocal = !url.includes('members.townsq.co.uk');
  let platform = 'Town Square';
  if (isCoworkLocal) {
    platform = 'Cowork Local';
  }

  return (
    <>
      <Helmet>
        <title>Sign in</title>
        <meta
          name="description"
          content={`Welcome to ${platform} Members portal`}
        />
      </Helmet>
      <Wrapper className="w-full max-w-md">
        <AuthCardComponent
          title="Login"
          description={`Welcome to ${platform} Members portal. Login with your members account to get started.`}
        >
          <LoginForm />
          <a
            className="mt-8 text-sm text-gray-600 underline"
            href="https://townsquare.officernd.com/reset-password"
          >
            Forgot password?
          </a>
        </AuthCardComponent>
        {isCoworkLocal && (
          <CardComponent className="mt-8 flex h-2 items-center justify-between text-sm">
            <p>Don't have an account?</p>
            <Link to="/register">
              <span className="font-bold underline">Register now</span>
            </Link>
          </CardComponent>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;

export default LoginScreen;
