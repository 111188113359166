import dayjs from 'dayjs';
import officeInfo, { Days } from './officeInformation';

export default function formMinDate(slug?: string) {
  // ! previous functionality gave startOf() too early, so every day would have had 1 day only added.
  // ! if formMinDate returned a saturday, available days for booking would have been backdated to previous monday.

  const office = officeInfo.find((office) => office.slug === slug);

  // Cut off time of 9pm
  let formMinDate = dayjs()
    // .startOf('day') // <-- should add day _before_ setting to midnight
    .add(dayjs().isBefore(dayjs().set('hour', 21)) ? 1 : 2, 'day')
    .startOf('day')
    .toDate();

  // If the date is the weekend, set the next date to Monday // <-- this fails
  // if (dayjs(formMinDate).day() === 0 || dayjs(formMinDate).day() === 6) {
  //   formMinDate = dayjs(formMinDate).set('day', 1).toDate();
  // }

  // If the date is a Sunday (day 0), set the next date to Monday
  if (dayjs(formMinDate).day() === 0) {
    formMinDate = dayjs(formMinDate).set('day', 1).toDate();
  }

  // If the date is a Saturday (day 6), add 2 days to get the _following_ Monday
  if (dayjs(formMinDate).day() === 6) {
    formMinDate = dayjs(formMinDate).add(2, 'days').toDate();
  }

  if (office?.custom_days) {
    formMinDate = handleCustomOpeningDays(formMinDate, office.custom_days);
  }

  return formMinDate;
}

function handleCustomOpeningDays(formMinDate: Date, customDays: Days[]) {
  if (!customDays.includes(dayjs(formMinDate).day())) {
    // console.log('not a custom day');
    for (let i = 0; i < 7; i++) {
      if (customDays.includes(dayjs(formMinDate).day() + i)) {
        console.log('found a custom day');
        formMinDate = dayjs(formMinDate).add(i, 'day').toDate();
        break;
      }
    }
  } else {
    // console.log('is a custom day');
  }
  return formMinDate;
}

export function formMinDateTest(day: Date) {
  console.log('formMinDateTest', day);

  // Cut off time of 9pm
  let formMinDate = dayjs(day)
    .add(dayjs(day).isBefore(dayjs(day).set('hour', 21)) ? 1 : 2, 'day')
    .startOf('day')
    .toDate();

  // If the date is a Sunday (day 0), set the next date to Monday
  if (dayjs(formMinDate).day() === 0) {
    formMinDate = dayjs(formMinDate).set('day', 1).toDate();
  }

  // If the date is a Saturday (day 6), add 2 days to get the _following_ Monday
  if (dayjs(formMinDate).day() === 6) {
    formMinDate = dayjs(formMinDate).add(2, 'days').toDate();
  }

  return formMinDate;
}
