const townSquareOfficeIds = [
  '600834bd1d6d843e7165f594', // Node Cowork
  '5e60f4380d715200cb209354', // The Track
  '5c62cd1855d7470010d90776', // Perch Eco Centre
  '5f3cf901840909001271ae60', // Head Office
  '5f3130e0a35cca00111bc330', // Wrexham
  '6058bb4a1fe58ce56798cd71', // Chester
  '61a5fc7694690a95d938f517', // Islington
  '61bb3f59d18a4bc4684007f0', // Cwrt Llanishen
  '',
];

export default townSquareOfficeIds;
