import {
  faArrowLeft,
  faDoorClosed,
  faDoorOpen,
  faCalendar,
  //faCheck,
  faDesktop,
  faHandshakeAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
//import { MenuContext } from 'react-flexible-sliding-menu';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router';
import ContentCardComponent from '../../components/ContentCardComponent';
import { UserContext } from '../../context/user';
import useOffices from '../../hooks/useOffices';
import officeInfo from '../../utils/officeInformation';
import BookingModal from '../../views/BookingsModal';
import BookMeetingRoomModal from '../../views/BookMeetingRoomModal';
//import useMembership from '../../hooks/useMembership/useMembership';
import CardComponent from '../../components/CardComponent';
//import Select from 'react-select';
//import { Controller, useForm } from 'react-hook-form';
import NewBookingForm from '../../forms/NewBookingForm';
import Placeholder from '../Placeholder.png';
import AmenitiesCardComponent from '../../components/AmenitiesCardComponent';
import useResources from '../../hooks/useResources';
//import _ from 'lodash';
//import formMinDate from '../../utils/formMinDate';
import Mapbox from '../../components/NewMapSingleComponent';

export type OfficeDetailScreenProps = {};

type OfficeInput = {
  label: string;
  value: string;
};
export type Inputs = {
  time: 'am' | 'pm' | 'allDay';
  office?: OfficeInput;
  title: string;
  description: string;
  date: string;
  size?: string;
  amenities?: string[];
};

type Params = {
  officeId?: string;
};

//const minDate = formMinDate();

const OfficeDetailScreen: React.FC<OfficeDetailScreenProps> = (props) => {
  const { profile } = useContext(UserContext);
  // const { toggleMenu } = useContext(MenuContext);
  const { office, getOffice, loading } = useOffices({ mock: false });
  const { resources, getResources } = useResources();
  const [resourcesByNumber, setResourcesByNumber] = useState<{
    hotdesks: number;
    meeting_rooms: number;
  }>();
  //const { activeMembership } = useMembership();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showBookMeetingRoomModal, setShowBookMeetingRoomModal] =
    useState(false);
  const params = useParams<Params>();
  let officeId = params?.officeId ?? profile?.member?.office;
  // console.log('🚀 ~ officeId', officeId);
  //const [startDate, setStartDate] = useState(minDate);

  useEffect(() => {
    if (office) {
      getResources({
        office: office?._id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  //  get office id from url
  const officeIdUrl = window.location.pathname.split('/')[2];

  const meetingRooms = resources?.filter((r) => r.type === 'meeting_room');
  // console.log('🚀 ~ meetingRooms', meetingRooms);

  // * does venue have a meeting room?
  const hasMeetingRoom =
    (resources?.findIndex((res) => res.type === 'meeting_room') ?? -1) > -1;
  // console.log('🚀 ~ hasMeetingRoom', hasMeetingRoom);

  // * filter meetingRooms with officeId
  const currentOffice = meetingRooms?.filter((r) => r.office === officeIdUrl);
  // console.log('🚀 ~ currentOffice', currentOffice);
  //const sortedByPrice = _.sortBy(meetingRooms, 'price');
  // console.log('🚀 ~ sortedByPrice', sortedByPrice);

  // TODO: just put this here for the moment until I can figure out what to do with the amenities
  //const features = ['40" presentation screen', '12 power points', 'Projector'];

  // Module Hooks
  //const {
  //handleSubmit,
  //control,
  //formState: { errors },
  //} = useForm<Inputs>();

  useEffect(() => {
    if (officeId) {
      getOffice(officeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  useEffect(() => {
    if (office) {
      getResources({
        office: office?._id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  useEffect(() => {
    if (resources) {
      setResourcesByNumber({
        hotdesks: resources.filter((resource) => resource.type === 'hotdesk')
          .length,
        meeting_rooms: resources.filter(
          (resource) => resource.type === 'meeting_room'
        ).length,
      });
    }
  }, [resources]);

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <ReactLoading color="black" type={'bubbles'} width={48} height={48} />
      </div>
    );
  }

  const { latitude, longitude } = office?.physicalAddress ?? {};

  //const address = `${office?.address}, ${office?.city}, ${office?.zip}`;

  const officeMeta = officeInfo.find((off) => off.slug === office?.slug);

  let addresses = [<br key={0} />];

  if (office?.address)
    addresses.push(
      <p key={office.address} className="max-w-xl text-sm text-gray-500">
        {office?.address}
      </p>
    );
  if (office?.city)
    addresses.push(
      <p key={office.city} className="max-w-xl text-sm text-gray-500">
        {office?.city}
      </p>
    );
  if (office?.state)
    addresses.push(
      <p key={office.state} className="max-w-xl text-sm text-gray-500">
        {office?.state}
      </p>
    );
  if (office?.country)
    addresses.push(
      <p key={office.country} className="max-w-xl text-sm text-gray-500">
        {office?.country}
      </p>
    );
  if (office?.zip)
    addresses.push(
      <p key={office.zip} className="max-w-xl text-sm text-gray-500">
        {office?.zip}
      </p>
    );

  console.log('slug==>', office?.slug);

  return (
    <div>
      <Helmet>
        <title>{office?.name}</title>
        <meta name="description" content={office?.description} />
      </Helmet>
      {/* <div className="flex items-center justify-end mb-4 md:mb-16">
        <div>
          <PrimaryButtonComponent onClick={() => setShowBookingModal(true)}>
            {activeMembership ? ' Book this space' : 'Join to book'}
          </PrimaryButtonComponent>
        </div>

        <button
          onClick={toggleMenu}
          className="right-0 z-10 px-4 py-2 ml-4 text-white rounded-lg text-gray-10 focus:outline:none bg-realGray-700"
        >
          <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt} />
          Change Location
        </button>
      </div> */}

      {/* button taken you back to previous page */}
      <div className="mb-4 flex cursor-pointer items-center justify-start md:mb-16">
        <button
          className="flex items-center"
          onClick={() => {
            window.history.back();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-2xl" />
          <h2 className="ml-4 max-w-md text-2xl font-bold text-gray-700">
            Back to locations
          </h2>
        </button>
      </div>
      {/* end of button */}

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
        {/* FIXME: I have commented this card out, as it doesn't seem to allow fullwidth image? */}
        {/* <ContentCardComponent
        // floatImage
        img={office?.image}
        title={`${office?.city ?? ''}: ${office?.name ?? ''}`}
        description={office?.description}
      >
        {addresses}
      </ContentCardComponent> */}

        {/* <CardComponent className="relative w-full" isLocationCard>
          <div className="h-5/6">
            <img
              className={` h-0.63 w-full rounded-t-xl object-cover `} // rounded-full w-24 lg:w-48
              src={office?.image}
              alt={office?.name}
            ></img>
            <div className="flex h-0.47 flex-col justify-between px-6">
              <div className="items-center pt-4 font-bold text-gray-700">
                <h2 className="max-w-md mb-4 text-2xl font-bold text-gray-700">{`${
                  office?.city ?? ''
                }: ${office?.name ?? ''}`}</h2>
                <p className="max-w-xl text-sm text-gray-500">
                  {office?.description}
                </p>
              </div>
              <div>{addresses}</div>
            </div>
          </div>
        </CardComponent> */}
      </div>

      <div className="flex flex-wrap">
        {/* At the moment I cant get the amenities of the office */}

        <div className="w-full rounded-md md:w-2/3">
          <CardComponent className="relative w-full" isLocationCard>
            <div className="h-5/6">
              {office && (
                <img
                  className={` max-h-96 w-full rounded-t-xl object-cover `} // h-0.63 rounded-full w-24 lg:w-48
                  src={office.image ?? Placeholder}
                  alt={office.name}
                />
              )}
              <div className="grid w-full grid-cols-3 px-6 py-4">
                <div className="col-span-3 w-full xl:col-span-2">
                  <div className="flex h-0.47 flex-col justify-between px-6">
                    <div className="items-center pt-4 font-bold text-gray-700">
                      <h2 className="mb-4 max-w-md text-2xl font-bold text-gray-700">{`${
                        office?.city ?? ''
                      }: ${office?.name ?? ''}`}</h2>
                      <p className="max-w-xl text-sm text-gray-500">
                        {office?.description}
                      </p>
                    </div>
                    <div className="mb-4">{addresses}</div>
                  </div>
                </div>
                <div className=" col-span-3 flex w-full pt-4 pl-0 text-gray-700 xl:col-span-1 xl:flex-col xl:pl-2">
                  <div className="mr-2 mb-0 flex h-40 w-1/2 content-center items-center rounded-xl bg-gray-100 p-8 xl:mr-0 xl:mb-2 xl:w-full">
                    <div className="w-full text-center">
                      <div>
                        <FontAwesomeIcon
                          icon={faDesktop}
                          className="text-2xl"
                        />
                      </div>
                      {resourcesByNumber?.hotdesks} hotdesk
                      {resourcesByNumber?.hotdesks === 1 ? '' : 's'}
                    </div>
                  </div>
                  <div className="ml-1 mt-0 flex h-40 w-1/2 content-center items-center rounded-xl bg-gray-100 p-8 xl:ml-0 xl:mt-1 xl:w-full">
                    <div className="w-full text-center">
                      <div>
                        <FontAwesomeIcon
                          icon={faHandshakeAlt}
                          className="text-2xl"
                        />
                      </div>
                      {resourcesByNumber?.meeting_rooms} meeting room
                      {resourcesByNumber?.meeting_rooms === 1 ? '' : 's'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
          {/* Book Meeting Room */}
          {hasMeetingRoom && (
            <ContentCardComponent
              className="flex flex-col justify-between px-6"
              title={'Book a meeting room'}
              description=""
            >
              <div className="flex flex-col justify-between px-6">
                <div className="items-center pt-4 font-bold text-gray-700">
                  <div className="flex justify-between">
                    <h3 className="mb-4 max-w-md text-xl font-bold text-gray-700">
                      {currentOffice?.[0].name}
                    </h3>
                    <div className="rounded-md bg-cowork-green p-3 text-white">
                      {currentOffice?.[0].rate?.price === undefined ||
                      currentOffice?.[0].rate?.intervalLength === undefined
                        ? 'please enquire about price'
                        : `£${currentOffice?.[0].rate?.price}/${currentOffice?.[0].rate?.intervalLength}`}
                    </div>
                  </div>
                  <AmenitiesCardComponent
                    // * no amenities available for venues atm
                    features={
                      []
                      // features.map((feature) => feature) ?? []
                      // currentOffice?.[0].amenities.map((amenity) => amenity) ?? []
                    }
                    title=""
                    description={currentOffice?.[0].description}
                    buttonTitle="Make enquiry"
                    onClick={() => setShowBookMeetingRoomModal(true)}
                    capacity={currentOffice?.[0]?.size || 0}
                  />
                </div>
              </div>
            </ContentCardComponent>
          )}

          {officeMeta && (
            <ContentCardComponent
              className="my-8 items-center"
              title="Opening times"
              description="Availability Subject to change - check availability by clicking `Book this space’"
            >
              <div
                className={`items-top mt-8 grid h-24 grid-cols-2 ${
                  officeMeta.custom_days &&
                  officeMeta.custom_days.length > 0 &&
                  'grid-cols-3'
                } justify-around gap-3 text-center`}
              >
                <div>
                  <FontAwesomeIcon icon={faDoorOpen} />
                  <p className="text-sm text-gray-600">Open</p>
                  {dayjs(officeMeta.opening_time ?? '09:00:00').format('h:mma')}
                </div>
                <div>
                  <FontAwesomeIcon icon={faDoorClosed} />
                  <p className="text-sm text-gray-600">Close</p>
                  {dayjs(officeMeta.closing_time ?? '17:00:00').format('h:mma')}
                </div>
                {officeMeta.custom_days && (
                  <div>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p className="text-sm text-gray-600">Days</p>
                    {officeMeta.custom_days.map((day) => (
                      <div
                        key={day}
                        className="m-1 inline-flex rounded border px-2 py-1 text-xs"
                      >
                        {day === 0
                          ? 'Su'
                          : day === 1
                          ? 'Mo'
                          : day === 2
                          ? 'Tu'
                          : day === 3
                          ? 'We'
                          : day === 4
                          ? 'Th'
                          : day === 5
                          ? 'Fr'
                          : day === 6
                          ? 'Sa'
                          : ''}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <div className="mt-4 flex h-24 items-center justify-around text-center">
                <div className="text-center">
                  <FontAwesomeIcon icon={faDoorOpen} />
                  <p className="text-sm text-gray-600">Open</p>
                  {dayjs(officeMeta.opening_time ?? '09:00:00').format('h:mma')}
                </div>
                <div>
                  <FontAwesomeIcon icon={faDoorClosed} />
                  <p className="text-sm text-gray-600">Close</p>
                  {dayjs(officeMeta.closing_time ?? '17:00:00').format('h:mma')}
                </div>
                {officeMeta.custom_days && (
                  <div>
                    <FontAwesomeIcon icon={faCalendar} />
                    <p className="text-sm text-gray-600">Days</p>
                    {officeMeta.custom_days.map((day) => (
                      <div className="m-1 inline-flex rounded border px-2 py-1 text-xs">
                        {day}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
            </ContentCardComponent>
          )}
          {office?.physicalAddress &&
            latitude !== undefined &&
            longitude !== undefined && (
              <div className="my-4 w-full rounded-xl bg-white shadow-lg">
                <Mapbox lat={+latitude} lng={+longitude} />
              </div>
            )}
        </div>

        {/* <AmenitiesCardComponent
          features={['TV', 'Whiteboard']}
          title={'Services and Amenities'}
          description={'Vel egestas praesent varius aptent ultrices ut'}
        /> */}

        <NewBookingForm office={office} />

        {officeId && (
          <BookingModal
            officeId={officeId}
            isOpen={showBookingModal}
            onRequestClose={() => setShowBookingModal(false)}
          />
        )}
        <BookMeetingRoomModal
          // pass capacity to modal
          capacity={currentOffice?.[0]?.size || 0}
          isOpen={showBookMeetingRoomModal}
          onRequestClose={() => setShowBookMeetingRoomModal(false)}
        />
      </div>
    </div>
  );
};

export default OfficeDetailScreen;

// eslint-disable-next-line no-lone-blocks
{
  /* <AmenitiesCardComponent
  features={['TV', 'Whiteboard']}
  title={'Services and Amenities'}
  description={'Vel egestas praesent varius aptent ultrices ut'}
/>; */
}
