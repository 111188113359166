import React from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import { useEffect } from 'react';

export interface DatePickerComponentProps extends ReactDatePickerProps {
  onSelect?: (date: Date) => void;
}
const DatePickerComponent: React.FC<DatePickerComponentProps> = (props) => {
  useEffect(() => {
    registerLocale('en-GB', enGB);
  }, []);
  const onChange = (date: Date, event: any) => {
    if (props.onSelect) props.onSelect(date);
    props.onChange(date, event);
  };
  return (
    <DatePicker
      className="bordered focus:outline-none w-full rounded border-2 border-gray-400 p-2"
      {...props}
      locale="en-GB"
      onChange={onChange}
    />
  );
};

export default DatePickerComponent;
