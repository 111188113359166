export enum PlanNames {
  DAYPASS = 'dayPass',
  BASIC = 'basic',
  UNLIMITED = 'unlimited',
}

export type Pricing = {
  id: PlanNames.BASIC | PlanNames.UNLIMITED | PlanNames.DAYPASS;
  title: string;
  price: number;
  description: string;
};

export const pricingArray: Pricing[] = [
  {
    id: PlanNames.DAYPASS,
    title: 'Day Pass',
    price: 12.5,
    description: 'Our pay-as-you-go option, chargeable by the day.',
  },
  {
    id: PlanNames.BASIC,
    title: 'Basic',
    price: 50,
    description: 'Get 4 visits per month.',
  },
  {
    id: PlanNames.UNLIMITED,
    title: 'Unlimited',
    price: 150,
    description: 'Provides unlimited access to venues for a fixed monthly fee.',
  },
];

export function returnMembershipName(name: PlanNames | string) {
  // console.log('returnMembershipName', name);
  return [PlanNames.BASIC, 'basic'].includes(name.toLowerCase())
    ? 'Basic Access'
    : [PlanNames.UNLIMITED, 'unlimited'].includes(name.toLowerCase())
    ? 'Full Access'
    : [PlanNames.DAYPASS, 'daypass', 'day pass'].includes(name.toLowerCase())
    ? 'Pay As You Go'
    : '';
}

export function returnPlanDetails(title: string | undefined) {
  let planTitle: PlanNames | undefined;
  let planDescription = '';
  if (title && title.toLowerCase().includes('basic')) {
    planTitle = PlanNames.BASIC;
    planDescription =
      'Access to venues 1 day per week, up to 4 visits per month.';
  }
  if (
    title &&
    (title.toLowerCase().includes('unlimited') ||
      (title && title.toLowerCase().includes('cowork local full access')))
  ) {
    planTitle = PlanNames.UNLIMITED;
    planDescription =
      'Provides unlimited access to venues for a fixed monthly fee.';
  }
  if (title && title.toLowerCase().includes('day pass')) {
    planTitle = PlanNames.DAYPASS;
    planDescription = 'Our pay-as-you-go option, chargeable by the day.';
  }
  // console.log({ planTitle, planDescription });
  return { planTitle, planDescription };
  // !SUPER HARDCODED FOR NOW
}
