import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import CardComponent from '../../components/CardComponent';
//import PrimaryButtonComponent from '../../components/PrimaryButtonComponent';
import { Office } from '../../types/officeRND/offices';
import Placeholder from '../Placeholder.png';
import { Link } from 'react-router-dom';

type LocationCardProps = {
  name: string;
  image: string;
  location: string;
  id: string;
};

const LocationCard = (props: LocationCardProps) => {
  const history = useHistory();

  // TODO: add lazy loading if there's time available @sarah

  return (
    <CardComponent
      className="h-70 relative w-full lg:col-span-3 xl:col-span-2"
      isLocationCard
    >
      <div>
        <div className="h-40">
          <Link to={`/venues/${props.id}`}>
            {/* Image Container */}
            <div className="absolute top-2 right-2">
              <div
                style={{ backgroundColor: '#25b092' }}
                className="focus:outline-none flex justify-center rounded-lg py-2 px-4  font-semibold  text-primary-100 shadow-md transition-all  hover:text-white focus:ring-2 focus:ring-primary-400 focus:ring-opacity-75"
              >
                <span
                  className="text-lg font-medium " //tracking-wide
                >
                  {' '}
                  £12.50/day
                </span>
              </div>
            </div>
            <img
              className={` h-full w-full rounded-t-xl object-cover `} // h-0.63 max-h-[164px] rounded-full w-24 lg:w-48
              src={props.image ?? Placeholder}
              alt={props.name}
            ></img>
          </Link>
        </div>
        <div
          className="flex h-28 flex-col justify-between py-4 px-5"
          // h-0.47
        >
          {/* Name, Town, Btn Container */}
          <div className="mb-2">
            <div className=" flex h-2/3 items-center font-bold text-gray-700">
              <FontAwesomeIcon className="mr-4" icon={faMapMarkerAlt} />
              <p>{props.name}</p>
            </div>
          </div>
          {/* // TODO: Try adding a spacer div here to make the text align right @sarah */}
          <div className="flex h-1/3 items-center justify-between ">
            <p className="m-1 text-gray-700">{props.location}</p>
            <button
              onClick={() => history.push(`/venues/${props.id}`)}
              className="outline-none m-1 rounded border-2 border-primary-500 p-2 text-xs font-bold text-gray-800 hover:border-primary-600"
            >
              Select Location
            </button>
          </div>
        </div>
      </div>
    </CardComponent>
  );
};

const LocationCards = ({ filteredOffices }: { filteredOffices: Office[] }) => {
  return (
    <>
      {filteredOffices.map((office) => {
        return (
          <LocationCard
            id={office._id}
            key={office._id}
            name={office.name}
            image={office.image ?? ''}
            location={office.city ?? ''}
          />
        );
      })}
    </>
  );
};

export default LocationCards;
