import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Map from '!react-map-gl';
import Pin from './Pin';

const MapWrapper = styled.div`
  height: 400px;
`;

const token = process.env.REACT_APP_MAPBOX_TOKEN;

export type MapSingleComponentProps = {
  lat?: number;
  lng?: number;
  zoom?: number;
};

const MapSingleComponent: React.FC<MapSingleComponentProps> = ({
  lat,
  lng,
  zoom = 10,
}) => {
  if (!lat || !lng) {
    console.error('No Mapbox found. Please check latitude and longitude.');
    return (
      <ReactLoading color="black" type={'bubbles'} width={48} height={48} />
    );
  }

  return (
    <MapWrapper>
      <Map
        mapboxAccessToken={token}
        initialViewState={{
          latitude: lat,
          longitude: lng,
          zoom,
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        <Pin lat={lat} lng={lng} />
      </Map>
    </MapWrapper>
  );
};

export default MapSingleComponent;

// If problems with mapbox:-
// #️⃣ To check if your TOKEN is valid:
// https://api.mapbox.com/v4/mapbox.satellite/page.html?access_token={TOKEN}
// #️⃣ To test different custom styles:
// https://api.mapbox.com/styles/v1/{username}/{style_id}?access_token={TOKEN}
// #️⃣ To test mapbox styles (replace {STYLE} with any style from the url below):
// https://api.mapbox.com/styles/v1/mapbox/{STYLE}?access_token={TOKEN}
//❗Here are mapbox styles: https://docs.mapbox.com/api/maps/styles/
