import { useState } from 'react';
import * as _ from 'lodash';
import { Booking } from '../../types/officeRND/Booking';
import OfficeRndAPI from '../../utils/OfficeRndAPI';
import { mockBookings } from './mockBookings';
import { GetBookingsRequest } from '../../types/officeRND';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import {
  BookingRequestInput,
  CreateBookingInput,
} from '../../types/officeRND/BookingRequest';
import dayjs from 'dayjs';
type UseBookingsProps = {
  mock?: boolean;
  filter?: GetBookingsRequest;
};
export enum CreateBookingType {
  HOTDESK = 'hotdesk',
  MEETING_ROOM = 'meeting_room',
}
type CreateBookingParams = {
  type: CreateBookingType;
  officeId: string;
};
const api = new OfficeRndAPI();
const useBookings = (props: UseBookingsProps) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToasts();

  /**
   * Get a single booking that belongs to the signed in user
   */
  const getBooking = async (id: string) => {
    setLoading(true);
    try {
      const bookings = await api.getBookings(props.filter);
      const booking = bookings?.[0];

      if (booking) {
        return booking;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }

    return null;
  };

  /**
   * List all bookings for the signed in user
   */
  const getBookings = async (filter?: GetBookingsRequest) => {
    try {
      setLoading(true);
      let bookings: Booking[] | null = null;
      if (props.mock) {
        bookings = mockBookings;
      } else {
        bookings = await api.getBookings(filter);
      }

      if (filter?.['start.dateTime.$gte']) {
        bookings = bookings.filter((b) =>
          dayjs(b.start.dateTime).isAfter(dayjs(filter['start.dateTime.$gte']))
        );
      }

      if (filter?.['end.dateTime.$lt']) {
        bookings = bookings.filter((b) =>
          dayjs(b.start.dateTime).isBefore(dayjs(filter['end.dateTime.$lt']))
        );
      }

      const sorted = _.sortBy(bookings, 'start.dateTime');
      setBookings(sorted);
      return bookings;
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const redirectToBooking = async (params: CreateBookingParams) => {
    const currentUserEmail = (await api.getCurrentUser()).member.email;

    // This will be a speical admin token generated by the server to login the user
    let token: string | null = null;
    const officeWindow = window.open();

    if (currentUserEmail) {
      try {
        const { data } = await axios.post(
          '/.netlify/functions/get-user-token',
          {
            email: currentUserEmail,
          }
        );
        token = data.token;
      } catch (error) {
        console.log(`error`, error);
      }
    }

    let url = `https://townsquare.officernd.com/calendar?office=${params.officeId}`;
    if (params.type === CreateBookingType.HOTDESK) {
      url = `https://townsquare.officernd.com/hotdesks?office=${params.officeId}`;
    }

    // Add the token to the end of the url. If there is no token we can still send them but they wont be logged in automaticlly
    if (token) {
      url += `&access_token=${token}`;
    }
    if (officeWindow) {
      officeWindow.location.replace(url);
      officeWindow.focus();
    }
  };

  const checkBooking = async (input: BookingRequestInput) => {
    setLoading(true);
    try {
      return api.bookingRequest(input);
    } catch (error) {
    } finally {
      setLoading(false);
    }
    return [];
  };

  const createBooking = async (input: CreateBookingInput) => {
    setLoading(true);
    try {
      return api.createBooking(input);
    } catch (error) {
    } finally {
      setLoading(false);
    }
    return [];
  };

  const cancelBooking = async (bookingId: string) => {
    try {
      await api.cancelBooking(bookingId);
      addToast('Cancelled your booking', { appearance: 'success' });
    } catch (error: any) {
      console.log('error', error.response);
      addToast(
        error?.response?.data?.message ??
          'Sorry something went wrong cancelling your booking',
        { appearance: 'error' }
      );
    }
  };

  return {
    bookings,
    getBooking,
    getBookings,
    loading,
    createBooking,
    checkBooking,
    cancelBooking,
    redirectToBooking,
  };
};

export default useBookings;
